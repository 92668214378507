import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
//import Slider from "react-slick";
//import styles from "./Hero.module.sass";
//import Icon from "../../../components/Icon";
//import Player from "../../../components/Player";
//import Modal from "../../../components/Modal";
//import Connect from "../../../components/Connect";
//import { useHistory } from 'react-router-dom';
// import Bid from "../../../components/Bid";
import API from "../../../utils/API";
import { useSelector } from "react-redux";
// import { FormHelperText } from "@material-ui/core";
import downArrowPng from '../../../assets/images/icons/down-arrow.png';

/*
const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <button {...props}>{children}</button>
);
*/

const Hero = () => {

    const history = useHistory();
    const sliderRef = useRef();

    /*
    const settings = {
        arrow: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 4000,
        //nextArrow: (
        //   <SlickArrow>
        //     <Icon name="arrownext" size="20"  fill="black"/>
        //   </SlickArrow>
        //),
        //prevArrow: (
        //   <SlickArrow>
        //     <Icon name="arrowprev" size="20" fill="black"/>
        //   </SlickArrow>
        //),
    };
    */

    //const [visibleModalBid, setVisibleModalBid] = useState(false);
    const [data, setData] = useState([]);
    //const [minutes, setMinutes] = useState(0);
    //const [seconds, setSeconds] = useState(0);
    //const [hour, setHour] = useState(0);
    const [timeArr, setTimeArr] = useState(null);

    const euroValue = useSelector((state) => state.counter.euroValue) || 0;

    const getSliderAuctions = (params) => {

        // API.get("/asset/active/auctions")
        API.get("/asset/all?isCarrosel=true&limit=25").then((res) => {
            const data = res.data.result.assets.rows;
            let arr = data?.map(each => {
                let item = each?.asset
                let price = each?.saleType === "fixed"
                    ? each?.isResell === true ? each?.resalePrice : item?.regularPrice
                    : item.auctions?.[0]?.currentBid || item.auctions?.[0]?.openingPrice

                return {
                    title: item.name?.length > 22 ? item.name?.substring(0, 20) + "..." : item?.name,
                    creator: item.creatorName > 24 ? item.creatorName?.substring(0, 22) + "..." : item?.creatorName,
                    currency: (price * euroValue)?.toFixed(4) + " Matic",
                    price: (price)?.toFixed(4),
                    avatar: "images/userplaceholder.svg",
                    image: item?.thumbnailUrl || item?.mediaPreviewUrl,
                    image2x: item?.thumbnailUrl || item?.mediaPreviewUrl,
                    id: item.id,
                    item,
                    video: item?.mediaPreviewUrl || null,
                    audio: item?.mediaPreviewUrl || null,
                    audioImg: item?.audioThumbnail || null,
                    mediaType: item?.mediaType,
                    creatorName: item?.creatorName,
                    description: item?.shortDescription,
                    assetId: each?.id
                }
            });
            setData(arr);
        });
    }

    function timeLeft(item) {
        if (item?.auction?.endingDate) {
            var now = new Date();
            var diff = new Date(item?.auction?.endingDate) - now;
            var hours = Math.floor(diff / 3.6e6);
            var minutes = Math.floor((diff % 3.6e6) / 6e4);
            var seconds = Math.floor((diff % 6e4) / 1000);
            if (hours < 0 || minutes < 0 || seconds < -1) {
              return { hours: 0, minutes: 0, seconds: 0 }
            }
            return { hours, minutes, seconds }
        }
        return { hours: 0, minutes: 0, seconds: 0 }
    }

    useEffect(() => {

        let params = {
            limit: 20,
            page: 1,
            saleType: "auction"
        };
        getSliderAuctions(params);

        const getAllData = setInterval(() => {
          setTimeArr(data.map(item => timeLeft(item.item)));
        }, 1000);
        return () => clearInterval(getAllData);
    }, [])

    /*
    const limitTextToCount = (text, count) => {
        return text.slice(0, count) + (text.length > count ? "..." : "");
    }

    const goNext = () => {
        sliderRef.current.slickNext();
    }
    const goPrev = () => {
        sliderRef.current.slickPrev();
    }

    const firstItem = data.length && data[0]
    */

    
    const scrollToWhatIsRealsplit = () => {
        //window.scrollTo(0, 830);
        window.scrollTo({
              top: 830
            , behavior: 'smooth'
        });
    };
    
    return (
        <>
        {/*
        <div className={ cn("section-bg", "container", styles.heroSection) } style={{ paddingBottom: "40px", width: "100%", maxWidth: "var(--site-width)", margin: "auto" }}>
        <div className={ cn("section-bg", "container", "top-section", styles.heroSection) } style={{ paddingBottom: "40px", width: "100%", maxWidth: $siteWidth, margin: "auto" }}>
        <div className={ cn(  "container", styles.heroSection) } style={{ width: "100%", maxWidth: "1130px", margin: "auto" }}>
        <div className={ cn( styles.heroSection) } style={{ width: "100%", maxWidth: "1130px", margin: "auto" }}>
        */}
        <div
            className = { "Hero" }
            style = {{
              width: "100%"
            //, paddingBottom: "150px"
            //, paddingTop: "88px"
            //, maxWidth: "1130px"
            , margin: "auto"
            }}>

            {/*
            <div style = {{ maxWidth: "48%", paddingTop: "80px", paddingBottom: "80px" }}>
            */}
            <div
                className = "hero-container"
                //style = {{ maxWidth: "48%", paddingTop: "80px", paddingBottom: "80px" }}
                //style = {{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
                style = {{
                    justifyContent: "flex-start"
                    , gap: "33px"
                    //, paddingTop: "108px"
                    , borderRadius:'5px'
                    
                }}>

                <div className = { cn("hero-item") }
                    style = {{
                        background: "rgba(0, 0, 0, 0.6)"
                        //, background: "#495057CC"
                        , padding: "40px"
                        //, flexBasis: "60%"
                        , borderRadius:'5px'
                        , boxShadow:'0px 0px 30px rgba(1,1,1,0.33)'
                    }}
                    >

                    <div
                        className = { cn("table") }
                        //style = {{ marginLeft: "auto", marginRight: 0 }}
                        //style = {{ paddingRight: "20px" }}
                        >

                        <div className = { cn("row") }>
                            <div
                                //className = { "hero-heading" }
                                //className = { "hero-title" }
                                className = "hero-title"
                                //style = {{ paddingRight: "20px" }}
                                >
                                <div style = {{ paddingBottom: '20px' }}>Redefining Commercial Real Estate Ownership</div>
                                {/*
                                <div
                                    //style = {{ fontSize: '.8em' }}
                                    className = { cn('subtitle') }
                                    >Learn More Now!</div>
                                */}
                            </div>
                        </div>

                        <div className={cn("row")}>
                            <div
                                className = 'cell'
                                style={{
                                    padding: "30px 0px 0px 0px"
                                    , textAlign: 'center'
                                    , margin: 'auto'
                                }}>

                                <div
                                    style = {{
                                        display: 'flex'
                                        , flexWrap: 'wrap'
                                        //, justifyContent: 'space-evenly'
                                        , justifyContent: 'center'
                                        , gap: '50px'
                                        , alignItems: 'center'
                                        , width: '100%'
                                        , flex: '1'
                                        //, border: 'yellow 3px solid'
                                    }}>

                                    {/*
                                    <div
                                        style = {{
                                            //flexBasis: '50%'
                                            //flex: '1 0 auto'
                                            //flexGrow: '1'
                                            border: 'blue 3px solid'
                                            , flexGrow: '1'
                                            //, textAlign: 'right'
                                        }}
                                        >
                                        <Link to="/search/all">
                                    */}
                                            <button
                                                type = 'button'
                                                style = {{
                                                    border: 'white 2px solid'
                                                }}
                                                className = 'theme-success large'
                                                onClick = {() => history.push('/search/all')}
                                                >Find Deals</button>
                                    {/*
                                        </Link>
                                    </div>
                                    */}

                                    {/*
                                    <div
                                        style = {{
                                            //flexBasis: '50%'
                                            //flex: '1 0 auto'
                                            border: 'blue 3px solid'
                                            , flexGrow: '1'
                                            //, textAlign: 'left'
                                        }}
                                        >
                                    */}
                                        <button
                                            onClick = { (e) => scrollToWhatIsRealsplit() }
                                            type = 'button'
                                            style = {{
                                                border: 'white 2px solid'
                                            }}
                                            className = 'primary large'
                                            >
                                            Learn More
                                            &nbsp;
                                            <img src = { downArrowPng } />
                                        </button>
                                    {/*
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>

                        {/*
                        <div className={cn("row")}>
                            <div style={{
                                padding: "30px 0px 0px 0px"
                                }}>
                                <Link to="/edit-profile">
                                    <button
                                        type="button"
                                        style={{
                                              padding: "16px"
                                            , backgroundColor: "rgba(0, 0, 255, 1)"
                                            , borderRadius: "5px"
                                            , color: "#FFF"
                                            , fontFamily: "'Source Sans 3'"
                                            , fontWeight: "400"
                                            , fontSize: "20px"
                                        }}
                                        >Join Now</button>
                                </Link>
                            </div>
                        </div>
                        */}

                    </div>
                </div>

            </div>
        </div>
        </>
    );
};

export default Hero;
