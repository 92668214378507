import React from 'react';
//import styles from './StartInvestingToday.module.sass'
//import cn from "classnames";

const StartInvestingToday = () => {

    const dataItems = [
        {
              title: "Membership Liquidity"
            , description: "Investors purchase membership interest into a LLC that holds a Property. This enables them to sell their interest into the Property easily."
            , image: "/images/icons/membership-liquidity.svg"
        }
        , {
              title: "Exchange Flexibility"
            , description: "RealSplit has an ATS Exchange (Alternative Trading Software) that enables Investors to sell their original investment once hold period of initial investment is over."
            , image: "/images/icons/Exchange-Flexibility.svg"
        }
        , {
              title: "Fractionalized Opportunities"
            , description: "Our unique selling proposition is that we are giving Investors that opportunity to participate in large commercial RE deals through fractionalization that they normally would not be able to invest in."
            , image: "/images/icons/Fractionalized-Opportunities.svg"
        }
    ];

    return (

        <section
            style = {{ backgroundColor: "white", backgroundImage: 'none' }}
            >

            <div
                className = 'max-width'
                id = 'start-investing-today-wrapper'
                style = {{ paddingBottom: '20px' }}
                >

                {/*
                <h1 style = {{ textAlign: 'center', width: '100%' }}>Start Investing Today</h1>
                */}

                <h2
                    //className = { styles.sectionTitle }
                    /*
                    style = {{
                        padding: '50px 0px 50px 0px'
                        , margin: '0'
                    }}
                    */
                    >Start Investing Today</h2>

                <div
                    className = 'container max-width'
                    style = {{
                          display: 'flex'
                        , flexWrap: 'wrap'
                        , justifyContent: 'center'
                        , flexGrow: '1'
                        , gap: '30px'
                        //, width: 'calc(100% / 3)'
                    }}>

                    {
                        dataItems.map((dataItem, index) =>
                            (
                                <div
                                    key = { index }
                                    style = {{
                                        //width: 'calc(100% / 3)'
                                        //  width: '33%'
                                        //, flexGrow: '1'
                                        //, padding: '0px 12px'
                                        //, flexBasis: 'calc(33% - 24px)'
                                        //, minWidth: '250px'
                                    }}>

                                    <div style = {{ textAlign: 'center' }}>
                                        <img
                                            src = { dataItem.image }
                                            alt = { dataItem.title }
                                            style = {{
                                                //height: '5em'
                                                height: '100px'
                                                , width: 'auto'
                                            }}
                                            />
                                    </div>

                                    <div style = {{
                                          height: '100px'
                                        , margin: 'auto'
                                        , display: 'flex'
                                        , verticalAlign: 'middle'
                                    }}>
                                        <h3
                                            /*
                                            style = {{
                                                  fontFamily: 'Roboto Slab'
                                                , textAlign: 'center'
                                                , padding: '20px 0px'
                                                , fontSize: '24px'
                                                , fontStyle: 'normal'
                                                , fontWeight: '700'
                                                , lineHeight: '120%'
                                            }}
                                            */
                                            >
                                            { dataItem.title }
                                        </h3>
                                    </div>

                                    <p
                                        /*
                                        style = {{
                                              textAlign: 'center'
                                            , color: 'var(--color-gray-700, #495057)'
                                            , fontFamily: 'Source Sans 3'
                                            , fontSize: '18px'
                                            , fontStyle: 'normal'
                                            , fontWeight: '400'
                                            , lineHeight: '150%'
                                        }}
                                        */
                                    >{ dataItem.description }</p>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </section>
    );
}

export default StartInvestingToday;
