import cn from "classnames";
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import { axiosInstance } from '../../../utils/API';
import {
    searchAssets
    //, clearSearchAssets
} from '../../../redux/assetSlice';
import { NAMES_CONSTANTS } from '../../../constants';

/*
const dropdownList = [
      'categories'
    , 'collections'
]
*/

//const MobileNav = ({ isOpen = false, closeModal, chooseWalletType }) => {
/*
const MobileNav = ({
    isOpen = false
  , closeModal
  , chooseWalletType
  , headerNav
  , handleDisconnectFunction
}) => {
*/
const MobileNav = (props) => {
    console.info('MobileNav()');
    console.debug('MobileNav()', props);

    let isOpen = props.isOpen;
    let closeModal = props.closeModal;
    let chooseWalletType = props.chooseWalletType;
    let headerNav = props.headerNav;
    //let handleDisconnectFunction = props.handleDisconnectFunction;
    let logoutUserFunction = props.loginRegisterFunctions.logoutUser;

    const history = useHistory()
    const dispatch = useDispatch();
    const storeSearchText = useSelector(state => state.asset.searchText)
    const [categories, setCategories] = useState([])
    const [selectedDropdown, setSelectedDropdown] = useState([])
    const [searchText, setSearchText] = useState(storeSearchText || '');
    const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
    //let walletImageSrc = '/images/icons/wallet-icon.svg';

    const clickEarlyRegistration = (event) => {
        closeModal();
        props.loginRegisterFunctions.clickBecomeInvestor(event);
    };

    const clickLogin = (event) => {
        closeModal();
        props.loginRegisterFunctions.showLoginDialog();
    };

    const handleCloseModal = () => {
        closeModal();
    };

    const getCategoryList = async () => {
        await axiosInstance.get("/asset/categories?page=1&limit=100")
            .then((result) => {
                let categories = result?.data?.result?.categories?.rows?.map(
                    (category) => {
                        return {
                            name: category.name
                            , subCategories: category.subCategories
                        }
                    }
                );
                if (categories?.length) {
                    //setCategories([{ name: "All Properties", categories: "" }, ...categories]);
                    setCategories([...categories]);
                } else {
                    setCategories([])
                }
            })
            .catch((err) => {
            });
    };

    useEffect(() => {
        console.debug('MobileNav: useEffect(() => {...')
        console.debug('    getCategoryList();')
        getCategoryList();
    }, []);

    useEffect(() => {
        console.debug('MobileNav: useEffect(() => {...')
        console.debug('    closeModal();');
        closeModal();
        //console.warn('    props.closeModal();');
        //props.closeModal();
    }, [history.location.pathname]);

    /*
    const toggleSelectedDropdown = (value) => {
      if (value === selectedDropdown) {
        setSelectedDropdown(null)
      } else {
        setSelectedDropdown(value)
      }
    }
    */

    const handleSearch = () => {
      if (searchText) {
        closeModal();
        dispatch(searchAssets(searchText))
        history.push('/search/all')
      }
    }

    const handleOnKeySearch = (e) => {
      if (e.key === 'Enter') {
        console.error(e.key, 'key')
        handleSearch()
      }
    }

    return (

        <>

        {
            isOpen
            && <div
                className = "modal-overlay"
                onClick = { handleCloseModal }
                />
        }

        <div
            className = {`mobile-nav-container ${isOpen ? "show" : ""}` }
            onScroll = { (e) => {
                console.log('MobileNav: onScroll0(e)', e);
            }}>

            {/*
            */}
            <div
                //className="mobile-nav-content"
                //style = {{
                //    //  height: '110px'
                //    //, verticalAlign: "middle"
                //    //, margin: "auto"
                //    padding: '8px'
                //    //, marginRight: "0px"
                //    //, marginLeft: "auto"
                //    , textAlign: "right"
                //}}
                onScroll = { (e) => {
                    console.log('MobileNav: onScroll1(e)', e);
                }}
                >

                <div
                    style = {{
                          display: 'flex'
                        //, justifyContent: 'space-between'
                        , justifyContent: 'flex-end'
                        , alignItems: 'center'
                    }}
                    >
                        {/*
                        <div
                            //className = 'cell'
                            style = {{
                                verticalAlign: 'middle'
                                , textAlign: 'center'
                                //, alignSelf: 'flex-end'
                                //, justifySelf: 'right'
                            }}>

                            <div
                                style = {{
                                    verticalAlign: 'middle'
                                    , textAlign: 'center'
                                    //, alignSelf: 'flex-end'
                                    //, justifySelf: 'right'
                                    , paddingLeft: '10px'
                                    , paddingRight: '10px'
                                }}>

                                <div>
                                    {
                                        userAccessToken == null
                                        ?
                                            <button
                                                //type = 'button'
                                                //className = { 'connect-wallet' }
                                                //className = { 'primary connect-wallet' }
                                                className = { 'theme-success connect-wallet' }
                                                //onClick={() => props.chooseWalletType()}
                                                onClick = { chooseWalletType }
                                                style = {{ padding: '8px 16px' }}
                                                >
                                                Investor Login
                                            </button>
                                        :
                                            <button
                                                //type = 'button'
                                                //className = { 'connect-wallet' }
                                                //className = { 'primary connect-wallet' }
                                                className = { 'theme-success connect-wallet' }
                                                //onClick={() => props.chooseWalletType()}
                                                //onClick = { chooseWalletType }
                                                //onClick = {e => handleDisconnectFunction(e)}>
                                                onClick = { handleDisconnectFunction }
                                                style = {{ padding: '8px 16px' }}
                                                >
                                                Logout
                                            </button>
                                    }
                                </div>
                            </div>

                        </div>
                        */}

                        <div
                            //className = 'cell'
                            >
                            <div
                                className = 'close'
                                onClick = { handleCloseModal }
                                title = 'Close'
                                style = {{
                                      padding: '30px'
                                    //, paddingBottom: '0px'
                                    //, verticalAlign: "middle"
                                    //, margin: "auto"
                                    , display: "inline-block"
                                    , paddingRight: '30px'
                                    , paddingLeft: '15px'
                                }}
                                >
                                {/*
                                <svg id="icon-close" viewBox="0 0 20 20" fill='#black' height="20px" width="20px">
                                    <path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"></path>
                                </svg>
                                */}
                                <img
                                    src = '/images/icons/close-icon-svg-primary.svg'
                                    style = {{ width: '32px', height: '32px' }}
                                    />
                            </div>
                        </div>
                {/*
                    </div>
                */}
                </div>

            </div>

            <div
                className="mobile-nav-content"
                //style = {{
                //      marginTop: "-30px"
                    //, display: 'none'
                //}}
                >

                {/*
                    userAccessToken
                    && <div className="input-search">
                        <input type="text" placeholder="Search" onChange={(e) => setSearchText(e.target.value)} onKeyDown={(e) => handleOnKeySearch(e)} />
                        <div className="search-btn" onClick={handleSearch}>
                            <svg viewBox="0 0 20 20" width="20px" height="20px" fill=''>
                                <path d="M12.9 14.32c-1.34 1.049-3.050 1.682-4.908 1.682-4.418 0-8-3.582-8-8s3.582-8 8-8c4.418 0 8 3.582 8 8 0 1.858-0.633 3.567-1.695 4.925l0.013-0.018 5.35 5.33-1.42 1.42-5.33-5.34zM8 14c3.314 0 6-2.686 6-6s-2.686-6-6-6v0c-3.314 0-6 2.686-6 6s2.686 6 6 6v0z" />
                            </svg>
                        </div>
                    </div>
                */}

                

                {/*
                    !userAccessToken
                    && (
                        <div className = "connect-btn">
                            <button
                                type = "button"
                                className = "connect-wallet"
                                onClick  = { chooseWalletType }
                                style = {{ color: 'white' }}
                                >
                                <img
                                    src={walletImageSrc}
                                    style={{ verticalAlign: 'middle', paddingRight: '5px', marginTop: '-3px' }}
                                    />
                                Connect Your Wallet
                            </button>
                        </div>
                    )
                    //: ''
                */}

                {/*
                <div
                    style={{
                    //      margin: "auto 10px"
                    //    , marginRight: "0px"
                        margin: "10px"
                    }}
                    >
                    <div>
                        <button
                            //type = 'button'
                            //className = { 'connect-wallet' }
                            className = { 'primary connect-wallet' }
                            //onClick={() => props.chooseWalletType()}
                            onClick = { chooseWalletType }
                            >
                            Investor Login
                        </button>
                    </div>
                </div>
                */}

                {/*
                <Link to="/about">
                  <div className="mobile-nav-item">
                    What we do
                  </div>
                </Link>
                */}

                <nav
                    //className = 'right'
                    style = {{
                          display: 'flex'
                        , gap: '8px'
                        //, flexDirection: 'row'
                        //, flexWrap: 'wrap'
                        //, alignItems: 'center'
                        //, padding: '20px 0px'
                        //, marginTop: '20px'
                    }}
                    >

                    {/*
                    <div
                        style = {{
                              display: 'flex'
                            //, justifyContent: 'space-between'
                            , justifyContent: 'flex-end'
                            , alignItems: 'center'
                        }}
                        >

                        <div
                            //className = 'cell'
                            style = {{
                                verticalAlign: 'middle'
                                , textAlign: 'center'
                                //, alignSelf: 'flex-end'
                                //, justifySelf: 'right'
                            }}>
                    */}

                            <div
                                style = {{
                                    verticalAlign: 'middle'
                                    //, textAlign: 'center'
                                    //, alignSelf: 'flex-end'
                                    //, justifySelf: 'right'
                                    //, paddingLeft: '10px'
                                    //, paddingRight: '10px'
                                }}>

                                <div>
                                    {
                                        userAccessToken == null
                                        ?
                                            <Link
                                                to = '/'
                                                style = {{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                                                //className = 'become-investor'
                                                >
                                            <button
                                                //type = 'button'
                                                //className = { 'connect-wallet' }
                                                //className = { 'primary connect-wallet' }
                                                className = { 'theme-success connect-wallet' }
                                                //onClick={() => props.chooseWalletType()}
                                                //onClick = { chooseWalletType }
                                                //onClick = {() => props.loginRegisterFunctions.showLoginDialog()}
                                                onClick = {() => clickLogin()}
                                                //style = {{ padding: '8px 16px' }}
                                                //style = {{
                                                //    padding: '16px'
                                                //    , width: '100%'
                                                //    , fontSize: '18px'
                                                //}}
                                                >
                                                Login
                                            </button>
                                            </Link>
                                        :
                                            <button
                                                //type = 'button'
                                                //className = { 'connect-wallet' }
                                                //className = { 'primary connect-wallet' }
                                                className = { 'theme-success connect-wallet' }
                                                //onClick={() => props.chooseWalletType()}
                                                //onClick = { chooseWalletType }
                                                //onClick = {e => handleDisconnectFunction(e)}>
                                                //onClick = { handleDisconnectFunction }
                                                onClick = { logoutUserFunction }
                                                //style = {{ padding: '8px 16px' }}
                                                //style = {{ padding: '16px' }}
                                                style = {{ padding: '16px', width: '100%', fontSize: '18px' }}
                                                >
                                                Logout
                                            </button>
                                    }
                                </div>
                            </div>
                    {/*
                        </div>
                    </div>
                    */}

                    {
                        !userAccessToken
                        &&
                            <div>
                                {/*
                                <Link
                                    to = '/edit-profile'
                                    style = {{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                                    //className = 'become-investor'
                                    >
                                */}
                                    <button
                                        //type = 'button'
                                        //className = { 'connect-wallet' }
                                        //className = { 'primary-inverse connect-wallet' }
                                        className = 'become-investor large'
                                        //className = 'primary-inverse large become-investor'
                                        //onClick={() => props.chooseWalletType()}
                                        //onClick = { chooseWalletType }
                                        //onClick = { props.loginRegisterFunctions.showRegisterDialog(); }
                                        //onClick = { () => props.loginRegisterFunctions.showRegisterDialog() }
                                        //onClick = { () => props.clickBecomeInvestor() }
                                        //onClick = { (event) => props.loginRegisterFunctions.clickBecomeInvestor(event) }
                                        onClick = { (event) => clickEarlyRegistration(event) }
                                        >
                                        {/*
                                        Become an Investor
                                        */}
                                        Early Registration
                                    </button>
                                {/*
                                </Link>
                                */}
                            </div>
                    }

                    {/*
                    <div
                        //className="input-search"
                        className = 'input-box input-search'
                        style = {{
                                margin: "10px"
                            //  margin: '0px 10px 10px 10px'
                            //, marginLeft: '0px'
                            //, margin: "10px"
                        }}
                        >
                        <input
                            type = 'text'
                            placeholder = 'Search'
                            onChange = {(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => handleOnKeySearch(e)}
                            //style = {{ width: 'calc(100% - 20px)', outline: 'none' }}
                            style = {{ width: '100%', outline: 'none' }}
                            />
                        <div className="search-btn" onClick={handleSearch}>
                            <svg
                                viewBox="0 0 20 20"
                                width="20px"
                                height="20px"
                                //fill='grey'
                                >
                                <path d="M12.9 14.32c-1.34 1.049-3.050 1.682-4.908 1.682-4.418 0-8-3.582-8-8s3.582-8 8-8c4.418 0 8 3.582 8 8 0 1.858-0.633 3.567-1.695 4.925l0.013-0.018 5.35 5.33-1.42 1.42-5.33-5.34zM8 14c3.314 0 6-2.686 6-6s-2.686-6-6-6v0c-3.314 0-6 2.686-6 6s2.686 6 6 6v0z" />
                            </svg>
                        </div>
                    </div>
                    */}

                    {/*
                    <div>
                        <Link
                            to='/about'
                            style = {{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                            >
                            <button
                                className = 'primary-inverse'
                                style = {{ whiteSpace: 'nowrap' }}
                                >
                                About Us
                            </button>
                        </Link>
                    </div>
                    */}

                    {/*
                    <div>
                        <Link to='/search/all' style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                            Our Portfolio
                        </Link>
                    </div>
                    */}

                    {/*
                </nav>

                <div className="mobile-nav-item-wrap">

                    */}

                    {/*
                    <div
                        //className={`mobile-nav-item dropdown-btn ${selectedDropdown === dropdownList[0] ? 'active' : ''}`}
                        //className = { 'dropdown-btn' }
                        //onClick={() => (toggleSelectedDropdown(dropdownList[0]))}
                        >
                        <Link to='/search/all' style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                            Our Portfolio
                        </Link>
                    </div>
                    */}

                    {/*
                    <div
                        //className={`dropdown  ${selectedDropdown === dropdownList[0] ? 'active' : ''}`}
                        className = { 'dropdown active' }
                        >
                        {
                            categories.map((item, index) => (
                                <div
                                    className = 'mobile-nav-item'
                                    onClick = {() => { history.push('/search/' + item?.name) }}
                                    key = { index }>
                                    { item.name }
                                    <span
                                        className = { 'dot '+ item.name }
                                    >
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                    */}

                    {/*
                    <div
                        //className={`dropdown  ${selectedDropdown === dropdownList[0] ? 'active' : ''}`}
                        className = { 'dropdown active' }
                        >
                    */}

                        <div style = {{ paddingTop: '50px' }}>
                            <Link
                                to='/about'
                                className = 'product-category'
                                style = {{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                                >
                                <button
                                    className = 'primary-inverse mobile-nav-item'
                                    style = {{ whiteSpace: 'nowrap' }}
                                    >
                                    About Us
                                </button>
                            </Link>
                        </div>

                        <div
                            //style = {{ paddingBottom: '10px'}}
                            >
                            <a
                                href = 'https://docsend.com/v/ksnss/business-plan-community'
                                target = '_blank'
                                className = 'product-category'
                                style = {{
                                    cursor: 'pointer'
                                    , whiteSpace: 'nowrap'
                                }}>
                                <button
                                    //className = 'primary-inverse'
                                    className = 'primary-inverse mobile-nav-item'
                                    style = {{ whiteSpace: 'nowrap' }}
                                    >
                                    Strategy
                                </button>
                            </a>
                        </div>

                        <div
                            style = {{ paddingBottom: '10px'}}
                            >
                            <Link
                                to='/search/all'
                                className = 'product-category'
                                style = {{
                                    cursor: 'pointer'
                                    , whiteSpace: 'nowrap'
                                }}>
                                <button
                                    //className = 'primary-inverse'
                                    className = 'primary-inverse mobile-nav-item'
                                    style = {{ whiteSpace: 'nowrap' }}
                                    >
                                    Our Portfolio
                                </button>
                            </Link>
                        </div>

                        {
                            categories
                                .sort((a, b) => a.id - b.id)
                                .map((item, index) => (
                                <div
                                    style = {{
                                        borderRadius: '4px'
                                        //, fontSize: '13px'
                                        //, fontStyle: 'normal'
                                        //, fontWeight: '500'
                                        //, padding: '4px 8px'
                                    }}

                                    //className = 'mobile-nav-item'
                                    //className = { item.name }
                                    //className = { 'mobile-nav-item', item.name }
                                    //className = {
                                    //    cn(
                                    //        'mobile-nav-item'
                                    //        , item.name
                                    //    )
                                    //}

                                    onClick = {() => { history.push('/search/' + item?.name) }}
                                    key = { index }
                                    >
                                    <Link
                                        to='/search/all'
                                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                                        className = 'product-category'
                                        >
                                        <button
                                            //className = 'primary-inverse'
                                            //className = 'large rounded'
                                            className = {
                                                cn(
                                                    'mobile-nav-item'
                                                    , item.name
                                                    , 'product-category'
                                                    , 'text-shadow'
                                                    //, 'large'
                                                    , 'rounded'
                                                    //, 'circular'
                                                )
                                            }
                                            style = {{ whiteSpace: 'nowrap' }}
                                            >
                                            { item.name }
                                        </button>
                                    </Link>
                                    
                                </div>
                            ))
                        }

                    {/*
                    </div>
                    */}

                </nav>

            </div>
        </div>
        </>
    );
};

export default MobileNav;
