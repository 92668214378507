import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styles from "./UserProfileMenu.module.sass";
import { NAMES_CONSTANTS } from '../../../constants';
import DiamondIcon from '@mui/icons-material/Diamond';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

/*
const UserProfileMenu = ({
      handleDisconnectFunction
    , handleTokenExpiredFunction
    , userDetails
}) => {
*/
const UserProfileMenu = (props) => {
    //console.log('UserProfileMenu()')
    //console.warn('UserProfileMenu()', props)
    //console.log('UserProfileMenu: props.userDetails = ', props.userDetails)
    //console.log('UserProfileMenu: props.userProfileMenuRef = ', props.userProfileMenuRef)
    //console.log('UserProfileMenu: props.loginRegisterFunctions = ', props.loginRegisterFunctions)
    //console.log('UserProfileMenu: props.toggleShowUserProfileMenu = ', props.toggleShowUserProfileMenu)
    //console.log('UserProfileMenu: props.onOutsideClickUserProfileMenu = ', props.onOutsideClickUserProfileMenu)

    //const userProfileMenuRef = useRef(null);

    const history = useHistory();
    const [visible, setVisible] = useState(false);
    //const [params, setParams] = useState({ limit: 9, page: 1 });
    //const [details, setDetails] = React.useState({});
    //const [profilePic, setProfilePic] = React.useState(null);
    const [walletDetails] = useState(
        localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS)
    );
    let walletDetailsObject;
    //let tokenBal;
    //console.log('walletDetails', walletDetails)
    if (walletDetails) {
        walletDetailsObject = JSON.parse(walletDetails);
        //console.log('walletDetailsObject', walletDetailsObject)
        //tokenBal = walletDetailsObject.balance;
    }

    //const [profilePic, setProfilePic] = React.useState(null);

    //const [profile, setProfile] = useState('');
    // const profile = useSelector((state) => state.counter.provider);
    //const dispatch = useDispatch();

    /*
      const getDetails = async () => {
          const config = getConfig();
          await axiosInstance
              .get(`user/get-profile`, config)
              .then((res) => {
                  const { profilePicUrl } = res.data?.result;
                  dispatch(setWallet(res?.data?.result?.wallet))
                  dispatch(storeUserVerifyStatus(res?.data?.result?.status))
                  dispatch(setProfilePicValue(profilePicUrl))
                  dispatch(setKycVerifyStatus(res?.data?.result?.kycVerified))
                  setProfile(profilePicUrl);
              }
          )
          .catch((err) => {
              if(err.response.data.message === 'Your account is blocked by Admin'){
                  PopUpAlert("Alert..", err.response.data.message, "error").then(() => {
                      document.getElementById('connectWallet').click()
                  })
              }
          });
      };
    */

    /*
    const showHideProfileMenu = () => {
        let menu = document.getElementById('profile-dropdown-menu');
        if (menu) menu.style.display = 'block';
    };
    */

    const navigateTo = (path) => {
        history.push(path);
        return false;
    };

    /*
    const handleClose = () => {
        alert('handleClose()');
        alert('handleClose(): visible = ' + visible);
        setVisible(false);
        //setSubcategory({});
    };
    */

    /*
    const toggleVisible = () => {
        alert('toggleVisible()');
        alert('toggleVisible(): visible = ' + visible);
        setVisible(!visible);
        //setSubcategory({});
    };
    */
    
    /*
    const clickWalletAddress = function(x) {
        //https://mumbai.polygonscan.com/address/0xd072f7d4c6be619fd42b97045a45b0cdcbc09898
        window.open('https://mumbai.polygonscan.com/address/' + x, '_blank');
    };
    */

    /*
    const updateHeaderProfileImage = (x) => {      
        alert("updateHeaderProfileImage("+x+")");
        let profileImageElement = document.getElementById('header-profile-image');
        profileImageElement.setAttribute('src', x);
    };
    */

    return (

        <div
            //id = 'profile-dropdown-menu'
            //id = { props.componentId ? props.componentId : 'profile-dropdown-menu' }
            id = { props.componentId ? props.componentId : '' }
            //className = { styles.profileDropdownMenu }
            className = { props.componentId ? props.componentId : 'profile-dropdown-menu' }
            //ref = { userProfileMenuRef }
            ref = { props.userProfileMenuRef }
            //style = {{
            //      marginBottom: '150px'
            //}}
            onScroll = { (e) => {
                console.log('UserProfileMenu: onScroll(e)', e);
            }}
            >

            <div
                style = {{
                      marginRight: '0px'
                    , marginTop: '0px'
                    //, marginBottom: '150px'
                }}
                onScroll = { (e) => {
                    console.log('Scroll1: ', e);
                }}
                >

                <div style = {{ marginLeft: '0px' }}>

                    {/*
                    <div
                        className = { 'table' }
                        //style = {{ display: 'none' }}
                        >

                        <div className = { 'row' }>
                            <div
                                className = { 'cell' }
                                style = {{ verticalAlign: 'top', marginTop: '0' }}>
                    */}

                    {/*
                    <div
                        className={'title'}
                        style={{ display: 'block', textTransform: 'capitalize' }}>
                        { props.userDetails?.email }
                    </div>
                    */}

                    <div className={'profile-photo-box'}>
                        <div
                            style = {{
                                  textAlign: 'center'
                                , margin: 'auto'
                                , paddingBottom: '10px'
                            }}
                            >

                            {/*
                            <div
                                className={'title'}
                                style={{ display: 'block', textTransform: 'capitalize' }}>

                                Firstname Lastname
                                { details?.firstName } { details?.lastName }
                            </div>
                            */}

                            {
                                props.userDetails?.firstName || props.userDetails?.lastName
                                ?
                                    <div
                                        className = { 'table' }
                                        //style = {{ paddingLeft: '20px'}}
                                        style = {{ margin: 'auto' }}
                                        >
                                        <div className = { 'row' }>
                                            <div
                                                className = { 'cell' }
                                                style = {{
                                                    verticalAlign: 'top'
                                                    , marginTop: '0'
                                                    //, textTransform: 'capitalize'
                                                    //, padding: '0px 0px 20px 0px'
                                                }}>

                                                {/*
                                                */}
                                                <div
                                                    className = { 'title' }
                                                    //style = {{ paddingTop: '10px' }}
                                                    style = {{
                                                        padding: '10px 0px 0px 0px'
                                                        , textTransform: 'capitalize'
                                                        //, color: 'var(--color-gray-700)'
                                                        , color: 'var(--color-theme-primary)'
                                                        //, fontSize: '22px'
                                                        //, fontWeight: '700'
                                                        //, fontSize: '1.2em'
                                                    }}
                                                    >
                                                    { props.userDetails?.firstName } { props.userDetails?.lastName }
                                                {/*
                                                */}
                                                </div>
                                                <div
                                                    className = { 'subtitle' }
                                                    //style = {{ paddingTop: '10px' }}
                                                    style = {{
                                                        paddingTop: '0px'
                                                        //, textTransform: 'capitalize'
                                                        , color: 'var(--color-gray-600)'
                                                        , fontWeight: '400'
                                                        //, fontSize: '1.1em'
                                                    }}
                                                    >
                                                    { props.userDetails?.email }
                                                {/*
                                                */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div
                                        className = { 'title' }
                                        style = {{
                                            display: 'inline-block'
                                            //, textTransform: 'capitalize'
                                            , color: 'var(--color-gray-700)'
                                            , fontSize: '15px'
                                            , fontWeight: '500'
                                            , textAlign: 'center'
                                            , margin: 'auto'
                                            , padding: '10px 0px'
                                        }}>
                                        { props.userDetails?.email }
                                    </div>
                            }

                            {/*
                            <div className = { "title" } style = {{ display: "block" }}>0xc4c16a645...b21a</div>
                            */}

                            {/*
                            <div
                                className={'subtitle'}
                                style={{
                                  whiteSpace: 'nowrap'
                                }}>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'middle',
                                        //, textAlign: "center"
                                        maxWidth: '150px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        //, fontFamily: "'Poppins'"
                                        //, fontStyle: "normal"
                                        //, fontWeight: "500"
                                        //, fontSize: "14px"
                                        //, lineHeight: "24px"
                                        //, color: "#777E91"
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer'
                                    }}
                                    onClick = { () => {
                                        clickWalletAddress(details?.publicAddress);
                                    }}>

                                    { details?.publicAddress }

                                </span>
                                <img
                                    src='/images/icons/coins-filled-icon.svg'
                                    style={{
                                          verticalAlign: 'middle'
                                        , width: '16px'
                                        , height: '16px'
                                        , paddingLeft: '8px'
                                    }}
                                />
                            </div>
                            */}
                        </div>
                    </div>
                </div>

                {/*
                <div style={{ marginLeft: '0px', marginBottom: '10px' }}>
                    <div
                        style={{
                              background: '#fff'
                            //, boxShadow: '0px 24px 24px 0px rgba(15, 15, 15, 0.2)'
                            , boxShadow: '0px 12px 12px 0px rgba(15, 15, 15, 0.1)'
                            , borderRadius: '16px'
                            , padding: '10px'
                        }}>

                        <div className={'table'}>
                            <div className={'row'}>
                                <div className={'cell'} style={{ verticalAlign: 'middle' }}>
                                  <img
                                    //src = '/images/icons/matic-logo.svg'
                                    //src = '/images/logos/usdc/Circle_USDC_Logo.svg'
                                    src = '/images/icons/Dollar_sign_in_circle.svg'
                                    alt = 'Dollar sign in circle'
                                    style={{
                                      verticalAlign: 'middle',
                                      width: '40px',
                                      height: 'auto'
                                    }}
                                  />
                                </div>
                                <div
                                    className={'cell'}
                                    style={{
                                      fontFamily: "'Poppins'",
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      fontSize: '12px',
                                      lineHeight: '20px',
                                      color: '#777E91',
                                      paddingLeft: '15px'
                                    }}>
                                    Balance
                                    <div
                                        style={{
                                          fontFamily: "'Poppins'",
                                          fontStyle: 'normal',
                                          fontWeight: '600',
                                          fontSize: '24px',
                                          lineHeight: '32px',
                                          color: '#141416',
                                          whiteSpace: 'nowrap'
                                        }}>
                                        {tokenBal ? parseFloat(tokenBal)?.toFixed(2) : 0}
                                        <span style={{ fontSize: '13px', paddingLeft: '8px' }}>
                                            USD
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            className={'clear-button'}
                            style={{
                              width: '100%',
                              fontFamily: "'DM Sans'",
                              fontStyle: 'normal',
                              fontWeight: '700',
                              fontSize: '14px',
                              lineHeight: '16px',
                              textAlign: 'center',
                              color: '#23262F',
                              marginTop: '10px'
                            }}
                            onClick = { () => history.push('/mywallet') }>
                            Open your Wallet
                        </button>
                        
                    </div>
                </div>
                */}

                <div className={'profile-menu'} style={{ marginLeft: '0px' }}>
                    
                    <div
                        className = { 'profile-menu' }
                        style = {{
                            marginLeft: '0px'
                            //, marginBottom: '20px'
                        }}>
                        <div className = { 'table' }>
                            <div className = {'row heading'}>
                                <div className = {'cell'}>
                                    {/*
                                    <img src = '/images/icons/account-settings-icon.svg' />
                                    */}
                                    <PersonIcon color='primary' sx = {{ fontSize: 30 }} />
                                </div>
                                <div className = { 'cell' }>Account</div>
                                {/*
                                <div className = { "cell" }>
                                </div>
                                */}
                            </div>

                            <div className={'row'}>
                                <div
                                    className={`cell ${styles.cell}`}
                                    style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                                <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/user-profile')}>
                                    <Link to='/user-profile'>
                                        <button
                                            className = 'primary-inverse'
                                            >
                                            Profile
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr
                        style={{
                              height: '1px'
                            , border: 'none'
                            , backgroundColor: '#E6E8EC'
                            , marginTop: '0px'
                            , padding: '0px'
                            , marginBottom: '5px'
                        }} />

                    <div
                        className={'table'}
                        style = {{
                            marginLeft: '0px'
                            //, marginBottom: '20px'
                        }}>
                        <div className={'row heading'}>
                            <div className={'cell'}>
                                {/*
                                <img src='/images/icons/asset-management-icon.svg' />
                                */}
                                <DiamondIcon color='primary' sx = {{ fontSize: 30 }} />
                            </div>
                            <div className={'cell'}>Asset Management</div>
                            {/*
                            <div className = { "cell" }>
                            </div>
                            */}
                        </div>

                        <div className={'row'}>
                            <div
                                className={`cell ${styles.cell}`}
                                style={{ verticalAlign: 'top', marginTop: '0' }}>
                            </div>
                            <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/my-assets')}>
                                <Link
                                    to='/my-assets'
                                    //onClick={() => setVisible(!visible)}
                                    //key={index}
                                    >
                                    <button
                                        className = 'primary-inverse'
                                        >
                                        My Assets
                                    </button>
                                </Link>
                            </div>
                            {/*
                            <div className = { "cell" }>
                                <img src="/images/icons/right-arrow-icon.svg" style = {{ width: "20px", height: "20px", verticalAlign: "bottom" }} />
                            </div>
                            */}
                        </div>

                        <div className={'row'}>
                            <div
                                className={`cell ${styles.cell}`}
                                style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                            <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/order-details')}>
                                <Link to='/order-details'>
                                    <button
                                        className = 'primary-inverse'
                                        >
                                        Order Details
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div
                                className={`cell ${styles.cell}`}
                                style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                            <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/auctions')}>
                                <Link to='/auctions'>
                                    <button
                                        className = 'primary-inverse'
                                        >
                                        Auctions
                                    </button>
                                </Link>
                            </div>
                        </div>

                        {/*
                        <div className={'row'}>
                            <div
                                className={`cell ${styles.cell}`}
                                style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                            <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/search/All')}>
                                <Link to = "/search/All">Purchase Asset</Link>
                                <Link to='/search/All'>Marketplace</Link>
                            </div>
                            <div className = { "cell" }>
                                <img src="/images/icons/right-arrow-icon.svg" style = {{ width: "20px", height: "20px", verticalAlign: "bottom" }} />
                            </div>
                        </div>
                        */}

                        <div className={'row'}>
                            <div
                                className={`cell ${styles.cell}`}
                                style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                                <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/resell-assets')}>
                                    <Link to='/resell-assets'>
                                        <button
                                            className = 'primary-inverse'
                                            >
                                            Resell
                                        </button>
                                    </Link>
                                </div>
                                {/*
                                <div className = { "cell" }>
                                    <img src="/images/icons/right-arrow-icon.svg" style = {{ width: "20px", height: "20px", verticalAlign: "bottom" }} />
                                </div>
                                */}
                            </div>
                        </div>
                    </div>


                    <hr
                        style={{
                              height: '1px'
                            , border: 'none'
                            , backgroundColor: '#E6E8EC'
                            , marginTop: '0px'
                            , padding: '0px'
                            , marginBottom: '5px'
                        }} />

                    <div
                        className = {'profile-menu'}
                        style = {{
                            marginLeft: '0px'
                            //, marginBottom: '20px'
                        }}>
                        <div className = {'table'}>
                            <div className = {'row heading'}>
                                <div className = {'cell'}>
                                    {/*
                                    <img src = '/images/icons/referred-affiliates-1.png' />
                                    */}
                                    <img
                                        src = '/images/icons/referred-affiliates-1.png'
                                        alt = 'Referred Affiliates'
                                        />
                                </div>
                                <div className = { 'cell' }>Rewards</div>
                                {/*
                                <div className = { 'cell' }>Referrals</div>
                                <div className = { "cell" }>
                                </div>
                                */}
                            </div>

                            <div className={'row'}>
                                <div
                                    className={`cell ${styles.cell}`}
                                    style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                                <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/referred-affiliates')}>
                                    <Link to='/referred-affiliates'>
                                        <button
                                            className = 'primary-inverse'
                                            >
                                            Referred Affiliates
                                        </button>
                                    </Link>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div
                                    className={`cell ${styles.cell}`}
                                    style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                                <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/reward-history')}>
                                    <Link to='/reward-history'>
                                        <button
                                            className = 'primary-inverse'
                                            >
                                            Earnings
                                        </button>
                                    </Link>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div
                                    className={`cell ${styles.cell}`}
                                    style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                                <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/my-referral-link')}>
                                    <Link to='/my-referral-link'>
                                        <button
                                            className = 'primary-inverse'
                                            >
                                            Referral Link
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*
                    <hr
                        style={{
                            height: '1px',
                            border: 'none',
                            backgroundColor: '#E6E8EC',
                            marginTop: '0px',
                            padding: '0px'
                        }}
                        />

                    <div className={'profile-menu'} style={{ marginLeft: '0px' }}>
                        <div className={'table'}>
                            <div className={'row heading'}>
                                <div className={'cell'}>
                                    <img src='/images/icons/order-management-icon.svg' />
                                </div>
                                <div className={'cell'}>Order Management</div>
                            </div>

                            <div className={'row'}>
                                <div
                                    className={`cell ${styles.cell}`}
                                    style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                                <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/order-details')}>
                                    <Link to='/order-details'>Order Details</Link>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div
                                    className={`cell ${styles.cell}`}
                                    style={{ verticalAlign: 'top', marginTop: '0' }}></div>
                                <div className={`cell ${styles.cell}`} onClick = {() => navigateTo('/auctions')}>
                                    <Link to='/auctions'>Auctions</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    */}

                    <hr
                        style={{
                              height: '1px'
                            , border: 'none'
                            , backgroundColor: '#E6E8EC'
                            , marginTop: '0px'
                            , padding: '0px'
                            , marginBottom: '5px'
                        }}
                        />

                    <div className={'profile-menu'} style={{ marginLeft: '0px' }}>
                        <div className = 'table'>
                            <div className = 'row heading'>
                                <div className = 'cell'>
                                    {/*
                                    <img src = '/images/icons/disconnect-icon.svg' />
                                    */}
                                    <LogoutIcon
                                        color = 'primary'
                                        style = {{
                                            //padding: '0px 4px'
                                             width: '30px'
                                            , height: '30px'
                                        }} 
                                        />
                                </div>

                                <div
                                    className = 'cell'
                                    >
                                    {/*
                                    <button
                                        //onClick={status ? (e)=>handleDisconnect(e) : ''}
                                        //onClick = {(e) => handleDisconnect(e)}
                                        //onClick = function(e) { alert(e) }
                                        //onClick = { (e) => alert(e) }
                                        //onClick = { e => props.handleDisconnectFunction(e) }>
                                        onClick = { e => props.loginRegisterFunctions.logoutUser(e) }
                                        //onClick = { props?.loginRegisterFunctions?.logoutUser }
                                        //onClick = { props.loginRegisterFunctions.logoutUser }
                                        >
                                        Log Out
                                    </button>
                                    */}
                                    <Link
                                        to='/my-assets'
                                        //onClick={() => setVisible(!visible)}
                                        //key={index}
                                        >
                                        <button
                                            className = 'primary-inverse'
                                            onClick = { e => props.loginRegisterFunctions.logoutUser(e) }
                                            >
                                            Log Out
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    );
};

export default UserProfileMenu;
