import React, { useState, useEffect } from "react";
import cn from "classnames";
import Slider from "react-slick";
//import { Link } from "react-router-dom";
import styles from "./ResaleAssets.module.sass";
//import Add from "./Add";
import Icon from "../../../components/Icon";
import API, { axiosInstance } from "../../../utils/API";
//import Dropdown from "../../../components/Dropdown";
//import DropdownEmpty from "../../../components/DropdownEmpty";
import { useSelector } from "react-redux";
//import { current_datetime } from "../../../controller/utils";
//import ReactPlayer from "react-player";
//import moment from "moment";
import { useHistory } from 'react-router-dom';
//import hasAuctionEnded from "../../../utils/auctionEnded";
//import { displayPrice } from "../../../utils/displayPrice";
//import { CRYPTOCURRENCY_CONSTANTS } from '../../../constants';

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const dateOptions = ["Today", "Morning", "Dinner", "Evening"];
const directionOptions = ["Sellers", "Buyers"];

const ResaleAssets = (props) => {

    const history = useHistory()
    //const exchangeRate = useSelector(state  => state.counter.euroValue);
    const [data, setData] = useState([]);
    //const [date, setDate] = useState(dateOptions[0]);
    //const [direction, setDirection] = useState(directionOptions[0]);
    //const euroValue = useSelector((state) => state.counter.euroValue) || 0;

    useEffect(() => {

        getCategoryList();

        let params = {
          // limit: 6,
          // page: 1,
        };

        //API.get("/asset/all?latest=true&limit=25", {
        //API.get("/asset/all?saleType=past-deal&limit=25", {
        API.get("/asset/all?latest=true&saleType=fixed&isResell=true&limit=25", {
            params
        }).then((res) => {
            if (res.data?.result.assets.rows) {
                let data = res.data?.result.assets.rows
                setData(data)
            }
        })
    }, [])

    const sliderSettings = {

        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // adaptiveHeight: true,
        nextArrow: (
          <SlickArrow>
            {/*
            <Icon name="arrownext" viewbox="0 0 13 22" size="13" fill="#FFFFFF" />
            */}
            <Icon name="arrownext" viewbox="0 0 13 22" size="20" fill="#000" />
          </SlickArrow>
        ),
        prevArrow: (
          <SlickArrow>
            <Icon name="arrowprev" viewbox="0 0 13 22" size="20" fill="#000" />
          </SlickArrow>
        ),

        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              infinite: true,
            },
          },
          {
            breakpoint: 699
            , settings: {
              slidesToShow: 1
              , infinite: true
            }
          }
        ]
    };

    /*
    const limitTextToCount = (text, count) => {
        return text.slice(0, count) + (text.length > count ? "..." : "");
    };
    */

    const checkForUpcomingBid = (bidStartDate) => {
        let time = new Date(bidStartDate);
        var now = new Date();
        return time < now ? false : true;
    }

    const [categoryFilterObj, setCategoryFilterObj] = useState(null);
    const [categoryList, setCategoryList] = useState([]);

    const getCategoryList = async () => {
        console.log('getCategoryList()');
        await axiosInstance.get('/asset/categories?page=1&limit=100')
            .then(result => {
                console.log(result?.data?.result?.categories);
                console.log(result?.data?.result?.categories.rows);
                setCategoryFilterObj(result?.data?.result?.categories?.rows);
                let categories = result?.data?.result?.categories?.rows?.map(
                    category => category.name
                );
                setCategoryList(['All', ...categories]);
            })
            .catch(err => { });
    };

    const clickCategory = (categoryName, event) => {
        console.log('clickCategory('+categoryName+'`, '+event+')');
        history.push("/search/" + categoryName);
        console.log('event', event);
        console.log('event.stopPropagation = '+event.stopPropagation);
        event.stopPropagation();
        return false;
    };

    const getCategoryName = (categoryId) => {
        console.log('getCategoryName('+categoryId+')');
        /*
        let categoryId = categoryFilterObj?.filter(
            item => item.name === categoryFilter
        )[0]?.id;
        */
        return categoryFilterObj?.filter(
            item => item.id === categoryId
        )[0]?.name;
    };

    //let categoryName;

    return (

        <section
            //className = { "dark" }
            style = {{
                backgroundColor: "white"
                , backgroundImage: 'none'
                //, backgroundImage: 'url(/images/backgrounds/city-sky.jpg)'
                , paddingBottom: '0px' // overwrite padding-bottom
            }}
            >
            
            <div
                id = 'latest-arrivals-wrapper'
                className={"max-width"}
                >

                <h2
                    className = { styles.sectionTitle }
                    style = {{ color: '#000' }}
                    >Assets Listed for Resale</h2>

                {/*
                <div className = { cn("container", "card-container") } style = {{ gap: "10px", justifyContent: "spaceBetween" }}>
                */}
                <div
                    id = 'latest-arrivals-container'
                    className = {"container"}
                    style={{
                        gap: "10px"
                        //, justifyContent: "spaceBetween"
                        , justifyContent: "space-between"
                        , padding: 0
                    }}>
                    {
                        data.length
                        ? <>
                        {
                            <div
                                className = 'assets-container'
                                style = {{
                                    display: 'flex'
                                    , flexWrap: 'wrap'
                                    , gap: '30px'
                                    , justifyContent: 'space-evenly'
                                    , alignItems: 'flex-start'
                                    , alignContent: 'stretch'
                                }}
                                >
                                
                                    {
                                        data
                                        .sort((a, b) => a.id - b.id)
                                        .map((x, index) => {
                                            //{ categoryName = getCategoryName(x?.asset.categoryId) }
                                            if (!x?.asset) return;
                                            
                                            //const upcomingAsset = x?.auction ? checkForUpcomingBid(x?.auction?.startDate) : false;
                                            return (
                                                <div
                                                    //className = { styles.slide }
                                                    className = 'asset-container'
                                                    key={index}>
                                                    <div
                                                        className = { cn(
                                                              "item"
                                                            , getCategoryName(x?.asset.categoryId)
                                                            , styles.item
                                                        )}

                                                        //onClick = { () => history.push("/search/" + categoryName) }
                                                        //onClick = { () => history.push("/search/" + getCategoryName(x?.asset.categoryId)) }
                                                        //onClick = { () => clickCategory(getCategoryName(x?.asset.categoryId)) }
                                                        onClick = { () => history.push('/item/' + x?.id) }
                                                        style = {{
                                                            height: '100%'
                                                            //, maxHeight: '500px'
                                                        }}
                                                        >
                                                        <div
                                                            className = { cn('item-top') }
                                                            //style = {{ height: '100%' }}
                                                            style = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                            >

                                                            <div>
                                                                <button
                                                                    //onClick = { (event) => clickCategory(getCategoryName(x?.asset.categoryId), event) }
                                                                    onClick = { (event) => clickCategory(getCategoryName(x?.asset.categoryId), event) }
                                                                    >
                                                                    {/*
                                                                    { getCategoryName(x?.asset.categoryId) }
                                                                    { categoryName }
                                                                    */}
                                                                    { getCategoryName(x?.asset.categoryId) }

                                                                </button>
                                                            </div>

                                                            {
                                                                //productDetails?.status == 'past-deal'
                                                                //assetObject?.asset?.status == 'past-deal'
                                                                //assetObject?.saleType == 'past-deal'
                                                                x?.isResell
                                                                && <div
                                                                    style = {{
                                                                        //color: 'white'
                                                                        display: 'flex'
                                                                        //, marginLeft: '8px'
                                                                        , margin: 'auto'
                                                                        //, position: 'absolute'
                                                                        //, right: '0'
                                                                        //, top: '-40px'
                                                                        //, border: 'red 1px solid'
                                                                        , textAlign: 'center'
                                                                    }}>

                                                                    <div
                                                                        style = {{
                                                                              padding: '3px 8px'
                                                                            , fontSize: '13px'
                                                                            , fontWeight: '500'
                                                                            , verticalAlign: 'top'
                                                                            , fontFamily: 'DM Sans'
                                                                            , textAlign: 'center'
                                                                            , textTransform: 'none'
                                                                            , border: 'green 2px solid'
                                                                            //, borderWidth: '2px 2px 0px 2px'
                                                                            //, borderWidth: '2px'
                                                                            //, borderBottomWidth: '0px'
                                                                            //, backgroundColor: 'white'
                                                                            //, backgroundColor: assetObject?.isResell ? 'green' : 'white'
                                                                            , backgroundColor: 'green'
                                                                            //, borderTopLeftRadius: '3px'
                                                                            //, borderTopRightRadius: '3px'
                                                                            , borderRadius: '3px'
                                                                            , margin: 'auto'
                                                                            //, color: 'green'
                                                                            //, color: assetObject?.isResell ? 'white' : 'green'
                                                                            , color: 'white'
                                                                            , boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'
                                                                        }}>
                                                                        
                                                                        {
                                                                            x?.isResell
                                                                            ? <span>Resale {
                                                                                    x?.availableFractions
                                                                                }/{
                                                                                    x?.totalFractions
                                                                                }
                                                                            </span>
                                                                            : <span>Available</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div
                                                                className = 'small-details'
                                                                style = {{ display: 'inline-block' }}
                                                                >
                                                                <span
                                                                    //className = 'label'
                                                                    style = {{
                                                                          color: 'var(--color-gray-600)'
                                                                        , fontSize: '13px'
                                                                        , fontWeight: '500'
                                                                        //, textTransform: 'uppercase'
                                                                    }}
                                                                    >
                                                                    Closing Date:
                                                                </span>
                                                                &nbsp;
                                                                <span
                                                                    className = 'label'
                                                                    style = {{ whiteSpace: 'nowrap' }}
                                                                    >
                                                                    {
                                                                        new Date(
                                                                            Date.parse(x?.asset.closingDate)
                                                                        )
                                                                        .toLocaleDateString(
                                                                            "en-US"
                                                                            //, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
                                                                            //, { year: 'numeric', month: 'long', day: 'numeric' }
                                                                            , { year: 'numeric', month: 'short', day: 'numeric' }
                                                                        )
                                                                    }
                                                                </span>
                                                            </div>

                                                        </div>

                                                        {/*
                                                        mediaType = {x?.asset.mediaType}
                                                        <br/>mediaPreviewUrl = {x?.asset.mediaPreviewUrl}
                                                        <img src="images/products/watch-black.png" style = {{ margin: "auto", padding: "0px 18px" }} />
                                                        */}

                                                        {
                                                            x?.asset.mediaType === "image"
                                                            &&
                                                            <img
                                                                src = { x?.asset.mediaPreviewUrl }
                                                                style = {{
                                                                //  margin: "auto",
                                                                //  width: "100%"
                                                                    maxHeight: '200px'
                                                                    , minHeight: '200px'
                                                                }}
                                                                alt="Avatar"
                                                                />
                                                        }

                                                        <div
                                                            //className = { cn('item-bottom') }
                                                            style = {{
                                                                padding: "16px"
                                                                , flexBasis: "100%"
                                                                , display: "flex"
                                                                , flexDirection: 'column'
                                                                , backgroundColor: '#FFFFFF'
                                                            }}
                                                            >

                                                            <div
                                                                className = { "watch-info-box" }
                                                                style = {{ flexGrow: '0', flexShrink: '1', flexBasis: 'auto' }}
                                                                >
                                                                <div>
                                                                    <div className={"watch-title"}>{ x?.asset?.name }</div>
                                                                </div>
                                                                <div>
                                                                    <div className = { "watch-subtitle" }>
                                                                      {/*
                                                                      {x?.asset?.creatorName}
                                                                      */}
                                                                      { x?.asset?.location }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/*
                                                            <div className={"price-box"}>
                                                              <div>
                                                                <div>Price USD</div>
                                                                <div>Price Crypto</div>
                                                              </div>
                                                              <div>
                                                                <div className = { "price price-dollars" }>US${x?.asset ? displayPrice(x?.asset, exchangeRate) : ''}</div>
                                                                <div className = { "price price-crypto" }>{(x?.asset?.regularPrice)?.toFixed(4)} MATIC</div>
                                                              </div>
                                                            </div>
                                                            */}

                                                            <div
                                                                className={ "item-description" }
                                                                style = {{
                                                                    flexGrow: '1'
                                                                    , flexShrink: '0'
                                                                    //, paddingBottom: "16px"
                                                                    //, flexBasis: '100%'
                                                                    //, maxHeight: '200px'
                                                                    //, overflow: 'hidden'
                                                                    //, textOverflow: 'ellipsis'
                                                                }}
                                                                >
                                                                {/*
                                                                Hagsworth awaits! Premium industrial spaces, strategic location. Drive growth today!
                                                                */}
                                                                { x?.asset?.intro }
                                                            </div>

                                                            <div
                                                                className = { cn('item-button') }
                                                                style = {{
                                                                    margin: 'auto'
                                                                    , textAlign: 'center'
                                                                    , paddingTop: '16px'
                                                                }}
                                                                >
                                                                <button>View Investment</button>
                                                            </div>

                                                        </div>

                                                        {/*
                                                        <div style={{ height: "6px", backgroundColor: "orange" }}></div>
                                                        */}

                                                    </div>
                                                </div>
                                            )
                                        })}

                                </div>

                            }
                        </>
                        : <div className={styles.nodata}>
                          No Assets Listed for Resale.
                        </div>
                    }
                </div>

            </div>
        </section>

    );
};

export default ResaleAssets;
