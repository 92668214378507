//import styles from "./About.module.sass";
//import cn from "classnames";
//import { axiosInstance } from "../../utils/API";
//import { PopUpAlert } from "../../controller/utils";
//import { useEffect, useState } from "react";
//import Popular from "../Home/Popular";
//import RecentSold from "../Home/RecentSold";
//            <RecentSold color="#f5f5f5" textColor="#000000"></RecentSold>
import BankWireInstructions from "./BankWireInstructions";
import { useHistory } from "react-router";

const BankWireInstructionsScreen = () => {

    //const [about, setAbout] = useState(null);
    const history = useHistory()
    // useEffect(() => {
    //   axiosInstance.get('/webcontent?key=about_us').then((res) => {
    //     setAbout(res?.data?.result[0]);
    //   }).catch((err) => {
    //     PopUpAlert("Alert", err?.data?.response?.error ? err?.data?.response?.error : err.message, "error")
    //   })
    // }, []);
    const redirectNft = () => {
        history.push('/search/all')
    }

    return (
        <>
        <main className = 'item-main edit-profile about'>
            <section
                //className={styles.aboutsection}
                className = 'form property-information'
                //className = 'form'
                style = {{
                    paddingTop: "0px"
                    , paddingBottom: "80px"
                }}
                >
                <div className = { 'max-width' }>
                    <div
                        //className = 'flex-container'
                        className = { 'property-information' }
                        //style = {{ textAlign: "left" }}
                        >
                        
                        <BankWireInstructions />
                        
                    </div>
                </div>
            </section>

        </main>
        </>
    );
};

export default BankWireInstructionsScreen;
