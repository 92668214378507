import {
    Link
    , useHistory
    , useLocation
} from 'react-router-dom';


const BankWireInstructions = () => {

    const location = useLocation();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    //const baseUrl = window.location.hostname;

    return (
        <>

        <div
            className="about-section"
            style = {{
                paddingTop: '50px'
                , marginTop: '0px'
            }}>

            <h2>
                RealSplit Bank Wire Instructions
            </h2>

            <h3 style = {{
                borderBottom: 'white 0px solid'
                , fontSize: '1.05em'
                //, color: '#fff'
                , width: '100%'
                , maxWidth: '400px'
                , margin: 'auto'
                , padding: '0px'
                }}>
                Please wire your investment funds to the account listed below
            </h3>

            <div
                style= {{
                    //display: 'flex', flex: '0 1 1'
                    paddingTop: '25px'
                }}
                >

                <ul>
                    <li><a href="/">/</a></li>
                    <li><a href="{ baseUrl }/">{ baseUrl }/</a></li>
                    <li><a href="{ baseUrl }/index.js">{ baseUrl }/index.js</a></li>
                    <li><a href="/about">/about</a></li>
                    <li><a href="/bank-wire-instructions">/bank-wire-instructions</a></li>
                    <li><a href="http://localhost:3010/realsplit-pitchdeck.html">http://localhost:3010/realsplit-pitchdeck.html</a></li>
                    <li><a href="/realsplit-pitchdeck.html">/realsplit-pitchdeck.html</a></li>
                    <li><a href="realsplit-pitchdeck.html">realsplit-pitchdeck.html</a></li>
                    <li><a href="https://demo.realsplit.net/realsplit-pitchdeck.html">https://demo.realsplit.net/realsplit-pitchdeck.html</a></li>
                    <li><a href="http://localhost:3010/bank-wire-instructions">http://localhost:3010/bank-wire-instructions</a></li>
                    <li><a href=""></a></li>
                    <li><a href=""></a></li>
                    <li><a href=""></a></li>
                    <li><a href=""></a></li>
                    <li><a href=""></a></li>
                    <li><a href=""></a></li>
                </ul>

            </div>

        </div>

        </>
    );
};
  
export default BankWireInstructions;