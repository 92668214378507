import 
    React
    , {
          useState
        , useEffect
        , useLayoutEffect
        , useRef
        , forwardRef
        , useImperativeHandle
    } from 'react';

//import { CircularProgress } from '@mui/material';

import {
      useHistory
    , useParams
    , useLocation
} from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import styles from './Search.module.sass';
import paginateStyles from './../../styles/Paginate.module.sass';
//import MapMarker from '../../components/MapMarker';
import MapMarker from './../../components/MapMarker/index.js';
//import Icon from '../../components/Icon';
//import Dropdown from '../../components/Dropdown';
//import InfoSvg from './InfoSvg';
//import FilterOption from './FilterOption';
import { displayPrice } from '../../utils/displayPrice';
import { axiosInstance } from '../../utils/API';
//import { details, increment, setEuroValue } from '../../redux/counterSlice';
import { setEuroValue } from '../../redux/counterSlice';

import {
      PAGINATE
    , CRYPTOCURRENCY_CONSTANTS
    , NAMES_CONSTANTS
} from '../../constants';

import {
      base_url
    //, PopUpAlert
} from "../../controller/utils";

//import Categories from '../../components/Header/Categories';
//import SelectCategories from './SelectCategories';
import CategoriesSelect from './CategoriesSelect';
//import GoogleMapReact from 'google-map-react';
//import GoogleMap from 'google-map-react';
import GoogleMap from 'google-maps-react-markers'
//import Marker from 'google-maps-react-markers'
import Autocomplete from 'react-google-autocomplete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//const MATIC_USD_EXCHANGE_RATE = 0.633716;

const dateOptions = ['Newest', 'Oldest'];
const sortOptions = [
  'Latest',
  'Oldest',
  'Price - lowest',
  'Price - highest',
  'Most Popular',
  'Recently Sold',
  'Recently Created',
];
//const colorOptions = ['Active', 'Inactive'];
const soldOptions = ['Sold', 'Unsold'];
//const types = ['Fixed Price', 'Auction'];
//const PopularityTypes = ['High to low', 'Low to high'];
//const categories = ['Art', 'Music'];
//const fractionAsset = ['Yes', 'No'];
const quantityOptions = ['All items', 'Single Item'];
const statusOptions = ['On Aution', 'New', 'Has Offers'];

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const REACT_APP_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
console.warn('screens/Search.js: REACT_APP_GOOGLE_MAPS_API_KEY', REACT_APP_GOOGLE_MAPS_API_KEY);

const Search = () => {
    console.warn('Search()');

    
    //const { categoryToken } = useParams();
    //console.log('categoryToken = ', categoryToken);
    //if (categoryToken) categoryIds.push(categoryToken);

    const [allAssetsAddedToMap, setAllAssetsAddedToMap] = useState(false);
    const [isSelectedCategorySelected, setIsSelectedCategorySelected] = useState(false);

    const rowsPerPage = 10;
    const metresInKilometre = 1000; // 1000 Metres in a Kilometres
    const unitedStatesWidthInKilometres = 2420; // 2420 Kilometres Width

    const [googleMapsApiHasLoaded, setGoogleMapsApiHasLoaded] = useState(false);
    // Range input settings
    //const inputRangeMin = 5;
    const [inputRangeMin, setInputRangeMin] = useState(5);
    //const inputRangeMax = 110;
    //const inputRangeMax = 100;
    const [inputRangeMax, setInputRangeMax] = useState(100);
    const inputRangeStep = 5;
    const [inputRangeValue, setInputRangeValue] = useState(inputRangeMax);
    /*
    const inputRangeMin = 50;
    const inputRangeMax = 2500;
    const inputRangeStep = 50;
    */
    const inputRangeReference = useRef(null); // A reference to the range so that it can be read and updated.

    //let widthOfUnitedStatesInKilometres = 4662; // The conterminous United States extends 4,662 km
    let widthOfUnitedStatesInKilometres = 4827; // Adjusted for RealSplit so that fractions in miles are wholer numbers e.g. 75, 150, 225, etc...
    let widthOfUnitedStatesInMetres = widthOfUnitedStatesInKilometres * metresInKilometre;
    let halfWidthOfUnitedStatesInMetres = parseInt(widthOfUnitedStatesInMetres / 2);
    //console.log('widthOfUnitedStatesInKilometres = ', widthOfUnitedStatesInKilometres);
    //console.log('widthOfUnitedStatesInMetres = ', widthOfUnitedStatesInMetres);
    //console.log('halfWidthOfUnitedStatesInMetres = ', halfWidthOfUnitedStatesInMetres);
    let oneFractionOfUnitedStatesHalfWidthInMetres = halfWidthOfUnitedStatesInMetres / inputRangeMax;
    //let oneFractionOfUnitedStatesHalfWidthInKilometres = oneFractionOfUnitedStatesHalfWidthInMetres / metresInKilometre;
    //console.log('oneFractionOfUnitedStatesHalfWidthInMetres = ', oneFractionOfUnitedStatesHalfWidthInMetres);
    //console.log('oneFractionOfUnitedStatesHalfWidthInKilometres = ', oneFractionOfUnitedStatesHalfWidthInKilometres);

    const [oneFractionOfMapHalfWidthInMetres, setOneFractionOfMapHalfWidthInMetres] = useState(oneFractionOfUnitedStatesHalfWidthInMetres);

    /*
     * Categories functionality
     */
    const { category, subCategories } = useParams();
    console.warn('Search: category = ', category);

    const [categoryNameFilter, setCategoryNameFilter] = useState(null);
    //const [categoryNameFilter, setCategoryNameFilter] = useState(category);
    const [categoryIds, setCategoryIds] = useState([]); // The selected category IDs

    let selectedCategoryIds = [];
    let categoryName; // a reusable variable for JSX insertion in multiple places
    //const [categoryArray, setCategoryArray] = useState(null);
    const [categoryNameArray, setCategoryNameArray] = useState([]);
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);
    const [categoryObjectArray, setCategoryObjectArray] = useState(null);
    const categoryObjectArrayReference = useRef(null);
    //const allAssetsArrayReference = useRef(null);
    const allAssetsArrayReference = useRef([]);

    /* Experimenting with using a forwardRef to access the checkbox items in the CategoriesSelect sub component.
    //const selectCategoriesReference = useRef<CategoriesSelect>(null);
    const selectCategoriesReference = useRef(null);

    // Use the ref
    const callCategoriesSelect = () => {
        console.log("Search: callCategoriesSelect()");
        alert("Search: callCategoriesSelect()");

        console.log("Search: callCategoriesSelect()")

        //selectCategoriesReference.current?.callCategoriesSelect();
        selectCategoriesReference.current?.getAlert();
    };
    */

    //if (category) setCategoryNameFilter(category);

    const history = useHistory();
    const dispatch = useDispatch();

    /* Temporarily removed. Currently not using these.
    const exchangeRate = useSelector(state  => state.counter.euroValue);
    const searchTextStore = useSelector(state  => state.asset.searchText);
    console.log('exchangeRate = ', exchangeRate);
    console.log('searchTextStore = ', searchTextStore);]
    */

    const [date, setDate] = useState(dateOptions[0]);
    const [price, setPrice] = useState('');
    const [saleType, setSaleType] = useState('');
    const [popularityFilter, setPopularityFilter] = useState(null);

    // Google Maps References
    const mapReference           = useRef(null);
    const mapsApiReference       = useRef(null);
    const placesServiceReference = useRef(null);
    const geocoderReference      = useRef(null);
    const centerRadiusCircleReference     = useRef(null);

    /*
    const [mapState, setMapState] = useState(null);
    const [mapsApiState, setMapsApiState] = useState(null);
    const [placesServiceState, setPlacesServiceState] = useState(null);
    const [geocoderState, setGeocoderState] = useState(null);
    */

    //let centerRadiusCircle;
    //const centerRadiusCircle = useRef(null);
    //let centerRadiusCircle = useRef(null);
    //const [centerRadiusCircle, setCenterRadiusCircle] = useState({});

    //const [sportFilter, setSportFilter] = useState(null);
    //const [leagueFilter, setLeagueFilter] = useState(null);
    //const [TeamFilter, setTeamFilter] = useState(null);
    //const [sportList, setSportList] = useState(null);
    //const [leagueList, setLeagueList] = useState(null);
    //const [teamList, setTeamList] = useState(null);
    //const [sportCataList, setSportCataList] = useState(null);
    //const [leagueCataList, setLeagueCataList] = useState(null);
    //const [teamCataList, setTeamCataList] = useState(null);
    //const [categoryArray, setCategoryArray] = useState(null);
    //const [categoryNameArray, setCategoryNameArray] = useState([]);
    //const [search, setSearch] = useState(searchTextStore || '');
    const [search, setSearch] = useState('');
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [allAssets, setAllAssets] = useState([]);
    const [page, setPage] = useState(1);
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const [count, setCount] = useState(0);
    //const [screenWidth] = useWindowSize();
    //const [visibleNav, setVisibleNav] = useState(false);
    const [loaderShow, setLoaderShow] = useState(false);
    const [visibleLoader, setVisibleLoader] = useState(false);
    const [fractionType, setFractionType] = useState('');
    const [sortBy, setSortBy] = useState(sortOptions[0]);
    const [sold, setSold] = useState('');
    const [statusParam, setStatusParam] = useState('');
    const [quantityParam, setQuantityParam] = useState('');

    /*
    const [filterDrawerState, setFilterDrawerState] = React.useState({
        left: false,
        right: false
    });
    */

    //const euroValue = useSelector(state => state.counter.euroValue);

    const [userLat, setUserLat] = useState();
    const [userLong, setUserLong] = useState();

    filteredAssets.length > 0 && console.warn('Search: filteredAssets.length = ', filteredAssets.length);
    filteredAssets.length > 0 && console.warn('Search: filteredAssets = ', filteredAssets);

    /*
    useEffect(()=> {
        console.log('navigator.geolocation = ', + navigator.geolocation);
        navigator.geolocation.getCurrentPosition(position =>{
            console.log('position = ', + position);
            setUserLat(position.coords.latitude);
            setUserLong(position.coords.longitude);
            console.log(userLat, userLong);
        })
    },[]);
    */

    /*
    useEffect(() => {
        console.log('Search: useEffect()');
        if (searchTextStore) {
            setSearch(searchTextStore);
        }
    }, [searchTextStore]);
    */

    useEffect(() => {
        console.warn('Search: useEffect(() => {...');
        console.warn('    setPage(1);');
        setPage(1);
        console.warn('    addCssClassNameToBodyTag();');
        addCssClassNameToBodyTag();
    //}, [saleType]);
    }, []);

    /*
    useEffect(() => {
        console.warn('Search: useEffect(() => {...');

        console.warn('    getCategoryNameArray();');
        getCategoryNameArray();

        console.warn('    fetchAllAssets();');
        fetchAllAssets();

        //getPriceFeed(); // Temporarily removed. We are using dollars.
    }, []);
    //*/

    /*
    useEffect(() => {
        console.warn('Search.useEffect(() => {...');
        
        //setCategoryObjectArray(getCategoryObjectArray());
        console.warn('    categoryObjectArray = ' + categoryObjectArray);
        //setCategoryObjectArray(categoryObjectArray);
    }, [categoryObjectArray]);
    */

    /*
     * Experimenting with dynamically adding a className to the body tag.
     */
    //const addCssClassNameToBodyTag = async (exchangeRate) => {
    //const addCssClassNameToBodyTag = async () => {
    const addCssClassNameToBodyTag = () => {
        console.warn('Search.addCssClassNameToBodyTag()');

        //document.getElementsByName('body')[0].setAttribute('class', 'search');
        //document.getElementsByTagName('body')[0].setAttribute('class', 'search');
        document.getElementsByTagName('body')[0].classList.add('search');
    }

    const location = useLocation()

    /*
    //React.useEffect(() => {
    React.useEffect(() => {
        console.warn('Search: React.useEffect()');
        // runs on location, i.e. route, change
        console.warn('Search.js: handle route change here...', location)

        //console.warn('category = ', category);
        //selectCategoryByName(category);
        if (category && category != 'all') selectCategoryByName(category);
        //filterAssetsByCircleRadiusAndCategoryName(category);
    }, [location]);
    */

    //import { useLocation } from 'react-router-dom';

    /*
    useEffect(() => {

        //getAllAssets(price);
        //const fetchData = async () => {
        //    const data = await fetch('https://yourapi.com');
        //}
        (async () => {
            const allAssets = await getAllAssets();
            console.warn('allAssets = ', allAssets);
            setFilteredAssets(allAssets);
            console.warn('allAssets.length = ' + allAssets.length);
            if (allAssets.length > 0) {
                addAssetsToMap(allAssets);
            }
        })();

    }, [
        saleType,
        date,
        min,
        max,
        search,
        popularityFilter,
        categoryNameFilter,
        page,
        category,
        categoryArray,
        subCategories,
        fractionType,
        sortBy,
        sold,
        statusParam,
        quantityParam,
    ]);
    */

    /* Temporarily removed
    const getPriceFeed = async () => {
        const url = process.env.REACT_APP_ADMIN_URL;
        await axiosInstance
            .get(url + '/admin/exchange/rate')
            //.then(res => dispatch(setEuroValue(res.data?.result?.exchangeRate)));
            .then(res => setExchangeRate(res.data?.result?.exchangeRate));
    };

    const setExchangeRate = async (exchangeRate) => {
        dispatch(setEuroValue(exchangeRate));
    }
    */

    const getAllAssets = async e => {
    //const getAllAssets = function(e) {
        console.warn('Search.getAllAssets()');

        //console.warn('categoryArray?.length = ', categoryArray?.length);
        //console.warn('categoryArray = ', categoryArray);
        console.warn('categoryNameFilter = ', categoryNameFilter);

        //if (categoryArray === null || visibleLoader || e) {
        if (categoryObjectArray === null || visibleLoader || e) {
            setLoaderShow(true);
        }

        let categoryId = 0;
        //if (categoryArray) {
        if (categoryObjectArray) {
            //categoryId = categoryArray?.filter(
            categoryId = categoryObjectArray?.filter(
                item => item.name === categoryNameFilter
            )[0]?.id;
            console.warn('categoryId = ', categoryId);
        }

        let responseObject; // to generate and hold the response object whether an error or success

        try {

            let params = {
                  limit: 20
                , saleType
                , page
                , min
                , max
                , search
                //, category: categoryId
                , sold
                , status: statusParam
                , quantity: quantityParam
            };

            if (categoryId) params.category = categoryId;
            if (saleType === 'Fixed Price') params = { ...params, saleType: 'fixed' };
            if (saleType === 'Auction')     params = { ...params, saleType: 'auction' };
            console.warn('params = ', params);

            console.warn('category = ', category);
            if (category) {

                let selectedCategory = categoryObjectArray?.find(
                    item => item.name === category
                );
                console.warn('selectedCategory = ', selectedCategory);

                if (selectedCategory) {

                    let selectedCategoryId = selectedCategory.id;
                    console.warn('selectedCategoryId = ', selectedCategoryId);
                    if (!selectedCategoryId && category.toLowerCase() !== 'all') {
                        let subCategory = category;
                        params = { ...params, subCategory };
                    } else {
                        params = { ...params, category: selectedCategoryId };
                    }
                    console.warn('params = ', params);

                    if (subCategories) {
                        let subCategoriesArray = selectedCategory.subCategories
                        let subCategory = subCategoriesArray.find(
                            item => item.name === subCategories
                        );
                        params = { ...params, subCategory: subCategory.id };
                        console.warn('params = ', params);
                    }
                }
            }

            console.warn('categoryNameFilter = ', categoryNameFilter);
            if (categoryNameFilter) {

                let selectedCategory = categoryObjectArray?.find(
                    item => item.name === categoryNameFilter
                );

                if (selectedCategory) {

                    let selectedCategoryId = selectedCategory.id;
                    console.warn('selectedCategoryId = ', selectedCategoryId);

                    if (!selectedCategoryId && category.toLowerCase() !== 'all') {
                        let subCategory = categoryNameFilter;
                        params = { ...params, subCategory };
                    } else {
                        params = { ...params, category: selectedCategoryId };
                    }
                    console.warn('params = ', params);

                    if (subCategories) {
                        let subCategoriesArray = selectedCategory.subCategories
                        let subCategory = subCategoriesArray.find(
                            item => item.name === subCategories
                        );
                        params = { ...params, subCategory: subCategory.id };
                        console.warn('params = ', params);
                    }
                }
            }

            //if (sportFilter) {
            //    let sport = sportCataList?.find(item => item.name === sportFilter)?.id;
            //    params = { ...params, subCategory: sport };
            //}

            /*
            if (subCategories) {

                let subCategoriesArray = mainCategory.subCategories

                let subCategory = subCategoriesArray.find(
                    item => item.name === subCategories
                );

                params = { ...params, subCategory: subCategory.id };

                //params = { ...params, league: subCategories };
                //
            }
            */

            //if (leagueFilter) {
            //    let league = leagueCataList?.find(
            //        item => item.name === leagueFilter
            //    )?.id;
            //    params = { ...params, league: league };
            //}

            //if (subSubCategories) {
            //    params = { ...params, team: subSubCategories };
            //}

            //if (TeamFilter) {
            //  let team = teamCataList?.find(item => item.name === TeamFilter)?.id;
            //  params = { ...params, team: team };
            //}

            if (sortBy === sortOptions[0]) {
                params = { ...params, latest: true };
            } else if (sortBy === sortOptions[1]) {
                params = { ...params, latest: false };
            } else if (sortBy === sortOptions[2]) {
                params = { ...params, price: 'low_to_high' };
            } else if (sortBy === sortOptions[3]) {
                params = { ...params, price: 'high_to_low' };
            } else if (sortBy === sortOptions[4]) {
                params = { ...params, popularity: true };
            } else if (sortBy === sortOptions[5]) {
                params = { ...params, recentlySold: true};
            } else if (sortBy === sortOptions[6]) {
              params = { ...params, recentlyCreated: true };
            }

            setPrice(e);

            if (categoryNameFilter === 'All') {
                delete params.subCategory;
                //delete params.league;
                //delete params.team;
            }

            if (fractionType !== '') {
                params.isFractional = fractionType === 'Yes' ? true : false;
            }

            if (sold === soldOptions[0]) {
                params = { ...params, sold: true };
            } else if (sold === soldOptions[1]) {
                params = { ...params, sold: false };
            }

            if (statusParam === statusOptions[0]) {
                params = { ...params, status: 'onAuction' };
            } else if (statusParam === statusOptions[1]) {
                params = { ...params, status: 'new' };
            } else if (statusParam === statusOptions[2]) {
                params = { ...params, status: 'hasOffers' };
            }

            if (quantityParam === quantityOptions[0]) {
                params = { ...params, quantity: 'all' };
            } else if (quantityParam === quantityOptions[1]) {
                params = { ...params, quantity: 'single' };
            }

            //console.warn('params', params);
            params = Object.fromEntries(Object.entries(params).filter(([_, v]) => !!v));
            console.warn('params = ', params);

            responseObject = await axiosInstance.get('/asset/all', {
            //responseObject = axiosInstance.get('/asset/all', {
                params
            });
            console.warn('responseObject = ', responseObject);
            console.warn('responseObject?.data = ', responseObject?.data);
            console.warn('responseObject?.data?.result = ', responseObject?.data?.result);
            console.warn('responseObject?.data?.result?.totalPages = ', responseObject?.data?.result?.totalPages);

            setCount(responseObject?.data?.result?.totalPages);
            //setFilteredAssets(responseObject?.data?.result?.assets?.rows);

            //await setFilteredAssets(responseObject?.data?.result?.assets?.rows);
            //addAssetsToMap(responseObject?.data?.result?.assets?.rows);
            //setTimeout(() => addAssetsToMap(responseObject?.data?.result?.assets?.rows), 1000);
            setLoaderShow(false);

            console.warn('responseObject?.data?.result?.assets = ', responseObject?.data?.result?.assets);
            console.warn('responseObject?.data?.result?.assets?.rows = ', responseObject?.data?.result?.assets?.rows);
            return responseObject?.data?.result?.assets?.rows;

        } catch (err) {
            console.error(err);
            setLoaderShow(false);
        }
        //return null;

    }; // getAllAssets

    const fetchAllAssets = async () => {
        console.warn('Search.fetchAllAssets()');

        let responseObject; // to generate and hold the response object whether an error or success

        try {

            let params = {
                  limit: 20
                , saleType
                , page
                , min
                , max
                , search
                //, category: categoryId
                , sold
                , status: statusParam
                , quantity: quantityParam
            };

            if (sortBy === sortOptions[0]) {
                params = { ...params, latest: true };
            } else if (sortBy === sortOptions[1]) {
                params = { ...params, latest: false };
            } else if (sortBy === sortOptions[2]) {
                params = { ...params, price: 'low_to_high' };
            } else if (sortBy === sortOptions[3]) {
                params = { ...params, price: 'high_to_low' };
            } else if (sortBy === sortOptions[4]) {
                params = { ...params, popularity: true };
            } else if (sortBy === sortOptions[5]) {
                params = { ...params, recentlySold: true};
            } else if (sortBy === sortOptions[6]) {
              params = { ...params, recentlyCreated: true };
            }

            //setPrice(e);

            if (categoryNameFilter === 'All') {
                delete params.subCategory;
                //delete params.league;
                //delete params.team;
            }

            if (fractionType !== '') {
                params.isFractional = fractionType === 'Yes' ? true : false;
            }

            if (sold === soldOptions[0]) {
                params = { ...params, sold: true };
            } else if (sold === soldOptions[1]) {
                params = { ...params, sold: false };
            }

            if (statusParam === statusOptions[0]) {
                params = { ...params, status: 'onAuction' };
            } else if (statusParam === statusOptions[1]) {
                params = { ...params, status: 'new' };
            } else if (statusParam === statusOptions[2]) {
                params = { ...params, status: 'hasOffers' };
            }

            if (quantityParam === quantityOptions[0]) {
                params = { ...params, quantity: 'all' };
            } else if (quantityParam === quantityOptions[1]) {
                params = { ...params, quantity: 'single' };
            }

            //console.warn('params', params);
            params = Object.fromEntries(Object.entries(params).filter(([_, v]) => !!v));
            console.warn('params = ', params);

            /*
            responseObject = await axiosInstance.get('/asset/all', {
            //responseObject = axiosInstance.get('/asset/all', {
                params
            });
            */
            const queryParams = new URLSearchParams(params);
            //fetch(`/api/users/${id}?${queryParams}`
            console.warn('queryParams = ', queryParams);

            let fetchConfig = {
                method: 'GET'
                , headers: {
                      //'Accept': 'application/json'
                      'Accept': '*/*'
                    , 'Content-Type': 'application/json'
                    , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
                }
                //, body: JSON.stringify(params)
            };

            let fetchPath = `${base_url}asset/all?${ queryParams }`;
            console.warn('fetchPath = ', fetchPath);

            let fetchPromise = fetch(fetchPath, fetchConfig);

            fetchPromise
                //.then(response => response.json())
                .then(function(response) {
                    console.warn('fetchPromise.then(function(response) {...', response);

                    if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                        return response.json();
                    }
                })
                .then(function(responseJson) {
                    console.warn('fetchPromise.then(function(response) {...}.then(function(responseJson) {', responseJson);

                    console.warn('responseJson = ', responseJson);
                    console.warn('responseJson.result = ', responseJson.result);
                    console.warn('responseJson.result?.totalPages = ', responseJson.result?.totalPages);

                    setCount(responseJson.result?.totalPages);

                    //let assetsArray = responseJson.result?.assets?.rows;
                    //console.warn('assetsArray = ', assetsArray);
                    //allAssetsArrayReference.current = assetsArray;
                    allAssetsArrayReference.current = responseJson.result?.assets?.rows;
                    //setAllAssets(assetsArray);
                    setAllAssets(allAssetsArrayReference.current);
                    //setFilteredAssets(assetsArray);
                    setFilteredAssets(allAssetsArrayReference.current);

                    console.warn('fetchPromise.then(function(response) {...}.then(function(responseJson): categoryObjectArray = ', categoryObjectArray);
                    console.warn('fetchPromise.then(function(response) {...}.then(function(responseJson): categoryObjectArrayReference = ', categoryObjectArrayReference);

                    /*
                    //addAssetsToMap(responseJson.result?.assets?.rows);
                    console.warn('allAssetsAddedToMap = ', allAssetsAddedToMap);
                    if (!allAssetsAddedToMap) {

                        //if (assetsArray && assetsArray.length > 0) {
                        if (allAssetsArrayReference?.current?.length > 0) {

                            //addAssetsToMap(assetsArray);
                            addAssetsToMap(allAssetsArrayReference?.current);
                            setAllAssetsAddedToMap(true);

                            if (category && category != 'all') {

                                if (!isSelectedCategorySelected) {
                                    //selectSelectedCategory(category);
                                    //selectSelectedCategory2(categoryArray);
                                    //selectSelectedCategory2(categoryObjectArray);
                                    //selectSelectedCategory3();
                                    setTimeout(selectSelectedCategory3, 1000);
                                }
                            }
                        }
                    }
                    */

                    if (category && category != 'all') {
                        console.warn('isSelectedCategorySelected = ', isSelectedCategorySelected);
                        if (!isSelectedCategorySelected) {
                            //selectSelectedCategory(category);
                            //if (categoryObjectArray) selectSelectedCategory2(categoryObjectArray);
                            //if (categoryObjectArray) selectSelectedCategory2(categoryObjectArray);
                            if (categoryObjectArrayReference.current) selectSelectedCategory2(categoryObjectArrayReference.current);

                        }
                    }

                });

            /*
            console.warn('responseObject = ', responseObject);
            console.warn('responseObject?.data = ', responseObject?.data);
            console.warn('responseObject?.data?.result = ', responseObject?.data?.result);
            console.warn('responseObject?.data?.result?.totalPages = ', responseObject?.data?.result?.totalPages);

            setCount(responseObject?.data?.result?.totalPages);
            //setFilteredAssets(responseObject?.data?.result?.assets?.rows);

            //await setFilteredAssets(responseObject?.data?.result?.assets?.rows);
            //addAssetsToMap(responseObject?.data?.result?.assets?.rows);
            //setTimeout(() => addAssetsToMap(responseObject?.data?.result?.assets?.rows), 1000);
            setLoaderShow(false);

            console.warn('responseObject?.data?.result?.assets = ', responseObject?.data?.result?.assets);
            console.warn('responseObject?.data?.result?.assets?.rows = ', responseObject?.data?.result?.assets?.rows);
            return responseObject?.data?.result?.assets?.rows;
            */

        } catch (err) {
            console.error(err);
            setLoaderShow(false);
        }
        //return null;

    }; // fetchAllAssets

    const handlePageClick = ({ selected: selectedPage }) => {
        setPage(selectedPage + 1);
        setVisibleLoader(true);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    };

    /*
    //onMarkerClick
    //onClick = { (event) => clickCategoryId(assetObject?.asset.categoryId, event) }
    const onMarkerClick = (e) => {
        console.warn('Search.onMarkerClick(e)', e);
        alert('Search.onMarkerClick('+e+')');
    };
    */

    /*
    const handleReset = () => {
        setSaleType('');
        setDate('');
        setMin('');
        setPrice('');
        setMax('');
        setPopularityFilter('');
        setCategoryNameFilter('');
        //setSportFilter('');
        //setLeagueFilter('');
        //setTeamFilter('');
        //setSportList(null);
        //setLeagueList(null);
        //setTeamList(null);
        setVisibleLoader(false);
        setFractionType('');
        setSortBy(null);
        setSold('');
        setStatusParam('');
        setQuantityParam('');
    };
    */

    /*
    const categoriesChange = data => {
        console.warn('categoriesChange(data)', data);

        //setSportFilter('');
        //setLeagueFilter('');
        //setTeamFilter('');
        const subCategoriesArr = categoryArray?.find(
            each => each.name === data
        );

        const dataArr = subCategoriesArr?.subCategories.map(each => each.name);

        //setSportCataList(subCategoriesArr?.subCategories);
        //setSportList(dataArr);
        //setLeagueList(null);
        //setTeamList(null);
        setCategoryNameFilter(data);
    };
    */

    /*
    const getCategoryNameArray = async () => {
        await axiosInstance.get('/asset/categories?page=1&limit=100')
            .then(result => {
                setCategoryArray(result?.data?.result?.categories?.rows);
                let categories = result?.data?.result?.categories?.rows?.map(
                    category => category.name
                );
                setCategoryNameArray(['All', ...categories]);
            })
            .catch(err => { });
    };
    //*/

    const clickCategoryId = (categoryId, event) => {
        console.warn('clickCategoryId('+categoryName+', '+event+')');

        history.push("/search/" + getCategoryName(categoryId));
        //console.warn('event = ', event);
        //console.warn('event.stopPropagation = '+event.stopPropagation);
        event.stopPropagation();
        return false;
    };

    const clickCategory = (categoryName, event) => {
        console.warn('clickCategory('+categoryName+', '+event+')');

        history.push("/search/" + categoryName);
        //console.warn('event = ', event);
        //console.warn('event.stopPropagation = '+event.stopPropagation);
        event.stopPropagation();
        return false;
    };

    const getCategoryObjectArray = async () => {
        console.warn('Search.getCategoryObjectArray()');

        let fetchConfig = {
            method: 'GET'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            //, body: JSON.stringify(params)
        };

        //let fetchPath = `${base_url}asset/all?${ queryParams }`;
        //let fetchPath = '/asset/categories?page=1&limit=100';
        let fetchPath = `${base_url}asset/categories?page=1&limit=100`;
        console.warn('fetchPath = ', fetchPath);

        let fetchPromise = fetch(fetchPath, fetchConfig);

        fetchPromise
            //.then(response => response.json())
            .then(function(response) {
                console.warn('fetchPromise.then(function(response) {...', response);

                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    return response.json();
                }
            })
            .then(function(responseJson) {
                //console.warn('fetchPromise.then(function(response) {...}.then(function(responseJson) {', responseJson);

                console.warn('responseJson = ', responseJson);
                //console.warn('responseJson.result = ', responseJson.result);
                console.warn('responseJson.result?.totalPages = ', responseJson.result?.totalPages);
                console.warn('responseJson.result?.categories?.rows = ', responseJson.result?.categories?.rows);

                setCount(responseJson.result?.totalPages);

                //categoryObjectArray = responseJson.result?.categories?.rows;
                setCategoryObjectArray(responseJson.result?.categories?.rows);
                categoryObjectArrayReference.current = responseJson.result?.categories?.rows
                //setCategoryObjectArray(categoryObjectArrayReference.current);
                //categoryObjectArray = responseJson.result?.categories?.rows;

                console.warn('Search.getCategoryObjectArray(): categoryObjectArrayReference.current = ', categoryObjectArrayReference.current);
                console.warn('Search.getCategoryObjectArray(): categoryObjectArray = ', categoryObjectArray);

                //let categoryObjectsArray = responseJson.result?.categories?.rows;
                //console.warn('Search.getCategoryNameArray(): categoryObjectsArray = ', categoryObjectsArray);
                //setCategoryArray(categoryObjectArray);
                //console.warn('Search.getCategoryNameArray(): categoryArray = ', categoryArray);
                //setCategoryObjectArray(categoryObjectsArray);

                /*
                setCategoryObjectArray(responseJson.result?.categories?.rows);
                console.warn('Search.getCategoryObjectArray(): categoryObjectArray = ', categoryObjectArray);

                let categoryNamesArray = categoryObjectArray?.map(
                    category => category.name
                );
                console.warn('Search.getCategoryObjectArray(): categoryNamesArray = ', categoryNamesArray);
                setCategoryNameArray(['All', ...categoryNamesArray]);
                console.warn('Search.getCategoryObjectArray(): categoryNameArray = ', categoryNameArray);
                */

                fetchAllAssets();

                //return categoryObjectArray;
            });
    }; // const getCategoryObjectArray = async () => {

    /*
    const getCategoryNameArray = async () => {
        console.warn('Search.getCategoryNameArray()');

        await axiosInstance
            .get('/asset/categories?page=1&limit=100')
            .then(result => {
                console.warn('Search.getCategoryNameArray(): result?.data = ', result?.data);
                //console.warn('Search.getCategoryNameArray(): result?.data?.result = ', result?.data?.result);
                //console.warn('Search.getCategoryNameArray(): result?.data?.result?.categories = ', result?.data?.result?.categories);
                console.warn('Search.getCategoryNameArray(): result?.data?.result?.categories?.rows = ', result?.data?.result?.categories?.rows);

                //setCategoryObjectArray(result?.data?.result?.categories?.rows);
                let categoryObjectsArray = result?.data?.result?.categories?.rows;
                //categoryObjectArray = result?.data?.result?.categories?.rows;
                console.warn('Search.getCategoryNameArray(): categoryObjectsArray = ', categoryObjectsArray);
                //setCategoryArray(categoryObjectArray);
                //console.warn('Search.getCategoryNameArray(): categoryArray = ', categoryArray);
                setCategoryObjectArray(categoryObjectsArray);
                console.warn('Search.getCategoryNameArray(): categoryObjectArray = ', categoryObjectArray);

                let categoryNamesArray = categoryObjectsArray?.map(
                    category => category.name
                );
                console.warn('Search.getCategoryNameArray(): categoryNamesArray = ', categoryNamesArray);
                setCategoryNameArray(['All', ...categoryNamesArray]);
                console.warn('Search.getCategoryNameArray(): categoryNameArray = ', categoryNameArray);

                //console.warn('Search.getCategoryNameArray(): isSelectedCategorySelected = ', isSelectedCategorySelected);
                //if (!isSelectedCategorySelected) {
                    //selectSelectedCategory(category);
                    //selectSelectedCategory2(categories);
                //    selectSelectedCategory2(categoryObjectArray);
                //}

                fetchAllAssets();
                //setTimeout(fetchAllAssets, 1000);
                //setTimeout(function () { fetchAllAssets() }, 1000);

                //console.warn('selectedCategoryName = ', selectedCategoryName);
                //if (selectedCategoryName) console.warn('selectedCategoryName = ', selectedCategoryName);
                //if (selectedCategoryName) filterAssetsAfterLoading(selectedCategoryName);
                //if (selectedCategoryName) {
                //    setTimeout(function () { filterAssetsAfterLoading(selectedCategoryName); }, 1000);
                //}

                //console.warn('Search.getCategoryNameArray(): category = ', category);
                //if (category) setTimeout(function () { filterAssetsAfterLoading(category); }, 1000);
            })
            .catch(err => {
                console.error(err)
            });
    };
    //*/

    const getCategoryName = (categoryId) => {
        //console.warn('Search.getCategoryName('+categoryId+')');

        //console.log('Search: categoryArray = ', categoryArray);
        //console.log('Search: categoryNameArray = ', categoryNameArray);

        /*
        let categoryId = categoryArray?.filter(
            item => item.name === categoryNameFilter
        )[0]?.id;
        */
        //return categoryArray?.filter(
        //return categoryObjectArray?.filter(
        //console.warn('Search.getCategoryName('+categoryId+'): categoryObjectArrayReference.current = ', categoryObjectArrayReference.current);
        return categoryObjectArrayReference?.current?.filter(
            item => item.id === categoryId
        )[0]?.name;

        /*
        //return categoryNameArray?.filter(
        let categoryObject = categoryNameArray.find(item => item.id === categoryId);
        console.log('categoryObject', categoryObject);
        return categoryObject;
        */
    };

    const onChangeCategory = (event) => {
        console.warn('Search.onChangeCategory(event)', event);

        console.warn('event.target', event.target);
        console.warn('event.target.name', event.target.name);
        console.warn('event.target.value', event.target.value);

        let selectedCategoryId = event.target.value;
        let selectedCategoryName = event.target.name;
        console.warn('selectedCategoryId = ', selectedCategoryId);
        console.warn('selectedCategoryName = ', selectedCategoryName);

        // Check the checkbox
        /*
        //let selectedCategory = getCategoryById();
        let selectedCategory = categoryArray?.filter(
            categoryObject => categoryObject.id === selectedCategoryId
        )[0];
        console.warn('selectedCategory = ', selectedCategory);
        selectedCategory.checked = true;
        */
        //checkCheckboxByCategoryId(selectedCategoryId);

        //selectCategory(selectedCategoryName, selectedCategoryId);
        //selectCategory(selectedCategoryId);

        console.warn('event.target.checked', event.target.checked);
        if (event.target.checked) {
            selectCategory(selectedCategoryId);
        } else {
            unselectCategory(selectedCategoryId);
        }

        return categoryIds;
    };

    const unselectCategory = (selectedCategoryId) => {
        console.warn('Search.unselectCategory(selectedCategoryId:"'+selectedCategoryId+'")');

        let selectedCategoryIdNumber = Number(selectedCategoryId);
        console.warn('selectedCategoryIdNumber = ', selectedCategoryIdNumber);

        console.warn('categoryIds = ', categoryIds);
        if (selectedCategoryIdNumber) {
            let indexOfCategoryId = categoryIds.indexOf(selectedCategoryIdNumber);
            console.warn('indexOfCategoryId = ', indexOfCategoryId);

            if (indexOfCategoryId != -1) {
                categoryIds.splice(indexOfCategoryId, 1);
            }

            console.warn('categoryIds = ', categoryIds);
            setCategoryIds(categoryIds);

            filterAssetsByCircleRadiusAndCategory();

            uncheckCheckboxByCategoryId(selectedCategoryId);

            //filterAssetsByCircleRadiusAndCategory(e.target.value);
            //filterAssetsByCircleRadiusAndCategory();
            /*
            setTimeout(function() {
                filterAssetsByCircleRadiusAndCategory();
                checkCheckboxByCategoryId(selectedCategoryId);
            }, 1000);
            */
        }
    };

    const selectCategory = (selectedCategoryId) => {
        console.warn('Search.selectCategory(selectedCategoryId:"'+selectedCategoryId+'")');

        //callCategoriesSelect();
        //checkCheckboxByCategoryId(selectedCategoryId);

        let selectedCategoryIdNumber = Number(selectedCategoryId);
        console.warn('selectedCategoryIdNumber = ', selectedCategoryIdNumber);

        console.warn('categoryIds = ', categoryIds);
        if (selectedCategoryIdNumber) {
            let indexOfCategoryId = categoryIds.indexOf(selectedCategoryIdNumber);
            console.warn('indexOfCategoryId = ', indexOfCategoryId);
            //if (categoryIds.includes(categoryIdNumber))

            /*
            if (indexOfCategoryId === -1) {
                //categoryIds.splice(indexOfCategoryId, 1);
                //categoryIds.push(selectedCategoryIdNumber);
                if (selectedCategoryIdNumber) categoryIds.push(selectedCategoryIdNumber);
            } else {
                //categoryIds.push(selectedCategoryIdNumber);
                categoryIds.splice(indexOfCategoryId, 1);
            }
            */
            if (indexOfCategoryId !== -1) {
                categoryIds.splice(indexOfCategoryId, 1);
            } else {
                if (selectedCategoryIdNumber) categoryIds.push(selectedCategoryIdNumber);
            }

            console.warn('categoryIds = ', categoryIds);
            setCategoryIds(categoryIds);

            //filterAssetsByCircleRadiusAndCategory(e.target.value);
            filterAssetsByCircleRadiusAndCategory();
            /*
            setTimeout(function() {
                filterAssetsByCircleRadiusAndCategory();
                checkCheckboxByCategoryId(selectedCategoryId);
            }, 1000);
            */
            checkCheckboxByCategoryId(selectedCategoryId);
        }
    };

    // Check the checkbox
    const checkCheckboxByCategoryId = (selectedCategoryId) => {
        console.warn('Search.checkCheckboxByCategoryId('+selectedCategoryId+')');

        //console.warn('categoryArray = ', categoryArray);
        console.warn('categoryObjectArray = ', categoryObjectArray);
        //console.warn('categoryObjectArrayReference.current = ', categoyObjectArrayReference.current);

        //if (categoryArray) {
        if (categoryObjectArray) {
        //if (categoryObjectArrayReference.current) {

            let selectedCategory = categoryObjectArray?.filter(
                categoryObject => categoryObject.id == selectedCategoryId
            )[0];
            console.warn('selectedCategory = ', selectedCategory);

            if (selectedCategory) {
                selectedCategory.checked = true;
                //selectedCategory.checked = 'true';
                //selectedCategory.setAttribute('UPDATE_COUNT_' + updateCount++, updateCount);
                //selectedCategory['UPDATE_COUNT_' + updateCount++] = updateCount;
                console.warn('selectedCategory = ', selectedCategory);
            }

        } else if (categoryObjectArrayReference.current) {

            // There is a race condition when the page first loads with a category selected.
            // The categoryObjectArray state is set but it is asynchronous so not available when this method is first called.
            // To fix this, we store a second reference to the categoryObjectArray in categoryObjectArrayReference?.current which is available synchronously
            // and update that.

            let selectedCategory = categoryObjectArrayReference?.current?.filter(
                categoryObject => categoryObject.id == selectedCategoryId
            )[0];
            console.warn('selectedCategory = ', selectedCategory);

            if (selectedCategory) {
                selectedCategory.checked = true;
                console.warn('selectedCategory = ', selectedCategory);
            }
            setCategoryObjectArray(categoryObjectArrayReference?.current);

        }

        //setCategoryObjectArray(categoryObjectArrayReference.current);
        //this.forceUpdate();
    };

    let updateCount = 0;

    const uncheckCheckboxByCategoryId = (selectedCategoryId) => {
        console.warn('Search.uncheckCheckboxByCategoryId('+selectedCategoryId+')');

        //console.warn('categoryArray = ', categoryArray);
        console.warn('categoryObjectArray = ', categoryObjectArray);
        //console.warn('categoryObjectArrayReference.current = ', categoryObjectArrayReference.current);

        //if (categoryArray) {
        if (categoryObjectArray) {
        //if (categoryObjectArrayReference.current) {

            //let selectedCategory = categoryArray?.filter(
            let selectedCategory = categoryObjectArray?.filter(
            //let selectedCategory = categoryObjectArrayReference?.current?.filter(
                //categoryObject => categoryObject.id === selectedCategoryId
                categoryObject => categoryObject.id == selectedCategoryId
            )[0];
            console.warn('selectedCategory = ', selectedCategory);

            if (selectedCategory) {
                selectedCategory.checked = false;
                //selectedCategory.checked = 'true';
                //selectedCategory.setAttribute('UPDATE_COUNT_' + updateCount++, updateCount);
                //selectedCategory['UPDATE_COUNT_' + updateCount++] = updateCount;
                console.warn('selectedCategory = ', selectedCategory);
            }
        }

        setCategoryObjectArray(categoryObjectArray);
        //setCategoryObjectArray(categoryObjectArrayReference.current);
        //this.forceUpdate();
    };

    /**
     * There can be a race condition when calling this method before the category list has loaded.
     */
    const selectCategoryByName = (selectedCategoryName) => {
        console.warn('Search.selectCategoryByName("'+selectedCategoryName+'")');

        //* In the previous implementation, sometimes the category list doesn't exist yet.
        //*/
        let selectedCategoryId = getCategoryId(selectedCategoryName);
        console.warn('selectedCategoryId = ', selectedCategoryId);

        if (selectedCategoryId) {
            //selectCategory(selectedCategoryName, selectedCategoryId);
            selectCategory(selectedCategoryId);
            return selectedCategoryId;
        //} else {
        //    console.warn('selectedCategoryId = ', selectedCategoryId);
        //    selectCategoryAfterLoading(selectedCategoryName);
        }
    };

    const selectSelectedCategory = (selectedCategoryName) => {
        console.warn('Search.selectSelectedCategory("'+selectedCategoryName+'")');

        //let selectedCategoryId = getCategoryId(selectedCategoryName);
        let selectedCategoryId = getCategoryId(category);
        console.warn('selectedCategoryId = ', selectedCategoryId);

        if (selectedCategoryId) {

            //selectCategory(selectedCategoryName, selectedCategoryId);
            selectCategory(selectedCategoryId);

            isSelectedCategorySelected = true;

            return selectedCategoryId;

        //} else {
        //    console.warn('selectedCategoryId = ', selectedCategoryId);
        //    selectCategoryAfterLoading(selectedCategoryName);
        }
    };

    const selectSelectedCategory3 = () => {
        console.warn('Search.selectSelectedCategory3()');

        console.warn('categoryObjectArray = ', categoryObjectArray);
        console.warn('categoryObjectArrayReference.current = ', categoryObjectArrayReference.current);

        //let selectedCategoryId = getCategoryId(selectedCategoryName);
        //let selectedCategoryId = getCategoryId(category);
        //let selectedCategoryId = getCategoryIdFromArray(category, categoryObjectArray);
        let selectedCategoryId = getCategoryIdFromArray(category, categoryObjectArrayReference.current);
        console.warn('selectedCategoryId = ', selectedCategoryId);

        if (selectedCategoryId) {

            //selectCategory(selectedCategoryName, selectedCategoryId);
            selectCategory(selectedCategoryId);

            //isSelectedCategorySelected = true;
            setIsSelectedCategorySelected(true);

            return selectedCategoryId;

        //} else {
        //    console.warn('selectedCategoryId = ', selectedCategoryId);
        //    selectCategoryAfterLoading(selectedCategoryName);
        }
    };

    const selectSelectedCategory2 = (categoryObjectArrayParameter) => {
        console.warn('Search.selectSelectedCategory2(categoryObjectArrayParameter)', categoryObjectArrayParameter);

        //let selectedCategoryId = getCategoryId(selectedCategoryName);
        //let selectedCategoryId = getCategoryId(category);
        let selectedCategoryId = getCategoryIdFromArray(category, categoryObjectArrayParameter);
        console.warn('selectedCategoryId = ', selectedCategoryId);

        if (selectedCategoryId) {

            //selectCategory(selectedCategoryName, selectedCategoryId);
            selectCategory(selectedCategoryId);

            //isSelectedCategorySelected = true;
            setIsSelectedCategorySelected(true);

            return selectedCategoryId;

        //} else {
        //    console.warn('selectedCategoryId = ', selectedCategoryId);
        //    selectCategoryAfterLoading(selectedCategoryName);
        }
    };

    /*
    const selectCategoryAfterLoading = (selectedCategoryName) => {
        console.warn('Search.selectCategoryAfterLoading("'+selectedCategoryName+'")');

        setSelectedCategoryName(selectedCategoryName);
    };

    const filterAssetsAfterLoading = (selectedCategoryName) => {
        console.warn('Search.filterAssetsAfterLoading("'+selectedCategoryName+'")');

        console.warn('Search.filterAssetsAfterLoading(): allAssets.length = ' + allAssets.length);
        console.warn('Search.filterAssetsAfterLoading(): filteredAssets.length = ' + filteredAssets.length);

        //selectCategoryByName(selectedCategoryName);
        let selectedCategoryId = selectCategoryByName(selectedCategoryName);
        console.warn('Search.filterAssetsAfterLoading(): selectedCategoryId = ' + selectedCategoryId);

        //setTimeout(function() {
            //checkCheckboxByCategoryId(selectedCategoryId);
            if (selectedCategoryId) checkCheckboxByCategoryId(selectedCategoryId);
        //}, 1000);
    };
    */

    const getCategoryId = (categoryName) => {
        console.warn('Search.getCategoryId("'+categoryName+'")');

        //console.warn('Search: categoryArray = ', categoryArray);
        console.warn('Search: categoryObjectArray = ', categoryObjectArray);
        console.warn('Search: categoryObjectArrayReference.current = ', categoryObjectArrayReference.current);

        //if (categoryArray?.length) {
        //if (categoryObjectArray?.length) {
        if (categoryObjectArrayReference?.current?.length) {
            //console.warn('Search: categoryObjectArray?.length = ', categoryObjectArray?.length);
            console.warn('Search: categoryObjectArrayReference?.current?.length = ', categoryObjectArrayReference?.current?.length);
            //return categoryObjectArray?.filter(
            return categoryObjectArrayReference?.current?.filter(
                item => item.name === categoryName
            )[0]?.id;
        }

        /*
        //console.log('Search: categoryNameArray = ', categoryNameArray);
        //return categoryNameArray?.filter(
        let categoryObject = categoryNameArray.find(item => item.id === categoryId);
        console.log('categoryObject', categoryObject);
        return categoryObject;
        */
    };

    const getCategoryIdFromArray = (categoryName, categoryObjectArray) => {
        console.warn('Search.getCategoryIdFromArray("'+categoryName+'", categoryObjectArray)');

        console.warn('Search.getCategoryIdFromArray("'+categoryName+'", categoryObjectArray): categoryObjectArray = ', categoryObjectArray);

        if (categoryObjectArray?.length) {
            console.warn('Search: categoryObjectArray?.length = ', categoryObjectArray?.length);
            return categoryObjectArray?.filter(
                item => item.name === categoryName
            )[0]?.id;
        }

        /*
        //console.log('Search: categoryNameArray = ', categoryNameArray);
        //return categoryNameArray?.filter(
        let categoryObject = categoryNameArray.find(item => item.id === categoryId);
        console.log('categoryObject', categoryObject);
        return categoryObject;
        */
    };

    const selectCategoryById = (selectedCategoryId) => {
        console.warn('Search.selectCategoryById("'+selectedCategoryId+'")');
        alert('Search.selectCategoryById("'+selectedCategoryId+'")');

        let selectedCategoryName = getCategoryName(selectedCategoryId);
        console.warn('selectedCategoryName = ', selectedCategoryName);
        
        //selectCategory(selectedCategoryName, selectedCategoryId);
        selectCategoryByName(selectedCategoryName);
    };

    //const selectCategory = (selectedCategoryName, selectedCategoryId) => {
        //console.warn('Search.selectCategory(selectedCategoryName:"'+selectedCategoryName+'", selectedCategoryId:"'+selectedCategoryId+'")');

    //const selectCategoryId = (categoryId) => {
    //    console.warn('selectCategoryId(categoryId)', categoryId);
    //};

    const filterAssetsByCircleRadius = function(newCenterLocation) {
        console.warn('Search.filterAssetsByCircleRadius('+newCenterLocation+')');

        if (centerRadiusCircleReference.current) console.debug('centerRadiusCircleReference.current.getBounds()', centerRadiusCircleReference.current.getBounds());

        //console.warn('    allAssets = ', allAssets);
        console.warn('    allAssetsArrayReference.current = ', allAssetsArrayReference.current);
        console.warn('    filteredAssets = ', filteredAssets);
        //console.warn('allAssets.length', allAssets.length);

        let allAssetsArray = allAssetsArrayReference.current;
        console.warn('    allAssetsArray = ', allAssetsArray);

        if (allAssetsArray.length > 0) {

            /*
            allAssets.map((asset, index) => {
                console.warn('asset = ', asset);
                //console.warn(index, ' - asset = ', asset);

                let assetLocation = asset.asset.location;
                console.warn('assetLocation = ', assetLocation);
                let assetLatLng = asset.asset.latLng;
                console.warn('assetLatLng = ', assetLatLng);

                console.warn('centerRadiusCircleReference.current.getBounds() = ' + centerRadiusCircleReference.current.getBounds());

                if (assetLatLng) {
                    let centerCircleContainsPoint = centerRadiusCircleReference.current.getBounds().contains(assetLatLng);
                    console.warn('centerCircleContainsPoint = ' + centerCircleContainsPoint);
                }
            })
            */
            //const filtered = allAssets.filter(user => user.name.includes(value));
            //const filteredAssets = allAssets.filter(function(asset) {
            const filteredAssets = allAssetsArray.filter(function(asset) {

                //console.warn('asset = ', asset);
                //console.warn('asset.asset = ', asset.asset);
                let assetLatLng = asset.asset.latLng;
                //console.warn('assetLatLng = ', assetLatLng);

                if (assetLatLng) {
                    let centerCircleContainsPoint = centerRadiusCircleReference.current.getBounds().contains(assetLatLng);
                    //console.warn('centerCircleContainsPoint = ' + centerCircleContainsPoint);
                    return centerCircleContainsPoint;
                }

                /*
                let assetLocation = asset.asset.location;
                console.warn('assetLocation = ', assetLocation);

                if (assetLocation) {
                    console.warn(assetLocation, assetLatLng);
                    if (assetLatLng) {
                        let centerCircleContainsPoint = centerRadiusCircleReference.current.getBounds().contains(assetLatLng);
                        //console.warn('centerCircleContainsPoint = ' + centerCircleContainsPoint);
                        return centerCircleContainsPoint;
                    }
                }
                */
                
                return false;
            });

            //console.warn('filteredAssets = ', filteredAssets);
            //console.warn('filteredAssets.length = ', filteredAssets.length);
            setFilteredAssets(filteredAssets);
        }
    };

    //const filterAssetsByCircleRadiusAndCategory = function(centerLocation, categoryId) {
    //const filterAssetsByCircleRadiusAndCategory = function(categoryId) {
    //const filterAssetsByCircleRadiusAndCategory = function() {
    //const filterAssetsByCircleRadiusAndCategory = async () => {
    const filterAssetsByCircleRadiusAndCategory = () => {

        //console.warn('filterAssetsByCircleRadiusAndCategory(centerLocation)', centerLocation, categoryId);
        //console.warn('filterAssetsByCircleRadiusAndCategory(categoryId)', categoryId);
        console.warn('filterAssetsByCircleRadiusAndCategory()');

        //console.warn('centerRadiusCircleReference.current.getBounds()', centerRadiusCircleReference.current.getBounds());
        //console.warn('categoryIds = ', categoryIds);
        //console.warn('allAssets = ', allAssets);
        //console.warn('allAssets.length = ', allAssets.length);
        //console.warn('filteredAssets = ', filteredAssets);
        //console.warn('allAssets.length', allAssets.length);

        let allAssetsArray = allAssetsArrayReference.current;
        //console.warn('allAssetsArray = ', allAssetsArray);

        //if (allAssets.length > 0) {
        if (allAssetsArray.length > 0) {

            //const filteredAssets = allAssets.filter(function(asset) {
            const filteredAssets = allAssetsArray.filter(function(asset) {

                //console.warn('asset.asset.categoryId = ', asset.asset.categoryId);
                let assetLocation = asset.asset.location;
                //console.warn('assetLocation = ', assetLocation);
                let assetLatLng = asset.asset.latLng;
                //console.warn('assetLatLng = ', assetLatLng);

                //console.warn('categoryIds = ', categoryIds);

                //if (asset.asset.categoryId == categoryId) {
                if (
                       categoryIds.length === 0
                    || categoryIds.includes(asset.asset.categoryId)
                    ) {

                    /*
                    if (assetLocation) {
                        console.warn(assetLocation, assetLatLng);
                        if (assetLatLng) {
                            console.warn('centerRadiusCircleReference = ' + centerRadiusCircleReference);
                            if (centerRadiusCircleReference) {
                                let centerCircleContainsPoint = centerRadiusCircleReference.current.getBounds().contains(assetLatLng);
                                console.warn('centerCircleContainsPoint = ' + centerCircleContainsPoint);
                                return centerCircleContainsPoint;    
                            }
                        }
                    }
                    //*/
                    if (assetLatLng) {
                        //console.warn('centerRadiusCircleReference = ' + centerRadiusCircleReference);
                        if (centerRadiusCircleReference) {
                            let centerCircleContainsPoint = centerRadiusCircleReference.current.getBounds().contains(assetLatLng);
                            //console.warn('centerCircleContainsPoint = ' + centerCircleContainsPoint);
                            return centerCircleContainsPoint;    
                        }
                    }
                }
                return false;
            });
            console.warn('filteredAssets = ', filteredAssets);
            console.warn('filteredAssets = ', filteredAssets.length);
            setFilteredAssets(filteredAssets);
        }
    };

    const filterAssetsByCircleRadiusAndCategoryName = function(categoryName) {
        console.warn('filterAssetsByCircleRadiusAndCategoryName("'+categoryName+'")', categoryName);
        //console.warn('filterAssetsByCircleRadiusAndCategory()');

        /*
        let categoryId = getCategoryId(categoryName);
        console.warn('categoryId = ', categoryId);
        if (!categoryIds) categoryIds = [];
        categoryIds.push(categoryId);
        console.warn('categoryIds = ', categoryIds);
        console.warn('categoryIds.length', categoryIds.length);
        */
        //selectCategoryByName(categoryName);

        console.warn('filterAssetsByCircleRadiusAndCategoryName(): allAssets = ', allAssets);
        console.warn('filterAssetsByCircleRadiusAndCategoryName(): allAssets.length = ', allAssets.length);
        console.warn('filterAssetsByCircleRadiusAndCategoryName(): allAssetsArrayReference.current = ', allAssetsArrayReference.current);
        //console.log('filterAssetsByCircleRadiusAndCategoryName(): filteredAssets = ', filteredAssets);
        //console.log('filterAssetsByCircleRadiusAndCategoryName(): filteredAssets.length = ', filteredAssets.length);

        let allAssetsArray = allAssetsArrayReference.current;

        //if (allAssets.length > 0) {
        if (allAssetsArray.length > 0) {

            //const filteredAssets = allAssets.filter(function(asset) {
            const filteredAssets = allAssetsArray.filter(function(asset) {

                console.warn('asset.asset.name = ', asset.asset.name);
                console.warn('asset.asset.categoryId = ', asset.asset.categoryId);
                let assetLocation = asset.asset.location;
                let assetLatLng = asset.asset.latLng;
                //console.warn('assetLocation = ', assetLocation);

                //if (asset.asset.name == categoryName) {
                if (
                       categoryIds.length === 0
                    || categoryIds.includes(asset.asset.categoryId)
                ) {
                    //*
                    if (assetLocation) {
                        console.warn(assetLocation, assetLatLng);
                        if (assetLatLng) {
                            let centerCircleContainsPoint = centerRadiusCircleReference.current.getBounds().contains(assetLatLng);
                            console.warn('centerCircleContainsPoint = ' + centerCircleContainsPoint);
                            return centerCircleContainsPoint;
                        }
                    }
                    //*/
                    //return true;
                }
                return false;
            });
            console.warn('filterAssetsByCircleRadiusAndCategoryName("'+categoryName+'"): filteredAssets = ', filteredAssets);
            console.warn('filteredAssets = ', filteredAssets.length);
            setFilteredAssets(filteredAssets);
        }
    };

    /*
    const subCategoriesChange = data => {
        alert('subCategoriesChange(data)');
        //setLeagueFilter('');
        //setTeamFilter('');
        //const sportListArr = sportCataList?.find(each => each.name === data);
        //const dataArr = sportListArr?.league.map(each => each.name);
        //setLeagueCataList(sportListArr?.league);
        //setLeagueList(dataArr);
        //setTeamList(null);
        //setSportFilter(data);
    };
    */

    /*
    const LeagueCategoriesChange = data => {
      setTeamFilter('');
      const leagueListArr = leagueCataList?.find(each => each.name === data);
      const dataArr = leagueListArr?.teams.map(each => each.name);
      setTeamCataList(leagueListArr?.teams);
      setTeamList(dataArr);
      setLeagueFilter(data);
    };
    */

    /*
    const preventNonNumericalInput = e => {
      e = e || window.event;
      var charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
      var charStr = String.fromCharCode(charCode);
      const regex =
        !charStr.match(/^[0-9]+$/) && charStr == '.' && charCode !== 8;
      if (regex) e.preventDefault();
    };
    */

    /*
    const saleTypeChange = value => {
      setSaleType(value);
      if (value === 'Auction') {
        setFractionType('');
      }
    };
    */

    /*
    const onKeyPressSearch = (event) => {
        console.warn('onKeyPressSearch(event)');
        //console.warn('event.key = ' + event.key);
        if (event.key == "Enter") {
            handleSearch();
        }
    };
    */


    /*
     * Google Maps API Functionality
     */
    let markers = [];

    const initialZoom = 4;
    let currentZoom = initialZoom;

    const latLngUsaBak = {
          lat:  37.0902
        , lng: -95.7129
    };

    const latLngUsa = {
          lat:  39.1641
        , lng: -95.8886
    };

    //let oneFractionOfUnitedStatesWidthInMetres = unitedStatesWidthInMetres / rangeMax;
    //let oneFractionOfUnitedStatesWidthInKilometres = oneFractionOfUnitedStatesWidthInMetres / metresInKilometre;
    //console.warn('oneFractionOfUnitedStatesWidthInMetres = ', oneFractionOfUnitedStatesWidthInMetres);
    //console.warn('oneFractionOfUnitedStatesWidthInKilometres = ', oneFractionOfUnitedStatesWidthInKilometres);

    //let currentPopup;
    //let blueDot;
    let blueDot = {
        //fillColor: color['google-blue 100']
        fillColor: '#4285F4'
        , fillOpacity: 1
        //, path: google.maps.SymbolPath.CIRCLE
        //, path: mapsApiReference.current.SymbolPath.CIRCLE
        //, path: 'CIRCLE'
        , scale: 8
        , strokeWeight: 2
        , strokeColor: 'rgb(255,255,255)'
    };

    let coloredDots;

    //let categoryColors;
    let categoryColors = [
          null
        , null // Commercial  NULL    13  commercial  t   2023-08-02 10:13:22.597+00  2023-08-03 21:52:39.527+00
        , 'orange' // Multi-Family    NULL    6   multi-family    f   2023-08-08 09:03:44.937+00  2024-02-23 16:56:19.949+00
        , 'red' // Mixed-Use   NULL    1   mixed-use   f   2023-08-08 09:04:10.801+00  2024-01-19 16:34:31.396+00
        , 'purple' //  Hospitality NULL    0   hospitality f   2023-08-08 09:04:27.258+00  2023-08-08 09:04:27.258+00
        , 'blue' //  Retail
        , 'darkgreen' //  Office
        , "black" //   Industrial
    ];

    const apiHasLoaded = ((map, mapsApi) => {
        console.warn('apiHasLoaded(map:'+map+', mapsApi:'+mapsApi+')');

        console.warn('map = ', map, typeof map);
        console.warn('mapsApi = ', mapsApi);
            
        if (!mapReference.current) {

            mapReference.current = map;
            mapsApiReference.current = mapsApi;
            placesServiceReference.current = new mapsApi.places.PlacesService(map);
            geocoderReference.current = new mapsApi.Geocoder();

            console.warn('mapReference.current = ', mapReference.current);
            console.warn('mapsApiReference.current = ', mapsApiReference.current);
            console.warn('placesServiceReference.current = ', placesServiceReference.current);
            console.warn('geocoderReference.current = ', geocoderReference.current);

            /*
            setMapState(map);
            setMapsApiState(mapsApi);
            setPlacesServiceState(placesServiceReference.current);
            setGeocoderState(geocoderReference.current);

            console.warn('mapState = ' + mapState);
            console.warn('mapsApiState = ' + mapsApiState);
            console.warn('placesServiceState = ' + placesServiceState);
            console.warn('geocoderState = ' + geocoderState);
            */

            /*
            blueDot = {
                //fillColor: color['google-blue 100']
                fillColor: '#4285F4'
                , fillOpacity: 1
                //, path: google.maps.SymbolPath.CIRCLE
                , path: mapsApiReference.current.SymbolPath.CIRCLE
                , scale: 8
                , strokeWeight: 2
                , strokeColor: 'rgb(255,255,255)'
            };
            */
            //blueDot.path = mapsApiReference.current.SymbolPath.CIRCLE;

            //coloredDots = {
            coloredDots = [
                  {}
                , {}
                , {
                    fillColor: 'orange'
                    , fillOpacity: 1
                    //, path: google.maps.SymbolPath.CIRCLE
                    , path: mapsApiReference.current.SymbolPath.CIRCLE
                    , scale: 8
                    , strokeWeight: 2
                    , strokeColor: 'rgb(255,255,255)'
                }
                //'Mixed-Use': {
                , {
                    //fillColor: color['google-blue 100']
                      //fillColor: 'var(--color-Mixed-Use)'
                      fillColor: 'red'
                    , fillOpacity: 1
                    //, path: google.maps.SymbolPath.CIRCLE
                    , path: mapsApiReference.current.SymbolPath.CIRCLE
                    , scale: 8
                    , strokeWeight: 2
                    , strokeColor: 'rgb(255,255,255)'
                }
            ];

            //fitMapToUsa();

            //map.addListener('zoom_changed', function() { console.warn('zoom_changed') });
            map.addListener('zoom_changed', zoomChanged);
            //map.addListener('zoom_changed', () => { zoomChanged() });
            //map.addListener('center_changed', function() { console.warn('center_changed') });
            map.addListener('center_changed', handleCenterChanged);

            //google.maps.event.addListenerOnce(yourMap, 'bounds_changed', function(event) {
            mapsApi.event.addListenerOnce(
                map
                , 'bounds_changed'
                , function(event) {
                    console.warn('bounds_changed');
                    console.warn('bounds_changed(): this.getZoom() = ' + this.getZoom());

                    /*
                    var bounds = map.getBounds();
                    console.warn('bounds = ', bounds);

                    // from that, get the coordinates for the NE and SW corners
                    var NE = bounds.getNorthEast();
                    var SW = bounds.getSouthWest();
                    console.warn('NE = ', NE);
                    console.warn('SW = ', SW);

                    // from that, figure out the latitudes and the longitudes
                    var lat1 =  NE.lat();
                    var lat2 =  SW.lat();

                    var lng1 =  NE.lng();
                    var lng2 =  SW.lng();

                    console.warn('lat1 = ', lat1);
                    console.warn('lat2 = ', lat2);
                    console.warn('lng1 = ', lng1);
                    console.warn('lng2 = ', lng2);

                    // construct new LatLngs using the coordinates for the horizontal distance between lng1 and lng2
                    var horizontalLatLng1 = new mapsApi.LatLng(lat1,lng1);
                    var horizontalLatLng2 = new mapsApi.LatLng(lat1,lng2);

                    // construct new LatLngs using the coordinates for the vertical distance between lat1 and lat2
                    var verticalLatLng1 = new mapsApi.LatLng(lat1,lng1);
                    var verticalLatLng2 = new mapsApi.LatLng(lat2,lng1);

                    // work out the distance horizontally
                    console.warn('mapsApi?.geometry = ', mapsApi?.geometry);
                    console.warn('mapsApi?.geometry?.spherical = ', mapsApi?.geometry?.spherical);
                    var horizontalMetres = mapsApi.geometry.spherical.computeDistanceBetween(horizontalLatLng1, horizontalLatLng2);
                    console.warn('horizontalMetres = ', horizontalMetres);
                    */

                    // round to kilometres to 1dp
                    //var horizontalKilometres = convertMetresToKm(horizontalMetres);
                    //console.warn('horizontalKilometres = ', horizontalKilometres);
                }
            );

            //setInputRangeValue(110);
            setInputRangeValue(inputRangeMax);

            //radiusChanged(inputRangeReference.current);

            //showCurrentLocation(map, mapsApi);
            startMap(map, mapsApi);
            /*
            setTimeout(function() {
                startMap(map, mapsApi);
            }, 100);
            */
        }

        /*
        this.setState({
            mapsApi
            , placesService: new mapsApi.places.PlacesService(map)
            , directionService: new mapsApi.DirectionsService()
            , geoCoderService: new mapsApi.Geocoder(),
            , singaporeLatLng: new mapsApi.LatLng(1.3521, 103.8198)
            //autoCompleteService: new mapsApi.places.AutocompleteService(),
        });
        */
    });

    const startMap = (
          mapParameter
        , mapsApiParameter
    ) => {
        console.warn('Search: startMap(mapParameter:'+mapParameter+', mapsApiParameter:'+mapsApiParameter+')');

        //let minimumRadiusInMiles = 50;

        //addAssetsToMap();
        /*
        console.warn('filteredAssets.length = ' + filteredAssets.length);
        if (filteredAssets.length > 0) {
            addAssetsToMap();
        }
        */
        (async () => {

            /*
            const allAssetsFromServer = await getAllAssets();
            console.warn('allAssetsFromServer = ', allAssetsFromServer);
            //setFilteredAssets(allAssets);
            setAllAssets(allAssetsFromServer);
            setFilteredAssets(allAssetsFromServer);
            console.warn('allAssets.length           = ' + allAssets.length);
            console.warn('allAssetsFromServer.length = ' + allAssetsFromServer.length);
            if (allAssetsFromServer.length > 0) {
                addAssetsToMap(allAssetsFromServer);
            }
            */
            //addAllAssetsToMap();
            /*
            console.warn('Search: startMap(mapParameter, mapsApiParameter): allAssets = ', allAssets);
            console.warn('Search: startMap(mapParameter, mapsApiParameter): allAssetsAddedToMap = ', allAssetsAddedToMap);
            if (allAssets) {
                addAllAssetsToMap();
            }
            //*/
            //getCategoryNameArray();
            getCategoryObjectArray();
            //setTimeout(getCategoryObjectArray, 2000);

            //setTimeout(addAllAssetsToMap, 2000);

            //let unitedStatesWidthInMetres = unitedStatesWidthInKilometres * metresInKilometre;
            //console.warn('unitedStatesWidthInMetres = ', unitedStatesWidthInMetres);
            //let rangeMax = 110;
            //let oneFractionOfUnitedStatesWidthInMetres = unitedStatesWidthInMetres / rangeMax;
            //console.warn('oneFractionOfUnitedStatesWidthInMetres = ', oneFractionOfUnitedStatesWidthInMetres);

            // Now global variables:
            //const inputRangeMin = 5;
            //const inputRangeMax = 110;
            //const inputRangeStep = 5;

            /*
            //let circleRadius = inputRangeMax * halfWidthOfUnitedStatesInMetres; // Radius should be half width of the USA in metres
            //let circleRadius = inputRangeMax * halfWidthOfMapInMetres; // Radius should be half width of the USA in metres
            let circleRadius = halfWidthOfUnitedStatesInMetres; // Radius should be half width of the USA in metres
            console.warn('circleRadius = ' + circleRadius);

            //setInputRangeValue(110);
            //setInputRangeValue(inputRangeMax);

            // Create the center radius circle
            centerRadiusCircleReference.current = new mapsApiParameter.Circle({
            //setCenterRadiusCircle = new mapsApiParameter.Circle({
                  map:    mapParameter
                , center: mapParameter.getCenter()
                //, radius: 50 * 1000 // 50 Kilometres
                //, radius: 110 * 22000 // Entire USA
                , radius: circleRadius // Entire USA
                , fillColor: "#000"
                , fillOpacity: 0.15
                //  strokeColor: "#FF0000"
                //, strokeOpacity: 0.8
                //, strokeWeight: 2
                , strokeWeight: "none"
                //, center: this.state.center
                //, center: latLngUsa
                //, radius: this.state.inputRad
            });
            //console.warn('centerRadiusCircleReference = ', centerRadiusCircleReference);
            console.warn('centerRadiusCircleReference.current = ', centerRadiusCircleReference.current);
            */
            addOverlayCircleToMap(mapParameter, mapsApiParameter);

            // Filter the assets based on the radius of the center size
            //filterAssetsByCircleRadius(mapReference.current.getCenter());
            //filterAssetsByCircleRadiusExperimental(mapReference.current.getCenter(), allAssetsFromServer);

            let inputRange = inputRangeReference.current;

            radiusChanged(inputRange);

            //adjustOutputPosition(inputRangeReference.current);
            setTimeout(function() {
                adjustOutputPosition(inputRange);
                inputRange.focus(); // focus on the input range so the arrow keys can change the circle radius
                setGoogleMapsApiHasLoaded(true);
                //console.warn('Search: googleMapsApiHasLoaded = ', googleMapsApiHasLoaded);
            }, 1000);

        })();

        /*
        //let circle = new mapsApiParameter.Circle({
        centerRadiusCircleReference.current = new mapsApiParameter.Circle({
        //setCenterRadiusCircle = new mapsApiParameter.Circle({
            map: mapParameter
            , center: mapParameter.getCenter()
            , radius: 60 * 10000
            , fillColor: "#000"
            , fillOpacity: 0.15
            //  strokeColor: "#FF0000"
            //, strokeOpacity: 0.8
            //, strokeWeight: 2
            , strokeWeight: "none"
            //, center: this.state.center
            //, center: latLngUsa
            //, radius: this.state.inputRad
        });
        console.warn('centerRadiusCircleReference = ', centerRadiusCircleReference);
        console.warn('centerRadiusCircleReference.current = ', centerRadiusCircleReference.current);
        //centerRadiusCircleState = centerRadiusCircle;
        */
    };

    // Create the center radius circle
    const addOverlayCircleToMap = (mapParameter, mapsApiParameter) => {
        console.warn('addOverlayCircleToMap(mapParameter, mapsApiParameter)');

        let circleRadius = halfWidthOfUnitedStatesInMetres; // Radius should be half width of the USA in metres
        console.warn('circleRadius = ' + circleRadius);

        centerRadiusCircleReference.current = new mapsApiParameter.Circle({
              map:    mapParameter
            , center: mapParameter.getCenter()
            , radius: circleRadius // Entire USA
            , fillColor: "#000"
            , fillOpacity: 0.15
            , strokeWeight: "none"
        });
        console.warn('centerRadiusCircleReference.current = ', centerRadiusCircleReference.current);
    };

    const handleSearch = (event) => {
        console.warn('handleSearch(event)');

        //console.warn('mapState     = ' + mapState);
        //console.warn('mapsApiState     = ' + mapsApiState);
        //console.warn('placesServiceState     = ' + placesServiceState);
        //console.warn('geocoderState     = ' + geocoderState);
        console.warn('mapReference.current = ', mapReference.current);
        console.warn('mapsApiReference.current = ', mapsApiReference.current);
        console.warn('placesServiceReference.current = ', placesServiceReference.current);
        console.warn('geocoderReference.current = ', geocoderReference.current);

        let map = mapReference.current;

        //*
        // 1. Create places request 
        const placesRequest = {
            location: new mapsApiReference.current.LatLng(1.3521, 103.8198),
            type: ['restaurant', 'cafe'],
            query: 'ice cream',
            rankBy: mapsApiReference.current.places.RankBy.DISTANCE,
            // radius: 30000, 
        };
        console.warn('placesRequest = ' + placesRequest);
        //*/

        // 2. Search for ice cream shops. Returns max 20 results.
        placesServiceReference.current.textSearch(placesRequest, ((response) => {
            console.warn('response = ', response);
            // 3. Calculate traveling time for each location
            for (let i = 0; i < response.length; i ++) {
                console.warn('response[i] = ', response[i]);
                const iceCreamPlace = response[i];
                const { rating, name } = iceCreamPlace;
                console.warn('name = ', name);
                const address = iceCreamPlace.formatted_address; // e.g 80 mandai lake...
                console.warn('address = ', address);

                geocoderReference.current.geocode({ address: address }, ((response) => {
                    console.warn('response = ', response);
                    console.warn('response[0] = ', response[0]);
                    console.warn('response[0]?.geometry = ', response[0]?.geometry);
                    console.warn('response[0]?.geometry?.location = ', response[0]?.geometry?.location);

                    /*
                    const { lngLat } = response[0]?.geometry?.location;
                    console.warn('lngLat = ', lngLat);
                    //this.props.addMarker(lngLat.lat(), lngLat.lng(), this.props.markerName);
                    //this.props.addMarker(lngLat.lat(), lngLat.lng(), 'test');
                    let marker = new maps.Marker({
                        position: myLatLng,
                        map,
                        title: 'Hello World!'
                    });
                    */

                    let place = response[0];
                    console.warn("latitude: " + place.geometry.location.lat() + ", longitude: " + place.geometry.location.lng());

                    // Create a marker for each place.
                    /*
                    markers.push(new mapsApiReference.current.Marker({
                        map: mapReference.current,
                        //icon: icon,
                        title: place.name,
                        position: place.geometry.location
                    }));
                    */
                    let marker = new mapsApiReference.current.Marker({
                        map: mapReference.current,
                        //icon: icon,
                        title: place.name,
                        position: place.geometry.location
                    });
                    markers.push(marker);

                    let infoWindow = new mapsApiReference.current.InfoWindow({
                          content: '<div>info</div>'
                        , maxWidth: 300
                    });

                    marker.addListener("click", () => {
                        console.warn("marker.click()")
                        infoWindow.open({
                            anchor: marker
                            , map
                            //, map: mapReference.current
                        });
                    });
                }));
            }
         }));
    };

    const onPlaceSelected = (place) => {
        console.info('Search: onPlaceSelected(place)', place);

        const formattedAddress = place.formatted_address;
        console.warn('formattedAddress', formattedAddress);

        const placeLocation = {
              lat: place.geometry.location.lat()
            , lng: place.geometry.location.lng()
        };
        console.warn('placeLocation', placeLocation);

        mapReference.current.setCenter(placeLocation);

        filterAssetsByCircleRadius(mapReference.current.getCenter());

        /*
        const address = place.formatted_address,
        addressArray =  place.address_components,
        city = this.getCity( addressArray ),
        area = this.getArea( addressArray ),
        state = this.getState( addressArray ),
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();

        // Set these values in the state.
        this.setState({
           address: ( address ) ? address : '',
           area: ( area ) ? area : '',
           city: ( city ) ? city : '',
           state: ( state ) ? state : '',
           markerPosition: {
            lat: latValue,
            lng: lngValue
           },
           mapPosition: {
            lat: latValue,
            lng: lngValue
           },
        });
        */
    };

    /*
    const fitMapToUsa = () => {
        console.warn('fitMapToUsa()');
        let bounds = new mapsApiReference.current.LatLngBounds();
        bounds.extend(new mapsApiReference.current.LatLng(24.891752, -98.4375));
        bounds.extend(new mapsApiReference.current.LatLng(40.351289, -124.244385));
        bounds.extend(new mapsApiReference.current.LatLng(44.488196, -70.290656));
        bounds.extend(new mapsApiReference.current.LatLng(49.000282, -101.37085));
        console.warn('bounds = ', bounds);
        mapReference.current.setCenter(bounds.getCenter());
        mapReference.current.fitBounds(bounds);
    };
    */

    const addAllAssetsToMap = async () => {
        console.warn('Search.addAllAssetsToMap()');

        //try {

            /*
            const allAssetsFromServer = await getAllAssets();
            console.warn('allAssetsFromServer = ', allAssetsFromServer);
            console.warn('allAssetsFromServer.length = ' + allAssetsFromServer.length);

            if (allAssetsFromServer.length > 0) {

                setAllAssets(allAssetsFromServer);
                setFilteredAssets(allAssetsFromServer);
            */
        console.warn('allAssets = ', allAssets);
        console.warn('filteredAssets = ', filteredAssets);
        console.warn('allAssetsAddedToMap = ', allAssetsAddedToMap);
        console.warn('allAssetsArrayReference.current = ', allAssetsArrayReference.current);

        //let allAssetsArray = allAssetsArrayReference.current;

        //if (allAssets) {
        //if (allAssets && allAssets.length > 0) {
        //if (allAssetsArray && allAssetsArray.length > 0) {
        if (allAssetsArrayReference?.current?.length > 0) {

            /*
            if (!allAssetsAddedToMap) {

                //addAssetsToMap(allAssets);
                //addAssetsToMap(allAssetsArray);
                addAssetsToMap(allAssetsArrayReference?.current);
                setAllAssetsAddedToMap(true);

                if (category && category != 'all') {
                    console.warn('isSelectedCategorySelected = ', isSelectedCategorySelected);
                    if (!isSelectedCategorySelected) {
                        //selectSelectedCategory(category);
                        //if (categoryObjectArray) selectSelectedCategory2(categoryObjectArray);
                        if (categoryObjectArray) selectSelectedCategory2(categoryObjectArray);
                    }
                }
            }
            */

            /*
            //if (category && category != 'all') selectCategoryByName(category);
            if (category && category != 'all') selectSelectedCategory(category);
            //if (category && category != 'all') {
                //setTimeout(selectCategoryByName, 1000, category);
            //    setTimeout(selectSelectedCategory, 5000, category);
            //}
            */
        }
    };

    /*
    //const addAllAssetsToMap = () => {
    const addAllAssetsToMapBak = async () => {
        console.warn('Search.addAllAssetsToMap()');

        try {

            const allAssetsFromServer = await getAllAssets();
            console.warn('allAssetsFromServer = ', allAssetsFromServer);
            console.warn('allAssetsFromServer.length = ' + allAssetsFromServer.length);

            if (allAssetsFromServer.length > 0) {

                setAllAssets(allAssetsFromServer);
                setFilteredAssets(allAssetsFromServer);

                //console.warn('allAssets.length           = ' + allAssets.length);
                addAssetsToMap(allAssetsFromServer);
                //setTimeout(addAssetsToMap, 1000, allAssetsFromServer);
                //if (category && category != 'all') selectCategoryByName(category);
                if (category && category != 'all') {
                    //setTimeout(selectCategoryByName, 1000, category);
                    setTimeout(selectSelectedCategory, 5000, category);
                }

                //console.warn('selectedCategoryIds = ', selectedCategoryIds);
                //if (selectedCategoryIds) filterAssetsByCircleRadiusAndCategory();
                //console.warn('categoryIds = ', categoryIds);
                //if (categoryIds) filterAssetsByCircleRadiusAndCategory();
                //console.warn('selectedCategoryName = ', selectedCategoryName);
                //if (selectedCategoryName) console.warn('selectedCategoryName = ', selectedCategoryName);
                //if (selectedCategoryName) filterAssetsAfterLoading(selectedCategoryName);
                //if (selectedCategoryName) {
                    //setTimeout(function () { filterAssetsAfterLoading(selectedCategoryName); }, 1000);
                //}
                //console.warn('category = ', category);
                //setTimeout(function () { filterAssetsAfterLoading(category); }, 1000);
                //filterAssetsAfterLoading(category);
            }
        } catch(error) {
            console.warn(error);
        }
    };
    */

    //const addAssetsToMap = () => {
    //    addAssetsToMap = (assets);
    //}
    const addAssetsToMap = (assetsArray) => {
        console.warn('addAssetsToMap(assetsArray)', assetsArray);

        //console.warn('assetsArray = ' + assetsArray);
        //console.warn('assetsArray.length = ' + assetsArray.length);
        //console.warn('allAssets = ' + allAssets);
        //console.warn('allAssets.length = ' + allAssets.length);

        let assetCount = 0;

        if (assetsArray.length > 0) {

            let assetLatLng;
            let map = mapReference.current;
            console.log('map = ', map);
            //console.log('map instanceof google.maps.Map = ', map instanceof google.maps.Map);

            assetsArray.map((asset, index) => {

                //assetCount++;

                //console.warn('asset = ', asset);
                console.warn(index, 'asset = ', asset);
                //console.warn('assetCount = ', assetCount);

                //if (index > 0) return; // temporary for development

                let assetName = asset.asset.name;
                let assetLocation = asset.asset.location;
                let assetLatitude = asset.asset.lat;
                let assetLongitude = asset.asset.lng;
                let assetCategoryId = asset.asset.categoryId;

                console.warn('assetCategoryId = ', assetCategoryId);
                console.warn('assetName = ', assetName);
                console.warn('assetLocation = ', assetLocation);
                console.warn('assetLatitude = ', assetLatitude);
                console.warn('assetLongitude = ', assetLongitude);
                
                //console.warn('assetLocation = ', assetLocation);

                if (assetLatitude && assetLongitude) {

                    console.warn('addAssetsToMap(assetsArray): mapReference.current = ', mapReference.current);
                    console.warn('addAssetsToMap(assetsArray): mapsApiReference.current = ', mapsApiReference.current);

                    let dotObject = blueDot;
                    dotObject.path = mapsApiReference.current.SymbolPath.CIRCLE;
                    dotObject.fillColor = categoryColors[assetCategoryId];
                    console.warn('addAssetsToMap(assetsArray): dotObject = ', dotObject);

                    //assetLatLng = { lat: assetLatitude, lng: assetLongitude };
                    assetLatLng = new mapsApiReference.current.LatLng(assetLatitude, assetLongitude);
                    console.warn('addAssetsToMap(assetsArray): assetLatLng = ', assetLatLng);
                    console.warn('addAssetsToMap(assetsArray): typeof assetLatLng = ', typeof assetLatLng);
                    //asset.latLng = assetLatLng;
                    asset.asset.latLng = assetLatLng;

                    //let map = mapReference.current;
                    //console.warn('addAssetsToMap(assetsArray): map = ', map);

                    /*
                    let marker = new mapsApiReference.current.Marker({
                          title: assetName
                        , icon: dotObject
                        , position: assetLatLng
                        //, setMap: map
                        //, map: mapReference.current
                        //, map
                        //, icon: blueDot
                        //, icon: coloredDots[assetCategoryId]
                    });
                    console.warn('addAssetsToMap(assetsArray): marker = ', marker);
                    marker.setMap(map);
                    //console.warn('marker.setMap('+mapReference.current+')');
                    //marker.setMap(mapReference.current);
                    markers.push(marker);

                    /*
                    let infoWindow = new mapsApiReference.current.InfoWindow({
                          content: '<div>'+assetName+'</div>'
                        , maxWidth: 300
                    });

                    marker.addListener("click", () => {
                        console.warn("marker.click()")
                        infoWindow.open({
                              anchor: marker
                            , map:    mapReference.current
                        });
                    });
                    //*/

                    //console.warn('addAssetsToMap(assetsArray): mapsApiReference?.current?.AdvancedMarkerElement = ', mapsApiReference?.current?.AdvancedMarkerElement);
                    console.warn('addAssetsToMap(assetsArray): mapsApiReference?.current?.marker = ', mapsApiReference?.current?.marker);
                    console.warn('addAssetsToMap(assetsArray): mapsApiReference?.current?.marker?.AdvancedMarkerElement = ', mapsApiReference?.current?.marker?.AdvancedMarkerElement);

                    /*
                    // Default marker with title text (no PinElement).
                    const markerViewWithText = new mapsApiReference.current.marker.AdvancedMarkerElement({
                          map
                        , title: assetName
                        , position: assetLatLng
                        //, icon: dotObject
                    });
                    console.warn('addAssetsToMap(assetsArray): markerViewWithText = ', markerViewWithText);
                    //*/

                } else if (assetLocation) {

                    console.log(assetLocation, asset);

                    geocoderReference.current.geocode(

                        { address: assetLocation }

                        , ((response) => {
                            //console.warn('response = ', response);
                            //console.warn('response[0] = ', response[0]);
                            //console.warn('response[0]?.geometry = ', response[0]?.geometry);
                            //console.warn('response[0]?.geometry?.location = ', response[0]?.geometry?.location);

                            let place = response[0];
                            console.warn('place = ', place);
                            //console.warn("latitude: " + place.geometry.location.lat() + ", longitude: " + place.geometry.location.lng());
                            asset.asset.latLng = {
                                  lat: place.geometry.location.lat()
                                , lng: place.geometry.location.lng()
                            }
                            console.warn('asset.asset.latLng = ', asset.asset.latLng);

                            console.warn('assetCount = ', assetCount++);

                            //console.warn('mapReference.current = ', mapReference.current);
                            //console.warn('mapsApiReference.current = ', mapsApiReference.current);

                            // Create a marker for each place.
                            //markers.push(new mapsApiReference.current.Marker({
                            /*
                            new mapsApiReference.current.Marker({
                                  map: mapReference.current
                                , icon: blueDot
                                , title: place.name
                                , position: place.geometry.location
                            //}));
                            });
                            //*/

                            let dotObject = blueDot;
                            dotObject.fillColor = categoryColors[assetCategoryId];

                            let marker = new mapsApiReference.current.Marker({
                                  map: mapReference.current
                                //, icon: blueDot
                                //, icon: coloredDots[assetCategoryId]
                                , icon: dotObject
                                , title: place.name
                                , position: place.geometry.location
                            });
                            markers.push(marker);

                            let infoWindow = new mapsApiReference.current.InfoWindow({
                                  content: '<div>'+assetName+'</div>'
                                , maxWidth: 300
                            });

                            marker.addListener("click", () => {
                                console.warn("marker.click()")
                                infoWindow.open({
                                      anchor: marker
                                    , map:    mapReference.current
                                });
                            });

                        })
                    ); // geocoderReference.geocode()
                } // if (assetLocation)
            }); // assetsArray.map((asset, index)

            //if (category) filterAssetsAfterLoading(category);

        } // if (assetsArray.length > 0)
    }; // addAssetsToMap(assetsArray)

    /*
    const addAssetsToMapUsingLocation = (assetsArray) => {
        console.warn('addAssetsToMap(assetsArray)', assetsArray);

        console.warn('assetsArray = ' + assetsArray);
        console.warn('assetsArray.length = ' + assetsArray.length);
        console.warn('allAssets = ' + allAssets);
        console.warn('allAssets.length = ' + allAssets.length);

        let assetCount = 0;

        if (assetsArray.length > 0) {

            assetsArray.map((asset, index) => {

                //assetCount++;

                //console.warn('asset = ', asset);
                console.warn(index, ' - asset = ', asset);
                //console.warn('assetCount = ', assetCount);

                //if (index > 0) return; // temporary for development

                let assetName = asset.asset.name;
                let assetLocation = asset.asset.location;
                let assetCategoryId = asset.asset.categoryId;
                console.warn('assetCategoryId = ', assetCategoryId);
                
                //console.warn('assetLocation = ', assetLocation);

                if (assetLocation) {

                    console.log(assetLocation, asset);

                    let map = mapReference.current;
                    console.log('map = ', map);

                    geocoderReference.current.geocode(

                        { address: assetLocation }

                        , ((response) => {
                            //console.warn('response = ', response);
                            //console.warn('response[0] = ', response[0]);
                            //console.warn('response[0]?.geometry = ', response[0]?.geometry);
                            //console.warn('response[0]?.geometry?.location = ', response[0]?.geometry?.location);

                            let place = response[0];
                            console.log('place', place);
                            //console.warn("latitude: " + place.geometry.location.lat() + ", longitude: " + place.geometry.location.lng());
                            asset.asset.latLng = {
                                  lat: place.geometry.location.lat()
                                , lng: place.geometry.location.lng()
                            }
                            console.log('asset.asset.latLng = ', asset.asset.latLng);

                            console.warn('assetCount = ', assetCount++);


                            //console.warn('mapReference.current = ', mapReference.current);
                            //console.warn('mapsApiReference.current = ', mapsApiReference.current);

                            // Create a marker for each place.
                            //markers.push(new mapsApiReference.current.Marker({
                            //new mapsApiReference.current.Marker({
                            //      map: mapReference.current
                            //    , icon: blueDot
                            //    , title: place.name
                            //    , position: place.geometry.location
                            //}));
                            //});

                            let dotObject = blueDot;
                            dotObject.fillColor = categoryColors[assetCategoryId]

                            let marker = new mapsApiReference.current.Marker({
                                  map: mapReference.current
                                //, icon: blueDot
                                //, icon: coloredDots[assetCategoryId]
                                , icon: dotObject
                                , title: place.name
                                , position: place.geometry.location
                            });
                            markers.push(marker);

                            let infoWindow = new mapsApiReference.current.InfoWindow({
                                  content: '<div>'+assetName+'</div>'
                                , maxWidth: 300
                            });

                            marker.addListener("click", () => {
                                console.log("marker.click()")
                                infoWindow.open({
                                    anchor: marker
                                    , map
                                    //, mapReference.current
                                });
                            });

                            //let marker = new mapsApiReference.current.Marker({
                            //    icon: blueDot
                            //    , position: userLocation,
                            //    , title: 'You are here!'
                            //});
                            //marker.setMap(mapObject);
                        })
                    ); // geocoderReference.geocode()
                } // if (assetLocation)
            }); // assetsArray.map((asset, index)

            //if (category) filterAssetsAfterLoading(category);

        } // if (assetsArray.length > 0)
    }; // addAssetsToMap(assetsArray)
    */

    /*
    const blueDot = {
        //fillColor: color['google-blue 100']
        fillColor: '#4285F4'
        , fillOpacity: 1
        //, path: google.maps.SymbolPath.CIRCLE
        , path: mapsApiParameter.SymbolPath.CIRCLE
        , scale: 8
        , strokeWeight: 2
        , strokeColor: 'rgb(255,255,255)'
    };
    */

    //const showCurrentLocation = ((map, mapsApi) => {
    const showCurrentLocation = (mapParameter, mapsApiParameter) => {
        console.warn('showCurrentLocation(mapParameter:'+mapParameter+', mapsApiParameter:'+mapsApiParameter+')');

        console.warn('navigator.geolocation = ', navigator.geolocation);
        navigator.geolocation.getCurrentPosition(position => {
            console.warn('position = ', position);
            setUserLat(position.coords.latitude);
            setUserLong(position.coords.longitude);
            console.warn(userLat, userLong);

            const userLocation = {
                  lat: position.coords.latitude
                , lng: position.coords.longitude
            };
            console.warn('userLocation = ', userLocation);

            //mapReference.current.setCenter(userLocation);
            //mapParameter.setCenter(userLocation, 11);
            //mapParameter.setCenter(userLocation);
            //mapParameter.setZoom(10);

            /*
            const blueDot = {
                //fillColor: color['google-blue 100']
                fillColor: '#4285F4'
                , fillOpacity: 1
                //, path: google.maps.SymbolPath.CIRCLE
                , path: mapsApiParameter.SymbolPath.CIRCLE
                , scale: 8
                , strokeWeight: 2
                , strokeColor: 'rgb(255,255,255)'
            };
            */

            let marker = new mapsApiParameter.Marker({
                icon: blueDot,
                position: userLocation,
                title: 'You are here!'
            });
            marker.setMap(mapParameter);

            let infoWindow = new mapsApiParameter.InfoWindow({
                  content: '<div>info</div>'
                , maxWidth: 300
            });

            /*
            mapsApiParameter.event.addListener(
                  marker
                , "click"
                , function() {
                    if (currentPopup != null) {
                        currentPopup.close();
                        currentPopup = null;
                    }
                    popup.open(mapParameter, marker);
                    currentPopup = popup;
                }
            );
            */
            marker.addListener("click", () => {
                console.warn("marker.click()")
                infoWindow.open({
                    anchor: marker
                    , mapParameter
                });
            });

            mapParameter.setCenter(userLocation);
            mapParameter.setZoom(10);
        })
    };

    const handleDragEnd = function(dragEndEvent) {
        console.warn('handleDragEnd(dragEndEvent)', dragEndEvent);
        //let lat = dragEndEvent.center.lat;
        console.warn('dragEndEvent.center.lat', dragEndEvent.center.lat);
        console.warn('dragEndEvent.center.lng', dragEndEvent.center.lng);

        const newCenterLocation = {
              lat: dragEndEvent.center.lat()
            , lng: dragEndEvent.center.lng()
        };
        console.warn('newCenterLocation', newCenterLocation);
        mapReference.current.setCenter(newCenterLocation);
        //setFilteredAssets(filteredAssets); // Reset the assets to the full array
        resetCenterRadiusCircle(newCenterLocation);
        //filterAssetsByCircleRadius(newCenterLocation);
        filterAssetsByCircleRadiusAndCategory();
    };

    //const filtered = users.filter(user => user.name.includes(value));
    //setFilteredUsers(filtered);

    //const kilometresToMiles = (kilometres) => {
    //    return kilometres * 0.621371;
    //};
    const kilometresToMiles = (kilometres) => Math.round(kilometres * 0.621371);

    const radiusRangeChanged = (rangeInput) => {
        //console.warn('changeRadius('+rangeInput+')');
        console.warn('Search: radiusRangeChanged('+rangeInput+')', rangeInput);

        let rangeValue = rangeInput.value;
        console.warn('rangeValue = ', rangeValue);
        setInputRangeValue(rangeValue);

        /*
        let outputElement = rangeInput.nextSibling;
        console.warn('outputElement = ', outputElement);
        outputElement.textContent = kilometresToMiles(rangeValue * (oneFractionOfMapHalfWidthInMetres / metresInKilometre)) + ' miles';

        adjustOutputPosition(rangeInput);
        */
        updateOutput(rangeInput);

        updateCircleRadius(rangeValue);

        filterAssetsByCircleRadiusAndCategory();
    }

    /*
    const changeRadiusRange = (rangeInput) => {
        //console.log('changeRadius('+rangeInput+')');
        console.log('Search: changeRadiusRange('+rangeInput+')', rangeInput);

        let rangeValue = rangeInput.value;
        let rangeWidth = rangeInput.offsetWidth;
        let rangeMax = rangeInput.getAttribute('max');
        let rangeMin = rangeInput.getAttribute('min');

        console.log('rangeValue = ', rangeValue);
        console.log('rangeWidth = ', rangeWidth);
        console.log('rangeMax = ', rangeMax);
        console.log('rangeMax = ', rangeMax, typeof rangeMax);
        console.log('rangeMin = ', rangeMin);
        console.log('rangeMin = ', rangeMin, typeof rangeMin);

        //setInputRangeValue(rangeValue);

        //let newPoint = (rangeValue - rangeMin) / (rangeMax - rangeMin);
        //console.log('newPoint = ', newPoint);

        outputElement.textContent = kilometresToMiles(rangeValue * (oneFractionOfMapHalfWidthInMetres / metresInKilometre)) + ' miles';

        // Set the new radius of the SVG circle overlay.
        //if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * oneFractionOfUnitedStatesHalfWidthInMetres);
        if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * oneFractionOfMapHalfWidthInMetres);

        adjustOutputPosition(rangeInput);
    };
    */

    const radiusChanged = (rangeInput) => {
        console.warn('Search: radiusChanged('+rangeInput+')', rangeInput);

        let rangeValue = rangeInput.value;
        let rangeWidth = rangeInput.offsetWidth;
        let rangeMax = rangeInput.getAttribute('max');
        let rangeMin = rangeInput.getAttribute('min');

        console.warn('rangeValue = ', rangeValue);
        console.warn('rangeWidth = ', rangeWidth);
        console.warn('rangeMax = ', rangeMax);
        console.warn('rangeMax = ', rangeMax, typeof rangeMax);
        console.warn('rangeMin = ', rangeMin);
        console.warn('rangeMin = ', rangeMin, typeof rangeMin);

        let outputElement = rangeInput.nextSibling;
        console.log('outputElement = ', outputElement);

        outputElement.textContent = kilometresToMiles(rangeValue * (oneFractionOfMapHalfWidthInMetres / metresInKilometre)) + ' miles';

        // Set the new radius of the SVG circle overlay.
        //if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * oneFractionOfUnitedStatesHalfWidthInMetres);
        if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * oneFractionOfMapHalfWidthInMetres);

        //adjustOutputPosition(rangeInput);
    };

    const updateCircleRadius = (rangeValue) => {
        //console.log('changeRadius('+rangeInput+')');
        console.warn('Search.updateCircleRadius('+rangeValue+')', typeof rangeValue);

        //outputElement.textContent = kilometresToMiles(rangeValue * (oneFractionOfMapHalfWidthInMetres / metresInKilometre)) + ' miles';

        console.log('oneFractionOfMapHalfWidthInMetres = ', oneFractionOfMapHalfWidthInMetres, typeof oneFractionOfMapHalfWidthInMetres);

        // Set the new radius of the SVG circle overlay.
        if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * oneFractionOfMapHalfWidthInMetres);

        //adjustOutputPosition(rangeInput);
    };

    const updateOutput = (rangeInput) => {
        console.warn('Search: updateOutput('+rangeInput+')', rangeInput);

        let rangeValue = rangeInput.value;
        console.warn('rangeValue = ', rangeValue);

        let outputElement = rangeInput.nextSibling;
        console.warn('outputElement = ', outputElement);
        outputElement.textContent = kilometresToMiles(rangeValue * (oneFractionOfMapHalfWidthInMetres / metresInKilometre)) + ' miles';

        adjustOutputPosition(rangeInput);
    }

    const adjustOutputPosition = (rangeInput) => {
        //console.log('changeRadius('+rangeInput+')');
        console.warn('Search.adjustOutputPosition('+rangeInput+')', rangeInput);

        let rangeValue = rangeInput.value;
        let rangeWidth = rangeInput.offsetWidth;
        let rangeMax = rangeInput.getAttribute('max');
        let rangeMin = rangeInput.getAttribute('min');

        console.warn('    rangeValue = ', rangeValue);
        console.warn('    rangeWidth = ', rangeWidth);
        //console.log('rangeMax = ', rangeMax);
        console.warn('    rangeMax = ', rangeMax, typeof rangeMax);
        //console.log('rangeMin = ', rangeMin);
        console.warn('    rangeMin = ', rangeMin, typeof rangeMin);

        let rangeInputWidth;
        if (rangeInput) {
            //console.log('rangeInput = ', rangeInput);
            rangeInputWidth = rangeInput.offsetWidth;
            console.log('    rangeInputWidth = ', rangeInputWidth);
        }

        // Experminting with adjusting the position of the output element based on the width of the slider handle
        //let sliderHandleWidth = 16;
        //let sliderHandleHalfWidth = sliderHandleWidth / 2;

        let outputElement = rangeInput.nextSibling;
        console.log('    outputElement = ', outputElement);
        let outputElementWidth;
        if (outputElement) {

            outputElementWidth = outputElement.offsetWidth;
            console.log('    outputElementWidth = ', outputElementWidth);

            //let outputPaddingPixels = 4;
            //outputWidth += outputPaddingPixels; // adjust for the padding which is not included in the offsetWidth above
            //console.log('outputWidth = ', outputWidth);

            let outputElementHalfWidth = parseInt(outputElementWidth / 2);
            console.log('    outputElementHalfWidth = ', outputElementHalfWidth);

            //let outputMargin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
            //let outputPadding = parseFloat(outputElement.style.paddingLeft) + parseFloat(outputElement.style.paddingRight);
            //console.log('outputPadding = ', outputPadding);
            //let outputBorder = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);

            /*
            //outputElement.style.left = (rangeValue - outputHalfWidth) + 'px';
            outputElement.style.left = (
                (rangeValue - inputRangeStep + sliderHandleHalfWidth) - outputHalfWidth
            ) + 'px';
            */

            //let rangeValueWidth = (rangeMin/)
            let rangePercentageWidth = (rangeValue/rangeMax);
            console.log('    rangePercentageWidth = ', rangePercentageWidth);
            let rangeValueWidth = parseInt(rangePercentageWidth * rangeInputWidth);
            console.log('    rangeValueWidth = ', rangeValueWidth);

            //let outputElementMarginLeft = (rangeValueWidth + sliderHandleHalfWidth) - outputHalfWidth;
            //let outputElementMarginLeft = (rangeValueWidth + sliderHandleHalfWidth) - outputHalfWidth;
            let outputElementMarginLeft = rangeValueWidth;
            console.log('    outputElementMarginLeft = ', outputElementMarginLeft);
            outputElementMarginLeft -= outputElementHalfWidth;
            console.log('    outputElementMarginLeft = ', outputElementMarginLeft);

            outputElement.style.left = (
                //rangeValueWidth - outputHalfWidth
                //(rangeValueWidth + sliderHandleHalfWidth) - outputHalfWidth
                outputElementMarginLeft
            ) + 'px';
            
        }

        //outputElement.style.left = (rangeMax - rangeMin) + 'px';
        //outputElement.style.left = (rangeValue) + 'px';
        //outputElement.textContent = (rangeValue) + ' Miles';
        //outputElement.textContent = (rangeValue) + ' Kilometres';

        /*
        let widthOfUnitedStatesInKilometers = 4662; // The conterminous United States extends 4,662 km
        let widthOfUnitedStatesInMetres = widthOfUnitedStatesInKilometers * metresInKilometre;
        let halfWidthOfUnitedStatesInMetres = parseInt(widthOfUnitedStatesInMetres / 2);
        console.log('widthOfUnitedStatesInKilometers = ', widthOfUnitedStatesInKilometers);
        console.log('widthOfUnitedStatesInMeters = ', widthOfUnitedStatesInMeters);
        console.log('halfWidthOfUnitedStatesInMetres = ', halfWidthOfUnitedStatesInMetres);

        let oneFractionOfUnitedStatesWidthInMetres = unitedStatesWidthInMetres / rangeMax;
        let oneFractionOfUnitedStatesWidthInKilometres = oneFractionOfUnitedStatesWidthInMetres / metresInKilometre;
        console.log('oneFractionOfUnitedStatesWidthInMetres = ', oneFractionOfUnitedStatesWidthInMetres);
        console.log('oneFractionOfUnitedStatesWidthInKilometres = ', oneFractionOfUnitedStatesWidthInKilometres);
        */

        /*
        //let unitedStatesWidthInMetres = unitedStatesWidthInKilometres * metresInKilometre;
        //console.log('unitedStatesWidthInMetres = ', unitedStatesWidthInMetres);
        //let oneFractionOfUnitedStatesWidthInMetres = unitedStatesWidthInMetres / rangeMax;
        let oneFractionOfUnitedStatesHalfWidthInMetres = halfWidthOfUnitedStatesInMetres / rangeMax;
        //let oneFractionOfUnitedStatesWidthInKilometres = oneFractionOfUnitedStatesWidthInMetres / metresInKilometre;
        let oneFractionOfUnitedStatesHalfWidthInKilometres = oneFractionOfUnitedStatesHalfWidthInMetres / metresInKilometre;
        console.log('oneFractionOfUnitedStatesHalfWidthInMetres = ', oneFractionOfUnitedStatesHalfWidthInMetres);
        console.log('oneFractionOfUnitedStatesHalfWidthInKilometres = ', oneFractionOfUnitedStatesHalfWidthInKilometres);
        */

        //outputElement.textContent = (rangeValue * oneFractionOfUnitedStatesWidthInKilometres) + ' km';
        //outputElement.textContent = (rangeValue * oneFractionOfUnitedStatesHalfWidthInKilometres) + ' km';
        //outputElement.textContent = kilometresToMiles(rangeValue * oneFractionOfUnitedStatesHalfWidthInKilometres) + ' miles';
        //outputElement.textContent = kilometresToMiles(rangeValue * (oneFractionOfMapHalfWidthInMetres / metresInKilometre)) + ' miles';

                        //, radius: 50 * 1000 // 50 Kilometres
                //, radius: 110 * 22000 // Entire USA

        /*
         newPoint = (el.val() - el.attr("min")) / (el.attr("max") - el.attr("min"));
         offset = -1.3;
         if (newPoint < 0) { newPlace = 0;  }
         else if (newPoint > 1) { newPlace = width; }
         else { newPlace = width * newPoint + offset; offset -= newPoint;}
        */

        // Set the new radius of the SVG circle overlay.
        //if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * oneFractionOfUnitedStatesHalfWidthInMetres);
        //if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * oneFractionOfMapHalfWidthInMetres);

        // Filter the assets search results.
        //filterAssetsByCircleRadius(mapReference.current.getCenter());
        //filterAssetsByCircleRadiusAndCategory();
    };

    /* Deprecated: Using changeRadiusRange() now instead.
    const changeRadius = (rangeValue) => {
        console.log('changeRadius('+rangeValue+')');
        //console.log(Number(this.state.inputRad));
        //console.log('centerRadiusCircleReference', centerRadiusCircleReference);
        //console.log('centerRadiusCircleReference.current', centerRadiusCircleReference.current);
        //console.log('centerRadiusCircleState = ', centerRadiusCircleState);
        //(centerRadiusCircle||centerRadiusCircleState).setRadius(Number(rangeValue) * 1000);
        //if (centerRadiusCircle) centerRadiusCircle.current.setRadius(Number(rangeValue) * 1000);
        if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * 22000);

        filterAssetsByCircleRadius(mapReference.current.getCenter());
    };
    //*/

    const resetCenterRadiusCircle = (newLocation) => {
        console.warn('resetCenterRadiusCircle(newLocation)', newLocation);
        
        //console.log('centerRadiusCircleReference = ' + centerRadiusCircleReference);
        //console.log('centerRadiusCircleReference.current = ' + centerRadiusCircleReference.current);
        if (centerRadiusCircleReference) centerRadiusCircleReference.current.setCenter(newLocation);

        filterAssetsByCircleRadius(mapReference.current.getCenter());
    };

    //const zoomChanged = function(x) {
    const zoomChanged = (x) => {
        console.warn('Search.zoomChanged('+x+')');
        //alert('Search.zoomChanged('+x+')');

        //console.log('mapReference.current = ' + mapReference.current);
        //console.log('mapReference.current.getZoom() = ' + mapReference.current.getZoom());
        //console.log('mapReference.current.getCenter() = ' + mapReference.current.getCenter());

        /*
        const newCenter = this.googleMap.current.getCenter();
        this.setState({
            zoom: this.googleMap.current.getZoom(),
            center: {
                latitude: newCenter.lat(),
                longitude: newCenter.lng()
            }
        });
        */
        //centerRadiusCircleReference.current.setCenter(mapReference.current.getCenter());

        let mapsApi = mapsApiReference.current;
        var bounds = mapReference.current.getBounds();
        //console.log('bounds = ', bounds);

        // from that, get the coordinates for the NE and SW corners
        var NE = bounds.getNorthEast();
        var SW = bounds.getSouthWest();
        //console.log('NE = ', NE);
        //console.log('SW = ', SW);

        // from that, figure out the latitudes and the longitudes
        var lat1 =  NE.lat();
        var lat2 =  SW.lat();
        var lng1 =  NE.lng();
        var lng2 =  SW.lng();

        //console.log('lat1 = ', lat1);
        //console.log('lat2 = ', lat2);
        //console.log('lng1 = ', lng1);
        //console.log('lng2 = ', lng2);

        // construct new LatLngs using the coordinates for the horizontal distance between lng1 and lng2
        var horizontalLatLng1 = new mapsApi.LatLng(lat1,lng1);
        var horizontalLatLng2 = new mapsApi.LatLng(lat1,lng2);

        // construct new LatLngs using the coordinates for the vertical distance between lat1 and lat2
        var verticalLatLng1 = new mapsApi.LatLng(lat1,lng1);
        var verticalLatLng2 = new mapsApi.LatLng(lat2,lng1);

        // work out the distance horizontally
        var widthOfMapInMetres = mapsApi.geometry.spherical.computeDistanceBetween(horizontalLatLng1, horizontalLatLng2);
        console.warn('    widthOfMapInMetres = ', widthOfMapInMetres);

        let halfWidthOfMapInMetres = parseInt(widthOfMapInMetres / 2);
        console.warn('    halfWidthOfMapInMetres = ', halfWidthOfMapInMetres);

        let oneFractionOfMapHalfWidthInMetres = halfWidthOfMapInMetres / inputRangeMax;
        console.warn('    oneFractionOfMapHalfWidthInMetres = ', oneFractionOfMapHalfWidthInMetres);

        setOneFractionOfMapHalfWidthInMetres(oneFractionOfMapHalfWidthInMetres);
        //setOneFractionOfMapHalfWidthInMetres(halfWidthOfMapInMetres / inputRangeMax);
        console.warn('    oneFractionOfMapHalfWidthInMetres = ', oneFractionOfMapHalfWidthInMetres);

        let oneFractionOfMapHalfWidthInKilometres = oneFractionOfMapHalfWidthInMetres / metresInKilometre;
        console.warn('    oneFractionOfMapHalfWidthInKilometres = ', oneFractionOfMapHalfWidthInKilometres);

        //filterAssetsByCircleRadius(mapReference.current.getCenter());

        let inputRange = inputRangeReference.current;
        console.warn('    inputRange = ', inputRange);

        //* Functionality to adjust the radius range and the output when the map is zoomed
        let rangeValue = inputRange.value;
        console.log('    rangeValue = ', rangeValue);

        let outputElement = inputRange.nextSibling;
        console.log('    outputElement = ', outputElement);

        outputElement.textContent = kilometresToMiles(rangeValue * oneFractionOfMapHalfWidthInKilometres) + ' miles';

        // Do we need to adjust the circle when the map is zoomed? The circle appears to zoom with the map automatically.
        //updateCircleRadius(rangeValue);
        //setTimeout(function () { updateCircleRadius(rangeValue); }, 1000)

        adjustOutputPosition(inputRange);
        //*/

        inputRange.focus(); // Focus on the range at the end of the zoom change to allow key stroke adjustments.

        // Set the new radius of the SVG circle overlay.
        //if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * oneFractionOfUnitedStatesHalfWidthInMetres);
        if (centerRadiusCircleReference) centerRadiusCircleReference.current.setRadius(Number(rangeValue) * oneFractionOfMapHalfWidthInMetres);
    };

    // { center, zoom, bounds, marginBounds }
    const handleChangeGoogleMap = (x) => {
        console.warn('Search.handleChangeGoogleMap(x:'+x+') ', x);

        console.log('    allAssets', allAssets);
        let { center, zoom, bounds, marginBounds } = x;
        console.log('    center = ', center);
        console.log('    zoom = ', zoom);
        console.log('    bounds = ', bounds);

        //filterAssetsByCircleRadius(mapReference.current.getCenter());
        filterAssetsByCircleRadius(center);
    }

    //*
    const handleCenterChanged = () => {
        console.warn('handleCenterChanged()');
        //console.log(mapReference.current);
        //if (mapReference.current) {
            //console.log(parseCoords(mapReference.current.getCenter()));
            //console.log('mapReference.current.getCenter() = '+mapReference.current.getCenter());
            //resetCenterRadiusCircle(mapReference.current.getCenter());
            //if (centerRadiusCircle) centerRadiusCircle.current.setCenter(newLocation);
            centerRadiusCircleReference.current.setCenter(mapReference.current.getCenter());
        //}
    };
    //*/
    
    /*
    //function showValue(newValue) {
    const setZoomLevel = function(rangeValue) {
        console.log('setZoomLevel(rangeValue:'+rangeValue+')');

        //document.getElementById("radius-range").innerHTML = newValue;

        console.log('mapReference.current = ', mapReference.current);
        console.log('mapState = ', mapState);

        //var value=1;
        let value = rangeValue;

        if (50 < value <= 100) {
            //mapReference.current.setZoom(11);
            mapState.setZoom(11);
        } else if (25 < value <= 50) {
            //mapReference.current.setZoom(12);
            mapState.setZoom(12);
        } else if (10 < value <= 25) {
            //mapReference.current.setZoom(13);
            mapState.setZoom(13);
        } else if (5 < value <= 10) {
            //mapReference.current.setZoom(g1427);
            mapState.setZoom(14);
        } else {
            //mapReference.current.setZoom(15);
            mapState.setZoom(15);
        }
    };
    */

    const clickZoomIn = () => {
        console.warn('clickZoomIn()');
        //mapReference.current = map;
        //mapsApiReference.current = mapsApi;
        let zoomLevel = mapReference.current.getZoom();
        console.log('zoomLevel = ', zoomLevel);
        mapReference.current.setZoom(++zoomLevel);
    };

    const clickZoomOut = () => {
        console.warn('clickZoomOut()');
        //mapReference.current = map;
        //mapsApiReference.current = mapsApi;
        let zoomLevel = mapReference.current.getZoom();
        console.log('zoomLevel = ', zoomLevel);
        mapReference.current.setZoom(--zoomLevel);
    };

    const clickSearchResult = (assetId) => {
        console.warn('clickSearchResult('+assetId+')');
        window.open('/item/' + assetId, '_blank');
    };

    return (

        <main
            //className = { styles.searchPage }
            className = 'search'
            style = {{
                //outline: "blue 3px solid"
            }}
            >

            <section
                className={'form'}
                style = {{
                    //border: "green 3px solid"
                    //padding: '0px'
                }}
                >

                <div
                    //className={styles.maxWidth}
                    style = {{
                        //border: "orange 3px solid"
                    }}
                    >

                    <div
                        //className = { 'flex-container' }
                        //style = {{
                              //justifyContent: 'start'
                        //      justifyContent: 'space-around'
                        //    , marginTop: '40px'
                            //, gap: '25px'
                        //    , gap: '1%'
                        //}}
                        style = {{
                            margin: "auto"
                            , textAlign: "center"
                            //, border: "black 3px solid"
                        }}
                        >

                        <div
                            id = 'filter-section-wrapper'
                            style = {{
                                //  margin: "auto"
                                //, textAlign: "center"
                                //, display: "inline-block"
                                //, padding: "20px 50px"
                                //borderBottom: "1px solid var(--theme-border, #DEE2E6)"
                                //, background: "var(--White, #FFF)"
                                //, width: "100%"
                            }}>

                            <div
                                id = 'filter-section'
                                style = {{
                                      margin: "auto"
                                    , textAlign: "center"
                                    , display: "inline-block"
                                    //, padding: "20px 50px"
                                }}>

                                {/* filterSection() - Removed and added inline below. It was causing some weirdness with state. */}

                                <div
                                    //className={styles.filterContainer}
                                    className = 'filter-section'
                                    style = {{
                                          margin: "auto"
                                        , textAlign: "center"
                                    }}>

                                    <div
                                        className = 'title'
                                        //style = {{ paddingBottom: '18px' }}
                                        >Find Deals</div>

                                    <div
                                        //className = 'search'
                                        className = 'input-box input-search'
                                        >
                                        {
                                            googleMapsApiHasLoaded
                                            &&
                                                <Autocomplete
                                                    apiKey = { REACT_APP_GOOGLE_MAPS_API_KEY }
                                                    //style = {{
                                                    //      width: '356px'
                                                    //}}
                                                    //placeholder = 'City, Neighborhood, ZIP or Address'
                                                    placeholder = 'Address, City or Neighborhood'
                                                    onPlaceSelected = { onPlaceSelected }
                                                    types = {['(regions)']}
                                                    />
                                        }
                                    </div>

                                    <div id = 'radius-range'>
                                        <div
                                            style = {{
                                                  display: "flex"
                                                , flexWrap: "wrap"
                                                , justifyContent: 'space-between'
                                                , alignItems: 'flex-end'
                                                //, padding: '38px 0px'
                                            }}>
                                            <div style = {{ flexBasis: "auto", paddingRight: '20px' }}>
                                                <label htmlFor = 'radius-range'>
                                                    Radius
                                                </label>
                                            </div>
                                            <div
                                                style = {{
                                                    flexBasis: "auto"
                                                    , flexGrow: "1"
                                                    , position: "relative"
                                                    //, display: 
                                                }}>

                                                <input
                                                    type = 'range'
                                                    id = 'radius-range'
                                                    ref   = { inputRangeReference }
                                                    min   = { inputRangeMin }
                                                    max   = { inputRangeMax }
                                                    value = { inputRangeValue }
                                                    step  = { inputRangeStep }
                                                    style = {{
                                                          width: '100%'
                                                        , verticalAlign: 'middle'
                                                    }}
                                                    onChange = { e => {
                                                        //changeRadius(e.target.value);
                                                        radiusRangeChanged(e.target);
                                                    }} />

                                                <output htmlFor = "radius-range"></output>

                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div
                                            style = {{
                                                  display: "flex"
                                                , flexWrap: "wrap"
                                                , justifyContent: 'space-between'
                                                //, alignItems: 'flex-end'
                                                , alignItems: 'center'
                                                //, padding: '38px 0px'
                                            }}>
                                            <div style = {{ flexBasis: "auto", paddingRight: '20px' }}>
                                                <label>
                                                    Zoom
                                                </label>
                                            </div>
                                            <div
                                                style = {{
                                                    flexBasis: "auto"
                                                    //, padding: '10px'
                                                }}>
                                                <button
                                                    //className = 'primary medium'
                                                    className = 'primary'
                                                    style = {{
                                                        padding: '6px 8px 8px 8px'
                                                        , verticalAlign: 'middle'
                                                    }}
                                                    onClick = { clickZoomIn }
                                                    >
                                                    <AddIcon
                                                        style = {{ verticalAlign: 'middle' }}
                                                        />
                                                </button>
                                            </div>
                                            <div style = {{ flexBasis: "auto", padding: '10px' }}>
                                                <button
                                                    className = 'primary'
                                                    style = {{ padding: '6px 8px 8px 8px' }}
                                                    onClick = { clickZoomOut }
                                                    >
                                                    <RemoveIcon
                                                        style = {{ verticalAlign: 'bottom' }}
                                                        />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div id = 'select-property-type'>
                                        <div style={{ position: 'relative' }}>
                                            <CategoriesSelect
                                                //closeDrawer={closeDrawer}
                                                onChangeCategory = { onChangeCategory }
                                                categoryIds = { categoryIds }
                                                setCategoryIds = { setCategoryIds }
                                                categoryObjectArray = { categoryObjectArray }
                                                setCategoryObjectArray = { setCategoryObjectArray }
                                                //callCategoriesSelect = { callCategoriesSelect }
                                                //selectCategoriesReference = { selectCategoriesReference }
                                                //ref = { selectCategoriesReference }
                                                />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div
                            style = {{
                                  display: 'flex'
                                , flexWrap: 'wrap'
                                , justifyContent: 'space-between'
                                , maxWidth: '100vw'
                                , backgroundColor: '#F8F9FA'
                                //, marginLeft: '15px'
                                //, marginRight: '15px'
                                , rowGap: '20px'
                            }}>

                            <div
                                id = 'google-maps-wrapper'
                                style = {{
                                    //  width: '300px'
                                    //, height: '300px'
                                    //, height: 'calc(100vh - 110px)'
                                    //, flexBasis: '50%'
                                    flexGrow: '1'
                                }}>

                                <GoogleMap
                                    apiKey = 'AIzaSyDT1Np7jvRbxXqeBnv0bESVS60i4xAtAkQ'
                                    bootstrapURLKeys = {{
                                          key: 'AIzaSyDT1Np7jvRbxXqeBnv0bESVS60i4xAtAkQ'
                                        //  key: { REACT_APP_GOOGLE_MAPS_API_KEY }
                                        , libraries: ['places', 'directions']
                                    }}
                                    libraries = { ['places', 'directions'] }
                                    defaultZoom = { initialZoom } // Supports DP, e.g 11.5
                                    defaultCenter = {{
                                          lat: latLngUsa.lat
                                        , lng: latLngUsa.lng
                                    }} // USA
                                    onDragEnd = { handleDragEnd }
                                    //onZoomChanged = { zoomChanged }
                                    //onZoomAnimationStart = { zoomChanged }
                                    //onZoomAnimationEnd = { zoomChanged }
                                    //onCenterChanged = { handleCenterChanged } // this is added in the apiHasLoaded() function call
                                    onChange = { handleChangeGoogleMap }
                                    yesIWantToUseGoogleMapApiInternals = { true }
                                    onGoogleApiLoaded = {({ map, maps }) => apiHasLoaded(map, maps)}
                                    >

                                    {/* console.warn('Search (JSX Markers): filteredAssets.length = ', filteredAssets.length) /*}
                                    {/* console.warn('Search (JSX Markers): filteredAssets = ', filteredAssets) */}
                                    {/* filteredAssets.length > 0 && console.warn('Search (JSX Markers): filteredAssets.length = ', filteredAssets.length) */}
                                    {/* filteredAssets.length > 0 && console.warn('Search (JSX Markers): filteredAssets = ', filteredAssets) */}

                                    {
                                        //coordinates.map(({ lat, lng, name }, index) => (
                                        //allAssets.map(({ lat, lng, name }, index) => (
                                        filteredAssets.map((assetObject, index) => (

                                            (assetObject.asset.lat && assetObject.asset.lng)
                                            &&
                                            <MapMarker
                                                //viewBox = '0 0 10 10'
                                                //viewBox = '0 0 14 14'
                                                circleRadius = '7'
                                                widthHeight = '18'
                                                asset = { assetObject.asset }
                                                //key = { assetObject.asset.index }
                                                key = { assetObject.asset.id }
                                                lat = { assetObject.asset.lat }
                                                lng = { assetObject.asset.lng }
                                                //markerId = { assetObject.asset.name }
                                                categoryId = { assetObject.asset.categoryId }
                                                fillColor = { categoryColors[assetObject.asset.categoryId] }
                                                //onClick = { onMarkerClick } // you need to manage this prop on your Marker component!
                                                // draggable={true}
                                                // onDragStart={(e, { latLng }) => {}}
                                                // onDrag={(e, { latLng }) => {}}
                                                // onDragEnd={(e, { latLng }) => {}}
                                                />
                                        ))
                                    }
                                </GoogleMap>
                            </div>

                            <div
                                className={'search-results'}
                                style = {{
                                    flexBasis: '50%'
                                    , flexGrow: '1'
                                    //, paddingTop: "24px"
                                    , padding: '24px 0px'
                                    , backgroundColor: "#F8F9FA"
                                }}
                                >

                                <div
                                    //className={'' + styles.assetsContainer}
                                    className = 'assets-container'
                                    style = {{
                                        gap: '24px'
                                        , justifyContent: 'space-evenly'
                                        //, height: '100%'
                                        //, alignItems: 'center'
                                    }}>

                                    {/* filteredAssets.length > 0 && console.warn('Search (JSX Results): filteredAssets.length = ', filteredAssets.length) */}

                                    {

                                        filteredAssets.length === 0
                                        ? (
                                            <div
                                                className={`${styles.assetContainer} ${styles.noAsset}`}
                                                style = {{
                                                    //  display: 'flex'
                                                    //, height: '100%'
                                                    //, alignItems: 'center'
                                                    //, border: 'blue 2px solid'
                                                    //paddingTop: '50px'
                                                    //width: '100%'
                                                    //minWidth: '335px'
                                                    // marginTop: '50px'
                                                    //, width: '335px'
                                                }}
                                                >
                                                <h4
                                                    className = 'no-results'
                                                    style = {{
                                                        padding: '30px 0px'
                                                    }}
                                                    >No Assets for Current Filter</h4>
                                            </div>
                                        ) : (
                                            <>
                                            {
                                                //filteredAssets.map((x, index) => (
                                                filteredAssets
                                                    .sort((a, b) => a.id - b.id)
                                                    .map((assetObject, index) => {

                                                        if (!assetObject?.asset) return;
                                                        //if (!assetObject?.asset || index > 0) return; // Temporary for development to only show 1 item.

                                                        { categoryName = getCategoryName(assetObject?.asset.categoryId) }
                                                        console.debug('Search (JSX): categoryName = ', categoryName);

                                                        return (

                                                            <div
                                                                key = { index }
                                                                //className={styles.assetContainer}
                                                                className = 'asset-container'
                                                                //onClick = { () => history.push('/item/' + assetObject?.id) }
                                                                onClick = { () => clickSearchResult(assetObject?.id) }
                                                                style = {{
                                                                    cursor: 'pointer'
                                                                    , paddingBottom: '10px'
                                                                    //, flexGrow: '1'
                                                                    //, flexShrink: '1'
                                                                    //, flexBasis: '300px'
                                                                }}>

                                                                {/*
                                                                <img
                                                                    src = {
                                                                           assetObject?.asset?.thumbnailUrl
                                                                        || assetObject?.asset?.mediaPreviewUrl
                                                                    }
                                                                    //className = { styles.thumbnailImage }
                                                                    className = 'thumbnail'
                                                                    alt = 'thumbnail.png'
                                                                    />
                                                                */}

                                                                {/* Previous button without the Past Deals flag.
                                                                <div
                                                                    //className = { cn("item", categoryName, styles.item)}
                                                                    className = { cn("item", categoryName) }
                                                                    //onClick={() => history.push("/item/" + assetObject?.id)}
                                                                    //onClick = { () => history.push("/search/" + categoryName); return false; }
                                                                    //onClick = { (event) => clickCategory(getCategoryName(assetObject?.asset.categoryId), event) }
                                                                    onClick = { (event) => clickCategoryId(assetObject?.asset.categoryId, event) }
                                                                    //onClick = { function() { history.push("/search/" + getCategoryName(assetObject?.asset.categoryId)); return false; }
                                                                        style = {{ position: 'relative' }}
                                                                    >
                                                                    <div
                                                                        className = { cn('item-top') }
                                                                        style = {{
                                                                            position: 'absolute'
                                                                            , right: '0'
                                                                            , margin: '10px'
                                                                        }}
                                                                        >
                                                                        <button
                                                                            style = {{
                                                                                textAlign: 'right'
                                                                                , margin: 'auto'
                                                                            }}
                                                                            className = 'text-shadow'
                                                                            >
                                                                            { categoryName }
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                */}

                                                                <div
                                                                    className = { cn("item", categoryName) }
                                                                    style = {{
                                                                        position: 'relative'
                                                                    }}>

                                                                    <div
                                                                        //className = { categoryName }
                                                                        //className = { cn(categoryName, 'text-shadow') }
                                                                        className = { cn('item-top') }
                                                                        style = {{
                                                                            position: 'absolute'
                                                                            //, top: '-28px'
                                                                            , bottom: '-48px'
                                                                            , right: '0'
                                                                            , margin: '10px'
                                                                            , whiteSpace: 'nowrap'
                                                                        }}>

                                                                        {
                                                                            //productDetails?.status == 'past-deal'
                                                                            //assetObject?.asset?.status == 'past-deal'
                                                                            assetObject?.saleType == 'past-deal'
                                                                            ? <div
                                                                                style = {{
                                                                                    //color: 'white'
                                                                                    display: 'flex'
                                                                                    //, marginLeft: '8px'
                                                                                    , margin: 'auto'
                                                                                    //, position: 'absolute'
                                                                                    //, right: '0'
                                                                                    //, top: '-40px'
                                                                                    //, border: 'red 1px solid'
                                                                                    , textAlign: 'center'
                                                                                }}>
                                                                                <div
                                                                                    style = {{
                                                                                          padding: '3px 8px'
                                                                                        , fontSize: '13px'
                                                                                        , fontWeight: '500'
                                                                                        , verticalAlign: 'top'
                                                                                        , fontFamily: 'DM Sans'
                                                                                        , textAlign: 'center'
                                                                                        , textTransform: 'none'
                                                                                        , border: 'red 2px solid'
                                                                                        , borderWidth: '2px 2px 0px 2px'
                                                                                        , backgroundColor: 'white'
                                                                                        //, backgroundColor: 'red'
                                                                                        , borderTopLeftRadius: '3px'
                                                                                        , borderTopRightRadius: '3px'
                                                                                        , margin: 'auto'
                                                                                        , color: 'red'
                                                                                        //, color: 'white'
                                                                                        , boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'
                                                                                    }}>
                                                                                    Past Deal
                                                                                </div>
                                                                            </div>

                                                                            : <div
                                                                                style = {{
                                                                                    //color: 'white'
                                                                                    display: 'flex'
                                                                                    //, marginLeft: '8px'
                                                                                    , margin: 'auto'
                                                                                    //, position: 'absolute'
                                                                                    //, right: '0'
                                                                                    //, top: '-40px'
                                                                                    //, border: 'red 1px solid'
                                                                                    , textAlign: 'center'
                                                                                }}>

                                                                                

                                                                                <div
                                                                                    style = {{
                                                                                          padding: '3px 8px'
                                                                                        , fontSize: '13px'
                                                                                        , fontWeight: '500'
                                                                                        , verticalAlign: 'top'
                                                                                        , fontFamily: 'DM Sans'
                                                                                        , textAlign: 'center'
                                                                                        , textTransform: 'none'
                                                                                        , border: 'green 2px solid'
                                                                                        //, borderWidth: '2px 2px 0px 2px'
                                                                                        , borderWidth: '2px'
                                                                                        , borderBottomWidth: '0px'
                                                                                        //, backgroundColor: 'white'
                                                                                        //, backgroundColor: assetObject?.isResell ? 'green' : 'white'
                                                                                        , backgroundColor: 'green'
                                                                                        , borderTopLeftRadius: '3px'
                                                                                        , borderTopRightRadius: '3px'
                                                                                        , margin: 'auto'
                                                                                        //, color: 'green'
                                                                                        //, color: assetObject?.isResell ? 'white' : 'green'
                                                                                        , color: 'white'
                                                                                        , boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'
                                                                                    }}>
                                                                                    
                                                                                    {/*
                                                                                    {
                                                                                        assetObject?.isResell
                                                                                        ? <span>Resell ({ assetObject?.availableFractions } Splits)</span>
                                                                                        : <span>Available</span>
                                                                                    }
                                                                                    {
                                                                                        assetObject?.isResell
                                                                                        ? <span>
                                                                                            {
                                                                                                assetObject?.availableFractions
                                                                                            } Split{
                                                                                                assetObject?.availableFractions > 1 ? "s" : ""
                                                                                            }
                                                                                        </span>
                                                                                        : <span>Available</span>
                                                                                    }
                                                                                    */}
                                                                                    {

                                                                                        assetObject?.isResell
                                                                                        
                                                                                        ? <span>Resale {
                                                                                                assetObject?.availableFractions
                                                                                            }/{
                                                                                                assetObject?.totalFractions
                                                                                            }
                                                                                        </span>
                                                                                        : <span>Available</span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        <button
                                                                            className = { cn(categoryName, 'text-shadow') }
                                                                            style = {{
                                                                                boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'
                                                                                , fontSize: '14px'
                                                                            }}
                                                                            >
                                                                            { categoryName }
                                                                        </button>

                                                                    </div>

                                                                </div>

                                                                <div
                                                                    className = 'preview-image'
                                                                    style = {{
                                                                          backgroundImage: 'url(' + (assetObject?.asset?.thumbnailUrl || assetObject?.asset?.mediaPreviewUrl) + ')'
                                                                          /*
                                                                        , backgroundRepeat: 'no-repeat'
                                                                        , backgroundPosition: 'center'
                                                                        //, backgroundSize: 'cover'
                                                                        //, backgroundSize: 'contain'
                                                                        //, backgroundSize: 'auto 100%'
                                                                        //, backgroundSize: '100% auto'
                                                                        , backgroundSize: '100% 100%'
                                                                        , borderTopLeftRadius: '10px'
                                                                        , borderTopRightRadius: '10px'
                                                                        , width: '335px'
                                                                        //, minWidth: '64px'
                                                                        , height: '180px'
                                                                        */
                                                                    }}
                                                                    >
                                                                </div>

                                                                <div
                                                                    style = {{
                                                                          padding: '10px 10px 5px 10px'
                                                                        , margin: '0px'
                                                                        , display: 'flex'
                                                                        , flexWrap: 'wrap'
                                                                        , justifyContent: 'space-between'
                                                                    }}>

                                                                    <div className = 'watch-info-box'>
                                                                        <div>
                                                                            <div
                                                                                className = 'watch-title'
                                                                                style = {{
                                                                                    //maxWidth: '220px'
                                                                                    maxWidth: '315px'
                                                                                }}
                                                                                >
                                                                                { assetObject?.asset?.name }
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div
                                                                                className = 'watch-subtitle'
                                                                                style = {{ maxWidth: '220px' }}>
                                                                                { assetObject?.asset?.location }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/*
                                                                    <div
                                                                        //className = { cn("item", categoryName, styles.item)}
                                                                        className = { cn("item", categoryName) }
                                                                        //onClick={() => history.push("/item/" + assetObject?.id)}
                                                                        //onClick = { () => history.push("/search/" + categoryName); return false; }
                                                                        //onClick = { (event) => clickCategory(getCategoryName(assetObject?.asset.categoryId), event) }
                                                                        onClick = { (event) => clickCategoryId(assetObject?.asset.categoryId, event) }
                                                                        //onClick = { function() { history.push("/search/" + getCategoryName(assetObject?.asset.categoryId)); return false; }
                                                                        >
                                                                        <div className = { cn('item-top') }>
                                                                            <button>
                                                                                { categoryName }
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    */}

                                                                    {/*
                                                                    <div className={'price-box'}
                                                                        style = {{
                                                                              display: "flex"
                                                                            , flexWrap: "wrap"
                                                                            , justifyContent: "space-between"
                                                                            , maxWidth: "220px"
                                                                        }}
                                                                        >

                                                                        <div>
                                                                            <div className = { 'table' }>
                                                                                <div className = { 'row' }>
                                                                                    <div style = {{ whiteSpace: 'nowrap' }}>Price USD</div>
                                                                                </div>
                                                                                <div className = { 'row' }>
                                                                                    <div className={`price price-dollars ${styles.price}}`}>
                                                                                        US${displayPrice(assetObject?.asset, exchangeRate)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div className = { 'table' }>
                                                                                <div className = { 'row' }>
                                                                                    <div style = {{ whiteSpace: 'nowrap' }}>Price Crypto</div>
                                                                                </div>
                                                                                <div className = { 'row' }>
                                                                                    <div
                                                                                        className={cn('price', 'price-crypto')}
                                                                                        style = {{ whiteSpace: 'nowrap' }}
                                                                                        >
                                                                                        {(
                                                                                          assetObject?.asset?.regularPrice
                                                                                        ).toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)}{' '}
                                                                                        MATIC
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    */}
                                                                </div>

                                                                {
                                                                    assetObject?.asset?.status != 'past-deal'
                                                                    ?
                                                                        <div
                                                                            className = 'small-details'
                                                                            //style = {{
                                                                            //    paddingBottom: '10px'
                                                                            //}}
                                                                            >

                                                                            <span className = 'label'>Closing Date:</span>
                                                                            &nbsp;
                                                                            <span>{
                                                                                new Date(
                                                                                    Date.parse(assetObject?.asset.closingDate)
                                                                                )
                                                                                .toLocaleDateString(
                                                                                    "en-US"
                                                                                    //, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
                                                                                    //, { year: 'numeric', month: 'long', day: 'numeric' }
                                                                                    , { year: 'numeric', month: 'short', day: 'numeric' }
                                                                                )
                                                                            }</span>
                                                                        </div>
                                                                    :
                                                                        <div
                                                                            className = 'small-details'
                                                                            //style = {{
                                                                            //    display: 'inline-block'
                                                                            //    , textAlign: 'right'
                                                                            //    , lineHeight: '18px'
                                                                            //}}
                                                                            >
                                                                            <span className = 'label'>Price:</span>
                                                                            &nbsp;
                                                                            <span style = {{ whiteSpace: 'nowrap' }}>
                                                                                {
                                                                                    assetObject?.asset?.purchasePrice
                                                                                }
                                                                            </span>

                                                                            {/*
                                                                            */}
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className = 'label'>ROI:</span>
                                                                            &nbsp;<span style = {{ whiteSpace: 'nowrap' }}>
                                                                                {
                                                                                    assetObject?.asset?.returnOnInvestment
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                }



                                                            </div>

                                                            
                                                        )
                                                    })
                                            }
                                            </>
                                        )
                                    }
                                </div>

                                {
                                    filteredAssets.length > rowsPerPage
                                    && (
                                        <ReactPaginate
                                            previousLabel         = { "← Previous" }
                                            nextLabel             = { "Next →" }
                                            pageCount             = { count || 1 }
                                            onPageChange          = { handlePageClick }
                                            containerClassName    = { paginateStyles.pagination }
                                            pageClassName         = { paginateStyles.pagination_page }
                                            previousLinkClassName = { paginateStyles.pagination__link__previous }
                                            nextLinkClassName     = { paginateStyles.pagination__link__next }
                                            disabledClassName     = { paginateStyles.pagination__link__disabled }
                                            activeClassName       = { paginateStyles.pagination__link__active }
                                            pageRangeDisplayed    = { PAGINATE.PAGE_RANGE_DISPLAYED }
                                            marginPagesDisplayed  = { PAGINATE.MARGIN_PAGES_DISPLAYED }
                                            />
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Search;
