import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
//import { CircularProgress } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import {
      PopUpAlert
    , PopUpConfirmation
    , PopUpWarning
} from '../../controller/utils';
import './style.scss';
import { countCart } from '../../redux/cartSlice';
import { axiosInstance } from '../../utils/API';
import { numberWithCommas } from '../../utils/formatPricingNumber';
import AddPhysicalAddressModal from './AddPhysicAddressModal';
import AddWalletAddressModal from './AddWalletAddressModal';
//import ReceiverOptions from './ReceiverOptions';
import { useCreateStripeCheckoutCart } from './apis/useCreateStripeCheckoutCart'
import { useCreateNotification } from './apis/useCreateNotification';
import { CRYPTOCURRENCY_CONSTANTS, NAMES_CONSTANTS } from '../../constants';

const receiverTypeOptions = [
  {
    id: 'buy_for_me',
    name: 'Buy for me'
  },
  {
    id: 'send_gift',
    name: 'Send as a gift'
  }
];

//const CartCheckout = () => {
//const CartCheckout = (refreshUser) => {
const CartCheckout = (props) => {
    console.warn('CartCheckout(props)');

    console.warn('CartCheckout: props.userDetails = ', props.userDetails);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [isShowWalletAddress, setIsShowWalletAddress] = useState(false);
    const [isShowPhysicalAddress, setIsShowPhysicalAddress] = useState(false);
    const [receiverType, setReceiverType] = useState(receiverTypeOptions[0].id);
    const [physicalAddress, setPhysicalAddress] = useState({});

    //const count = useSelector(state => state.cart.countCart);
    const exchangeRate = useSelector(state => state.counter.euroValue);

    const searchParams = new URLSearchParams(location.search);
    const receiverTypeParam = searchParams.get('receiver_type');
    const [assets, setAssetList] = useState([]);
    const [cartDetail, setCartDetail] = useState();
    const {mutate: createStripeCheckoutCart, isLoading: isLoadingStripeCheckout} = useCreateStripeCheckoutCart();
    const {mutate: createNotification} = useCreateNotification();

    useEffect(() => {
      if (receiverTypeParam) setReceiverType(receiverTypeParam);
    }, [location.search]);

    useEffect(() => {
        console.warn('Header: useEffect(() => {');
        getListCart();

        return () => {
          
            axiosInstance.post('/klaviyo/event', {
                event: "Checkout Attempt",
                properties: {
                    checkoutCompleted: false
                }
            })

            createNotification({
                title: 'Did you forget to checkout?',
                description: 'Your cart is still waiting for you. Checkout now!',
                name: 'checkout-forgot',
                pth: '/checkout'
            })
        }
    }, []);

    const TimeAgo = ({ dateTime }) => {
        const timeAgo = moment(dateTime).fromNow();
        return <span>{timeAgo}</span>;
    };

    const getConfig = () => {
        let config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };
        return config;
    };

    /*
    const handleOptionOnChange = value => {
        searchParams.set('receiver_type', value);
    };
    */

    const getListCart = async () => {
        console.warn('CartCheckout.getListCart()');;
        const config = getConfig();
        const res = await axiosInstance.get('/carts?limit=100&page=1', config);
        console.warn('res = ', res);
        if (res.data) {
            console.warn('res.data = ', res.data);
            setCartDetail(res.data?.result);
            setAssetList(res.data?.result?.assets);
            //dispatch(countCart(res.data.result.totalItems));
            dispatch(countCart(res.data.result.total));
        }
    };

    const handleDeleteCart = async item => {
        const config = getConfig();

        await axiosInstance
            .post(`carts/${item['assets.id']}/listedAssets/${item.listedAssetId}`, { isDeleteItem : true }, config)
            .then(res => {
              if (res.data.success) {
                getListCart();
              }
            })
            .catch(err => {});
    };

    const handleBuyWithBalance = async item => {
        console.warn('CartCheckout.handleBuyWithBalance(item)');

        /*
        PopUpWarning(
            'Insufficient funds'
            , 'Your account has insufficient funds to purchase this investment amount.'
            , "warning"
        //).then(() => {
        //      document.getElementById('connectWallet').click()
        //}
        );
        return;
        */

        console.warn('NAMES_CONSTANTS.USER_WALLET_DETAILS = ', NAMES_CONSTANTS.USER_WALLET_DETAILS);
        console.warn('localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS) = ', localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));

        const config = getConfig();
        const wallet = JSON.parse(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS))
        const adminAdress = process.env.REACT_APP_ADMIN_ADDRESS;
        console.warn('wallet = ', wallet);
        console.warn('adminAdress = ', adminAdress);
        console.warn('props.userDetails = ', props.userDetails);

        let requestBody = {
              from: adminAdress
            //, to: wallet.accounts[0]
            , paymentType: 'bank-wire'
        };
        //if (wallet) requestBody.to = wallet.accounts[0];
        if (wallet?.accounts) requestBody.to = wallet.accounts[0];
        console.warn('requestBody = ', requestBody);

        await axiosInstance
            .post('/carts/checkout', requestBody, config)
            .then(response => {
                console.warn('response', response);
                if (response.data.success) {
                    console.warn('response.data', response.data);

                    //getListCart();
                    //history.push('/cart');
                    history.push('/order-details');

                    //PopUpAlert(
                    PopUpConfirmation(
                          //"Info"
                        //  "Success"
                          response.data.message
                        , response.data.result.confirmationMessage
                        //, "info"
                        , "success"
                    ).then(() => {
                          //document.getElementById('connectWallet').click()
                          //history.push('/cart');
                          props.refreshUser();
                    });
                  }
            })
            .catch(err => {

                //PopUpWarning(
                PopUpAlert(
                      err.response.data.message
                    , err.response.data.error
                    , "error"
                ).then(() => {
                      document.getElementById('connectWallet').click()
                });
            });
    };

    const handleCheckout = async () => {

        const wallet = JSON.parse(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS))
        const adminAdress = process.env.REACT_APP_ADMIN_ADDRESS;

        createStripeCheckoutCart({
          from: wallet.accounts[0],
          to: adminAdress,
          exchangeRate,
          physicalAddress,
          paymentType: 'stripe'
        }, {
          onSuccess: (res) => {
            if (res.data.success) {
              const url = res.data.result.url;
              window.location.replace(url);
            }
          },
          onError: (err) => {
            console.error({err},'Something went wrong!');
          }
        })
    };

    const handleAddPhysicalAddress = (data) => {
      if (data) {
        setPhysicalAddress(data)
      } else {
        setPhysicalAddress({})
      }
    }

    const handleDeleteCartItem = async function(event, item) {
        console.warn("handleDeleteCartItem(item)", event, item);
        console.warn("handleDeleteCartItem(item): item = " + item);
        console.warn("handleDeleteCartItem(item): event = " + event);
        console.warn("handleDeleteCartItem(item): event.preventDefault = " + event.preventDefault);
        event.preventDefault();
        console.warn("handleDeleteCartItem(item): event.stopPropagation = " + event.stopPropagation);
        event.stopPropagation();

        handleDeleteCart(item);

        return false;
    };

    let itemsIndex = 0;
    let itemsIndex2 = 0;

    return (

        <main
            className = 'edit-profile'
            //className={styles.main}
            >

            {/*
                loaderActive
                && (
                    <div className={styles.loaderContent}>
                        <CircularProgress />
                    </div>
                )
            */}

            {/*
                resalePopup
                && (
                    <ResellModal
                        closeModal = { closeModal }
                        resellMethod = { resellMethod }
                        item = { productDetails }
                        productInfo = { productInfo }
                        loaderShow = { setLoaderActive }
                        //getAllAssets = { getAllAssets }
                        />
                )
            */}

            <section className = { 'form' }>

                <div className = { 'max-width' }>

                    <div className='cart-page cart-checkout-page'>

                        {
                          <AddWalletAddressModal
                              isOpen={isShowWalletAddress}
                              closeModal={() => setIsShowWalletAddress(false)}
                              />
                        }

                        {
                            <AddPhysicalAddressModal
                                isOpen={isShowPhysicalAddress}
                                closeModal={() => setIsShowPhysicalAddress(false)}
                                addPhysicalAddress={(data) => handleAddPhysicalAddress(data)}
                                action={Object.keys(physicalAddress).length > 0 ? 'edit' : 'add'}
                                />
                        }

                        <div className='container'>

                            {/*
                            <div className='d-flex'>
                                <div
                                    className = 'back-btn btn'
                                    onClick={() => history.push('/cart')}
                                    >
                                    <img
                                        //src='/images/icons/left-arrow-icon.svg'
                                        src='/images/icons/arrow-left.svg'
                                        style = {{
                                            filter: 'invert(11%) sepia(100%) saturate(6205%) hue-rotate(244deg) brightness(83%) contrast(145%)'
                                        }}
                                        />
                                    <p>Back to Cart</p>
                                </div>
                            </div>
                            */}

                            <div className='d-flex'>
                                <button
                                    className = 'primary-inverse circular medium'
                                    style = {{
                                        marginLeft: '0'
                                        , paddingRight: '18px'
                                        //, paddingBottom: '4px'
                                    }}
                                    onClick = { () => history.push('/cart') }
                                    >
                                    <img
                                        //src='/images/icons/left-arrow-icon.svg'
                                        src='/images/icons/arrow-left.svg'
                                        style = {{
                                            paddingRight: '10px'
                                            //, filter: 'invert(11%) sepia(100%) saturate(6205%) hue-rotate(244deg) brightness(83%) contrast(145%)'
                                        }}
                                        />
                                        <div
                                            style = {{
                                                display: 'inline-block'
                                                , lineHeight: '16px'
                                                , verticalAlign: 'middle'
                                                //, paddingTop: '2px'
                                            }}>
                                            Back to Cart
                                        </div>
                                </button>
                            </div>

                            <div
                                className = { "heading" }
                                style = {{
                                    display: 'flex'
                                    , flexDirection: 'row'
                                    , flexWrap: 'wrap'
                                    //, justifyContent: 'space-between'
                                    , justifyContent: 'flex-start'
                                }}>
                                <div style = {{ marginTop: 'auto', marginBottom: 'auto' }}>
                                    <h1 style = {{ display: 'inline-block' }}>
                                        Checkout
                                    </h1>
                                </div>
                                <div style = {{ paddingLeft: '30px' }}>
                                    <img
                                        //src = '/images/icons/referrals-icon-28.jpg'
                                        src = '/images/icons/shopping-cart-free-4-svgrepo-com-scratch.svg'
                                        style = {{
                                            //width: '50px'
                                            height: '60px'
                                            //, filter: 'var(--filter-theme-primary)'
                                            , filter: 'invert(31%) sepia(37%) saturate(2487%) hue-rotate(192deg) brightness(92%) contrast(108%)'
                                        }}
                                        />
                                    <img
                                        //src='/images/icons/left-arrow-icon.svg'
                                        src='/images/icons/arrow-right.svg'
                                        style = {{
                                              width: '40px'
                                            , marginLeft: '3px'
                                            , filter: 'invert(11%) sepia(100%) saturate(6205%) hue-rotate(244deg) brightness(83%) contrast(145%)'
                                            , filter: 'invert(31%) sepia(37%) saturate(2487%) hue-rotate(192deg) brightness(92%) contrast(108%)'
                                        }}
                                        />
                                </div>
                            </div>

                            <div
                                className = { "subtitle" }
                                //style = {{
                                //    paddingTop: "30px"
                                //    , paddingBottom: "0px"
                                //}}
                                >
                                Verify your order and continue to payment.
                            </div>

                            {/*
                            <div className='cart-description'>
                                These are the items in your shopping cart. You can send them as a gift or continue to checkout.
                            </div>
                            */}

                            <div
                                //className='cart-main'
                                className='cart-main flex-container'
                                style = {{
                                    marginTop: '30px'
                                    , padding: '20px' // Overwrite the default padding for the cart flex-container
                                }}
                                >
                                <div className='left'>
                                    {/*
                                    <div className='checkout-form'>
                                        <div className='checkout-input'>
                                            <div className='label'>
                                                <b>Receiver Name</b>
                                                <span>&nbsp;(optional)</span>
                                            </div>
                                            <div className='select'>
                                                <ReceiverOptions
                                                    optionOnChange={value => {
                                                        handleOptionOnChange(value);
                                                    }} />
                                            </div>
                                        </div>
                                        {
                                            receiverType === receiverTypeOptions[1].id && (
                                                <div className='checkout-input'>
                                                  <div className='label'>
                                                    <b>Add message note</b>
                                                  </div>
                                                  <textarea
                                                    name='message_note'
                                                    cols='30'
                                                    rows='8'
                                                    placeholder='e. g. “After purchasing you will able to receive the...”'></textarea>
                                                </div>
                                            )
                                        }
                                    </div>
                                    */}

                                    {/* {receiverType === receiverTypeOptions[1].id && (
                                      <>
                                        <hr />
                                        <p className="checkout-note">
                                          Please Add Wallet address for buying 2 NFT’s
                                        </p>
                                        <div className="d-flex">
                                          <div className="add-address wallet" onClick={() => setIsShowWalletAddress(true)}>
                                            <img src="/images/icon-plus.png" alt="" height="16px" width="16px" />
                                            <p>
                                              Add  Wallet Address
                                            </p>
                                          </div>
                                        </div>
                                        <div className="cart-item">
                                          <div className="item-check">
                                            <img src="/images/icons/tick-icon.png" alt="" width="20px" height="20px" />
                                          </div>
                                          <div className="item-img">
                                            <img src="/images/watch1.png" alt="" />
                                          </div>
                                          <div className="item-info">
                                            <div className="item-name">
                                              Sports Watch
                                            </div>
                                            <div className="item-price">
                                              0.08 MATIC
                                            </div>
                                            <div className="item-date">
                                              2 days ago
                                            </div>
                                          </div>
                                          <div className="delete-item-btn btn">
                                            <svg id="icon-close" viewBox="0 0 20 20" fill='#ccc' height="26px" width="26px">
                                              <path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="cart-item">
                                          <div className="item-check">
                                            <img src="/images/icons/tick-icon.png" alt="" width="20px" height="20px" />
                                          </div>
                                          <div className="item-img">
                                            <img src="/images/watch1.png" alt="" />
                                          </div>
                                          <div className="item-info">
                                            <div className="item-name">
                                              Sports Watch
                                            </div>
                                            <div className="item-price">
                                              0.08 MATIC
                                            </div>
                                            <div className="item-date">
                                              2 days ago
                                            </div>
                                          </div>
                                          <div className="delete-item-btn btn">
                                            <svg id="icon-close" viewBox="0 0 20 20" fill='#ccc' height="26px" width="26px">
                                              <path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </>
                                    )} */}

                                    {/*
                                    <hr />
                                    {Object.keys(physicalAddress).length > 0 ? (
                                        <div className='checkout-address'>
                                            <p>
                                                {physicalAddress.addressDestinationName}
                                            </p>
                                            <p>
                                                {physicalAddress.addressLine1}
                                            </p>
                                            <p>
                                                {physicalAddress.addressLine2}
                                            </p>
                                            <p>
                                                {physicalAddress.postalCode}
                                            </p>
                                            <p>
                                                {physicalAddress.town}
                                            </p>
                                            <p>
                                                {physicalAddress.mobile}
                                            </p>
                                        </div>
                                    ) : (
                                        <p className='checkout-note'>
                                            Please Add physicals address for the gift receiver for buying{' '}
                                            {count} watches
                                        </p>
                                    )}
                                    */}

                                    {/*
                                    <div className='d-flex'>
                                        <div
                                            className='add-address physical'
                                            onClick={() => setIsShowPhysicalAddress(true)}>
                                            <img
                                                src='/images/icon-plus.png'
                                                alt=''
                                                height='16px'
                                                width='16px'
                                                />
                                            {Object.keys(physicalAddress).length > 0 ? (
                                                <p>Edit physical Address</p>
                                            ) : (
                                                <p>Add physical Address</p>
                                            )}
                                        </div>
                                    </div>
                                    */}

                                    {
                                        assets.length > 0
                                        && assets
                                            .sort((a, b) => a.id - b.id)
                                            .map((item, index) => (
                                            <div
                                                className='cart-item'
                                                key = { index }
                                                //style = {{ cursor: 'pointer' }}
                                                //style = {{ position: 'relative' }}
                                                //onClick = { () => history.push('/item/' + item['listedAssetId'])}
                                                style = {
                                                    item['listedAsset.isResell']
                                                    ? {
                                                        position: 'relative'
                                                        //, border: 'red 2px solid'
                                                        //, backgroundColor: 'lightblue'
                                                        //, backgroundColor: 'rgba(0, 0, 255, 0.05)'
                                                        , backgroundColor: 'rgba(242, 242, 255, 0.5)'
                                                    }
                                                    : {
                                                        position: 'relative'
                                                        //, border: 'green 2px solid'
                                                        //, backgroundColor: 'lightyellow'
                                                        , backgroundColor: 'rgba(255, 255, 0, 0.1)'
                                                    }
                                                }
                                                >

                                                {/*
                                                <div className='item-check'>
                                                    <img
                                                        src='/images/icons/tick-icon.png'
                                                        alt=''
                                                        width='20px'
                                                        height='20px'
                                                        />
                                                    <input type = 'checkbox'
                                                        style = {{
                                                            border: 'blue 2px solid'
                                                            , width: '20px'
                                                            , height: '20px'
                                                        }}
                                                        checked = 'checked'
                                                        />
                                                </div>
                                                */}

                                                <div
                                                    className='item-info'
                                                    style = {{
                                                        padding: '10px'
                                                        , display: 'flex'
                                                        , flexDirection: 'row'
                                                    }}
                                                    >

                                                    <div
                                                        style = {{
                                                            margin: "auto"
                                                            , textAlign: "center"
                                                        }}
                                                        >

                                                        {
                                                            //productDetails?.status == 'past-deal'
                                                            //asset.status == 'past-deal'
                                                            item['listedAsset.isResell'] == true
                                                            && <div
                                                                style = {{
                                                                      padding: '3px 8px'
                                                                    , fontSize: '13px'
                                                                    , fontWeight: '500'
                                                                    , verticalAlign: 'bottom'
                                                                    , fontFamily: 'DM Sans'
                                                                    , textAlign: 'center'
                                                                    , textTransform: 'none'
                                                                    , border: 'green 1px solid'
                                                                    //, borderWidth: '2px 2px 0px 2px'
                                                                    , borderWidth: '2px'
                                                                    , borderBottomWidth: '0px'
                                                                    //, backgroundColor: 'white'
                                                                    , backgroundColor: 'green'
                                                                    , borderTopLeftRadius: '3px'
                                                                    , borderTopRightRadius: '3px'
                                                                    , margin: 'auto'
                                                                    //, color: 'green'
                                                                    , color: 'white'
                                                                    , display: 'inline-block'
                                                                    , boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'
                                                                }}>

                                                                <span>Resale</span>

                                                            </div>
                                                        }

                                                        <div
                                                            className='item-img'
                                                            style = {{
                                                                  backgroundImage: 'url('+item['assets.mediaPreviewUrl']+')'
                                                                , backgroundRepeat: 'no-repeat'
                                                                , backgroundPosition: 'center'
                                                                , backgroundSize: 'cover'
                                                                , width: '120px'
                                                                , minWidth: '80px'
                                                                , height: '80px'
                                                            }}
                                                            >
                                                            {/*
                                                            <img
                                                                style = {{ height: '100%', width: 'auto' }}
                                                                src={item['assets.mediaPreviewUrl']} alt='' />
                                                            */}
                                                        </div>
                                                    </div>

                                                    <div
                                                        //className='item-info'
                                                        //style = {{ padding: '10px' }}
                                                        //style = {{ padding: '10px 0px 10px 20px' }}
                                                        style = {{
                                                            paddingLeft: '20px'
                                                            , margin: 'auto'
                                                        }}
                                                        >

                                                        <div className='item-name'>
                                                              <Link to = { '/item/' + item['listedAssetId'] }>{ item['assets.name'] }</Link>
                                                        </div>

                                                        {/*
                                                        <div className='item-price'>
                                                            {
                                                                numberWithCommas(item['assets.regularPrice']?.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)) + ' ' + CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                                            }
                                                        </div>
                                                        */}
                                                        
                                                        <div className='item-date'>
                                                            <TimeAgo dateTime={item.updatedAt} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    //className="cart-item-right"
                                                    style = {{
                                                        padding: '15px'
                                                        , paddingRight: '36px'
                                                        , whiteSpace: 'nowrap'
                                                        , fontWeight: '600'
                                                    }}
                                                    >
                                                    {
                                                        item.quantity
                                                        && (
                                                            <span>${
                                                                numberWithCommas(item.quantity * item['assets.regularPrice']?.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)) + ' ' + CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                                            }</span>
                                                        )
                                                    }
                                                <div
                                                    className='delete-item-btn btn'
                                                    style = {{
                                                        position: 'absolute'
                                                        , display: 'inline-block'
                                                        //, top: 'calc(50%-10px)'
                                                        , top: '8px'
                                                        , right: '0'
                                                        , paddingRight: '15px'
                                                    }}
                                                    >
                                                    <svg
                                                        //onClick = {() => handleDeleteCart(item)}
                                                        onClick = { (e) => handleDeleteCartItem(e, item) }
                                                        id='icon-close'
                                                        viewBox='0 0 20 20'
                                                        fill='#ccc'
                                                        height='26px'
                                                        width='26px'>
                                                        <path d='M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z'></path>
                                                    </svg>
                                                </div>
                                              </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className='right'>
                                    <div className='cart-info'>
                                        <div className='summary-text'>Summary</div>
                                        {/*
                                        <div className='cart-note'>
                                            Taxes are applied, where applicable.
                                        </div>
                                        */}

                                        {
                                            assets.length > 0
                                            //&& assets?.map((item, index) => (
                                            && assets
                                                .sort((a, b) => a.id - b.id)
                                                .map(function(item, index) {

                                                    item.index = itemsIndex++;

                                                    {/* console.warn(item); */}

                                                    return (
                                                        <>
                                                        <div
                                                            //className = 'cart-item'
                                                            className='order-total'
                                                            key = { index }
                                                            //onClick = { () => history.push('/item/' + item['listedAssetId'])}
                                                            style = {{ cursor: 'pointer' }}
                                                            >

                                                            <div
                                                                className='item-info'
                                                                style = {{
                                                                    width: '100%'
                                                                    //, border: 'green 2px solid'
                                                                }}
                                                                >

                                                                <div
                                                                    //className='item-name'
                                                                    className='order-total'
                                                                    >

                                                                    { item['assets.name'] } X { item.quantity } Split{ item.quantity > 1 ? 's' : '' }

                                                                    <span>${
                                                                        cartDetail?.totalAmount
                                                                        &&
                                                                            numberWithCommas(item.quantity * item['assets.regularPrice'])
                                                                    }
                                                                    </span>
                                                                </div>
                                                                {/*
                                                                <div className='item-name'>
                                                                    { item.quantity * item['assets.regularPrice'] }
                                                                </div>
                                                                */}

                                                            </div>

                                                            
                                                                
                                                        </div>

                                                        </>
                                                    )
                                                }) // assets.map(function(item, index)
                                        }

                                        <div className='order-total'>
                                            <p>Order Total</p>
                                            <b>$
                                            {/*
                                                numberWithCommas(Math.ceil((cartDetail?.totalAmount || 0) / exchangeRate).toFixed(2)) || 0
                                            */}
                                            {
                                                cartDetail?.totalAmount
                                                &&
                                                    numberWithCommas(cartDetail.totalAmount)
                                            }
                                            </b>
                                        </div>

                                        {/*
                                        <div className='order-total'>
                                            <p>Applicable Taxes</p>
                                            <b>$ 0.00</b>
                                        </div>
                                        */}

                                        <div className='order-total'>
                                            <p>Amount to Pay</p>
                                            <strong style = {{ whiteSpace: 'nowrap' }}>
                                            {/*
                                                numberWithCommas((cartDetail?.totalAmount || 0).toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES))
                                            */}
                                            {
                                                CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                            } ${
                                                cartDetail?.totalAmount
                                                &&
                                                    numberWithCommas(cartDetail.totalAmount)
                                            }</strong>
                                        </div>
                                        {/*
                                        */}
                                        <button
                                            className = 'theme-primary large'
                                            onClick = { handleCheckout }
                                            disabled = { isLoadingStripeCheckout || Object.keys(physicalAddress).length === 0 }
                                            >
                                            {
                                                isLoadingStripeCheckout
                                                ? <CircularProgress size={25} />
                                                : 'Continue to Payment'
                                            }
                                        </button>

                                        <div
                                            style = {{
                                                  textAlign: 'center'
                                                , margin: 'auto'
                                                , lineHeight: '15px'
                                                //, padding: '0px'
                                            }}>
                                            or
                                        </div>

                                        <button
                                            className = 'theme-success large'
                                            onClick = { handleBuyWithBalance }
                                            //disabled = { isLoadingStripeCheckout || Object.keys(physicalAddress).length === 0 }
                                            >
                                            Pay with Balance
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>         
    );
};

export default CartCheckout;
