import { Link, useHistory } from 'react-router-dom';

const BankWireInstructions = () => {
    return (
        <>

        <div
            className="about-section"
            style = {{
                paddingTop: '50px'
                , marginTop: '0px'
            }}>

            <h2>
                RealSplit Bank Wire Instructions
            </h2>

            <h3 style = {{
                borderBottom: 'white 0px solid'
                , fontSize: '1.05em'
                //, color: '#fff'
                , width: '100%'
                , maxWidth: '400px'
                , margin: 'auto'
                , padding: '0px'
                }}>
                Please wire your investment funds to the account listed below
            </h3>

            <div
                style= {{
                    //display: 'flex', flex: '0 1 1'
                    paddingTop: '25px'
                }}
                >

                {/*
                <h3 style = {{
                    borderBottom: 'white 0px solid'
                    , fontSize: '1.05em'
                    //, color: '#fff'
                    , width: '100%'
                    , maxWidth: '350px'
                    }}>
                    Please wire your funds to the account below.
                </h3>
                */}


                <div
                    className = 'vision-mission'
                    //style = {{
                    //    backgroundColor: 'var(--color-theme-primary)'
                    //    , borderRadius: '8px'
                    //    , color: '#fff'
                    //    , margin: '15px 50px'
                    //    , border: 'var(--color-gray-300) 1px solid'
                    //}}
                    >

                    <h4 style = {{ borderBottom: 'white 1px solid', fontSize: '1.2em', color: '#fff' }}>
                        Bank
                    </h4>
                    <p style = {{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '25px', fontSize: '1.3em' }}>
                        <span>Name of Bank:</span> Wells Fargo
                    </p>
                    <p style = {{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '0px', fontSize: '1.3em' }}>
                        <span>Bank Address:</span> 420 Montgomery Street, San Francisco, CA 94104
                    </p>
                    <p style = {{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '0px', paddingBottom: '0px', fontSize: '1.3em' }}>
                        <span>ABA Number:</span> 1234567890
                    </p>

                    <h4 style = {{ borderBottom: 'white 1px solid', fontSize: '1.2em', color: '#fff' }}>
                        Beneficiary
                    </h4>
                    <p style = {{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '25px', fontSize: '1.3em' }}>
                        <span>Account Name:</span> REALSPLIT, INC.
                    </p>
                    <p style = {{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '0px', fontSize: '1.3em' }}>
                        <span>Account Number:</span> 1234567890
                    </p>
                    <p style = {{ paddingLeft: '50px', paddingRight: '50px', paddingTop: '0px', fontSize: '1.3em' }}>
                        <span>Beneficiary Address:</span> 2150 Town Square PL, #200, Sugar Land, Texas 77479
                    </p>

                </div>

            </div>

        </div>

        </>
    );
};
  
export default BankWireInstructions;