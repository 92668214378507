import React from 'react';
//import styles from './WhatIsRealSplit.module.sass'
//import cn from "classnames";
import { Link } from "react-router-dom";
import majesticHotelExteriorJpg from '../../../assets/images/properties/Majestic-Hotel/Majestic-Hotel-Exterior.jpg';

const WhatIsRealSplit = () => {

    return (
        <section
            style = {{
                //  backgroundImage: 'url(images/backgrounds/what-is-realsplit-background-275x183.jpg)'
                //, backgroundPosition: 'left 0px bottom -100px'
                //, backgroundRepeat: 'no-repeat'
                //, backgroundSize: '700px'
                //, maxWidth: 'var(--site-width)'
                //, maxWidth: '1500px'
                //, margin: 'auto'
                //paddingTop: '80px'
            }}
            >

            <div
                id = 'what-is-realsplit-container'
                //className = 'container'
                className = 'flex-container max-width'
                style = {{
                    maxWidth: 'var(--site-width)'
                    //maxWidth: '1350px'
                    , flexWrap: 'wrap'
                    , margin: 'auto'
                    //, width: '1500px'
                    //,  backgroundImage: 'url(images/backgrounds/what-is-realsplit-background.jpg)'
                    //, backgroundPosition: 'center'
                    //, backgroundRepeat: 'no-repeat'
                    //, backgroundImage: 'url(images/backgrounds/what-is-realsplit-background-275x183.jpg), url(images/backgrounds/what-is-realsplit-background-striped.jpg)'
                    //, backgroundImage: 'url(images/deals/Hillcrest/Hillcrest-pool.png), url(images/backgrounds/what-is-realsplit-background-striped.jpg)'
                    //, backgroundImage: 'url(images/properties/Majestic-Hotel/Majestic-Hotel-Exterior.jpg), url(images/backgrounds/what-is-realsplit-background-striped.jpg)'
                    //, backgroundPosition: 'left 0px bottom 100px, right 150px bottom 0px'
                    //, backgroundRepeat: 'no-repeat, no-repeat'
                    //, backgroundSize: '650px, 400px'
                    //, paddingBottom: '250px'
                }}
                >

                <h2
                    //className = { styles.sectionTitle }
                    /*
                    style = {{
                        //padding: '50px 0px 50px 0px'
                        textAlign: 'left'
                        //display: 'inline'
                        //, textAlign: 'left'
                        //, margin: '0'
                        , padding: '20px 0px 30px 0px'
                        , color: 'var(--color-gray-800, #343A40)'
                        //, textAlign: 'center'
                        , fontFamily: 'Roboto Serif'
                        , fontSize: '68px'
                        , fontStyle: 'italic'
                        , fontWeight: '500'
                        , lineHeight: '120%'
                        //, marginRight: '50px'
                    }}
                    */
                    >What is <span style = {{ fontWeight: '700' }}>RealSplit?</span>
                </h2>

                <div style = {{
                    display: 'flex'
                    , flexWrap: 'wrap'
                    , gap: '30px'
                    }}>

                    <div style = {{
                        flexBasis: '50%'
                        , width: '100%'
                        //, minWidth: '380px'
                        , flex: '1 1 380px'
                        }}>
                        <div className = 'table'>
                            {/*
                            <div className = 'row'>
                                <div className = 'cell'>
                                    <h2
                                        //className = { styles.sectionTitle }
                                        style = {{
                                            //padding: '50px 0px 50px 0px'
                                            textAlign: 'left'
                                            //display: 'inline'
                                            //, textAlign: 'left'
                                            //, margin: '0'
                                            , padding: '20px 0px'
                                            , color: 'var(--color-gray-800, #343A40)'
                                            //, textAlign: 'center'
                                            , fontFamily: 'Roboto Serif'
                                            , fontSize: '68px'
                                            , fontStyle: 'italic'
                                            , fontWeight: '500'
                                            , lineHeight: '120%'
                                            //, marginRight: '50px'

                                        }}
                                        >What is <span style = {{ fontWeight: '700' }}>RealSplit?</span>
                                    </h2>
                                </div>
                            </div>
                            */}
                            <div className = 'row'>
                                <div className = 'cell'>
                                    <div
                                        //className='container max-width'
                                        style = {{
                                          backgroundColor: 'rgba(255, 255, 255, 1)'
                                        , borderRadius: '10px'
                                        , background: 'var(--White, #FFF)'
                                        , boxShadow: '0px 24px 48px 0px rgba(0, 0, 0, 0.12)'
                                        //, backgroundPosition: 'left 20px bottom 10px'
                                        //, backgroundSize: 'auto auto'
                                        //, backgroundSize: 'unset'
                                        //, maxWidth: '550px'
                                        , padding: '50px'
                                        , textAlign: 'left'
                                        //, width: '100%'
                                        , color: 'var(--color-gray-700, #495057)'
                                        , fontFamily: 'Source Sans 3'
                                        //, fontSize: '20px'
                                        , fontStyle: 'normal'
                                        , fontWeight: '400'
                                        , lineHeight: '150%'

                                        //  display: 'flex'
                                        //, flexWrap: 'wrap'
                                        //, gap: '24px'
                                        //, width: 'calc(100% / 3)'

                                    }}>
                                        <p
                                            style = {{
                                                marginBottom: '30px'
                                                , marginTop: '10px'
                                            }}
                                            >
                                            {/*
                                            We provide access to diversified investment opportunities for individuals seeking to participate in the commercial real estate market without the burdens of sole ownership.
                                            */}
                                            <strong>RealSplit™</strong> is offering a unique fractional ownership model for high-value real estate properties.
                                            We are providing access to diversified investment opportunities for individuals seeking to participate in the commercial real estate market without the burdens of sole ownership.
                                        </p>
                                        <p
                                            style = {{ marginBottom: '30px' }}
                                            >
                                            We are giving Investors an opportunity to invest into Commercial Real Estate (CRE) deals that would not normally be available to them, given the industry’s high barriers to entry, which typically include significant upfront capital requirements.
                                        </p>
                                        <p
                                            style={{
                                                padding: "10px 0px 0px 0px"
                                            }}>
                                            <Link to="/search/all" target="_blank">
                                                <button
                                                    type = 'button'
                                                    className = 'theme-success large'
                                                    >Find Deals</button>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*
                    */}
                    <div
                        id = 'sample-past-deals'
                        style = {{
                            flexBasis: '50%'
                            , width: '100%'
                            //, minWidth: '380px'
                            , flex: '1 1 380px'
                        }}>
                        <div
                            //className='container max-width'
                            style = {{
                              backgroundColor: 'rgba(255, 255, 255, 1)'
                            , borderRadius: '10px'
                            , background: 'var(--White, #FFF)'
                            , boxShadow: '0px 24px 48px 0px rgba(0, 0, 0, 0.12)'
                            //, backgroundPosition: 'left 20px bottom 10px'
                            //, backgroundSize: 'auto auto'
                            //, backgroundSize: 'unset'
                            //, maxWidth: '550px'
                            //, padding: '50px'
                            , textAlign: 'left'
                            //, width: '100%'
                            , color: 'var(--color-gray-700, #495057)'
                            , fontFamily: 'Source Sans 3'
                            //, fontSize: '20px'
                            , fontStyle: 'normal'
                            , fontWeight: '400'
                            , lineHeight: '150%'
                            //, margin: '0px 30px 30px 30px'

                            //  display: 'flex'
                            //, flexWrap: 'wrap'
                            //, gap: '24px'
                            //, width: 'calc(100% / 3)'

                        }}>
                            <div className = 'table'
                                style = {{
                                    //marginTop: '120px'
                                    paddingBottom: '30px'
                                }}
                                >
                                <div className = 'row'>
                                    <div
                                        className = 'cell'
                                        style = {{
                                            padding: '30px 50px 15px 50px'
                                            , color: 'var(--color-gray-800, #343A40)'
                                            //, fontFamily: 'Roboto Serif'
                                            , fontSize: '33px'
                                            //, fontStyle: 'italic'
                                            , fontWeight: '500'
                                            , lineHeight: '120%'
                                        }}>
                                        <h3
                                            style = {{
                                                  margin: '0px'
                                                , padding: '0px'
                                                , textAlign: 'center'
                                            }}
                                            >
                                            Sample Past Deals
                                        </h3>
                                    </div>
                                </div>
                                
                                <div className = 'row'>
                                    <div
                                        className = 'cell'
                                        //style = {{ padding: '0px 50px 25px 50px' }}
                                        >
                                        <Link to="/item/5">
                                            <figure>
                                                <img
                                                    src = { majesticHotelExteriorJpg }
                                                    alt = 'Majestic Hotel, Chicago'
                                                    style = {{ width: '100%' }}
                                                    />
                                                <figcaption style = {{ marginTop: '-50px', marginLeft: '20px', fontWeight: 'bold', color: '#000' }}>
                                                    <div
                                                        style = {{
                                                            display: 'inline-block'
                                                            , padding: '0px 8px'
                                                            , backgroundColor: 'rgba(255,255,255,0.8)'
                                                            , borderRadius: '4px'
                                                        }}>
                                                        Majestic Hotel, Chicago
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </Link>
                                    </div>
                                </div>
                                
                                <div className = 'row'>
                                    <div
                                        className = 'cell'
                                        //style = {{ padding: '25px 50px' }}
                                        >
                                        <Link to="/item/3">
                                            <figure>
                                                <img
                                                    //src = 'https://realsplit.s3.us-east-1.amazonaws.com/teuUrG40U7.jpg'
                                                    //src = 'https://realsplit-s3-bucket-us-east-1.s3.us-east-1.amazonaws.com/I5JWdbTchI.jpeg'
                                                    src = 'https://realsplit-s3-bucket-us-east-1.s3.amazonaws.com/The-Sinclair-Exterior-Fixed.jpg'
                                                    alt = 'The Sinclair, Los Angeles'
                                                    style = {{ width: '100%' }}
                                                    />
                                                <figcaption style = {{ marginTop: '-50px', marginLeft: '20px', fontWeight: 'bold', color: '#000' }}>
                                                    <div
                                                        style = {{
                                                            display: 'inline-block'
                                                            , padding: '0px 8px'
                                                            , backgroundColor: 'rgba(255,255,255,0.8)'
                                                            , borderRadius: '4px'
                                                        }}>
                                                        The Sinclair, Los Angeles
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default WhatIsRealSplit;
