import
    React
    , {
        useState
        //, useEffect
    } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
//import { Link } from 'react-router-dom';
import styles from './CardContainer.module.sass';
//import Icon from '../Icon';
//import ReactPlayer from 'react-player';
//import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from "@mui/styles";
//import Button from '@material-ui/core/Button';
//import Button from "@mui/material/Button";
//import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { axiosInstance } from '../../utils/API';
import Swal from 'sweetalert2';
//import Image from '../Image';
//import { current_datetime } from '../../controller/utils';
import moment from 'moment';
//import hasAuctionEnded from '../../utils/auctionEnded';
import { numberWithCommas } from '../../utils/formatPricingNumber';
import { NAMES_CONSTANTS } from '../../constants';
//import { displayCryptoPrice, displayPrice } from '../../utils/displayPrice';

const useStyles = makeStyles({
    root: {
          maxWidth: 345
        , overflow: 'hidden'
    }
    , media: {
          height: 140
        , transition: '0.3s'
    }
    , '&:hover': {
        transform: 'scale(1.1)'
    }
});

/*
const CardContainer = ({
      className
    , asset
    , listedAsset
    , type = ''
    , showLabel = false
    , price = '1'
    , resaleHandleClick
    , cancelOption
    , resaleCancelClick
    , productDetails
    , resalePage
    , ownedAssetPage
    , categoryName
}) => {
*/
const CardContainer = props => {
    console.warn('CardContainer()');

    const {
          className
        , asset
        , listedAsset
        , type = ''
        , showLabel = false
        , price = '1'
        , resaleHandleClick
        , cancelOption
        , resaleCancelClick
        , productDetails
        , resalePage
        , ownedAssetPage
        , categoryName
    } = props;

    console.warn('CardContainer()', asset.name, categoryName);
    console.warn('asset = ', asset);
    console.warn('asset.name = ', asset.name);
    console.warn('categoryName = ', categoryName);
    console.warn('productDetails = ', productDetails);

    //const [visible, setVisible] = useState(false);
    //const classes = useStyles();
    const history = useHistory();
    //const exchangeRate = useSelector(state  => state.counter.euroValue);

    /*
    const handleUnlist = (e, id) => {
        let config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };
        e.stopPropagation();
        axiosInstance.patch(`/asset/un-publish/${id}`, '', config).then(res => {
            Swal.fire('Unlisted', 'item unlisted from marketplace', 'info').then(
                res => window.location.reload()
            );
        });
    };

    const checkForUpcomingBid = bidStartDate => {
        let time = new Date(bidStartDate);
        var now = new Date();
        return time < now ? false : true;
    };

    const checkIfResalePage = () => {
        return resalePage;
    };

    const showBlockchainTransaction = (txHash) => {
        window.open(`https://mumbai.polygonscan.com/tx/${txHash}`, '_blank');
    }
    */

    /*
    const getCategoryName = (categoryId) => {
        console.log('getCategoryName('+categoryId+')');

        console.log('categoryFilterObj', categoryFilterObj);
        console.log('categoryList', categoryList);
        return categoryFilterObj?.filter(
            item => item.id === categoryId
        )[0]?.name;
        return 'Mixed-Use';
    };
    */

    return (
        <>
        {
            asset
            ? (
                <div
                    //className={styles.assetContainer}
                    //className = 'asset-container'
                    className = { cn(
                          "item"
                        , 'asset-container'
                        //, getCategoryName(x?.asset.categoryId)
                        , categoryName // E.g. 'Mixed-Use'
                        , styles.item
                    )}
                    onClick = { () => history.push('/item/' + listedAsset.id) }
                    /*
                    onClick = { () => {
                        if (type === 'view') {
                            if (checkIfResalePage()) {
                                history.push('/item/' + productDetails?.id + `?tx=${asset.txHash}`);
                            } else {
                                history.push('/item/view/' + productDetails?.listedAssetId, {
                                    fromPrevious: true,
                                    fractions: productDetails?.fractionCount
                                });
                            }
                        } else {
                            history.push('/item/' + productDetails?.id);
                        }
                        // history.push(type === "view" ? checkIfResalePage() ? "/item/view/" + productDetails?.id : "/item/view/" + productDetails?.listedAssetId : "/item/" + productDetails?.id)
                    }}
                    */
                    style = {{
                        cursor: 'pointer'
                        , paddingBottom: '10px'
                        //, maxWidth: '330px'
                    }}>

                    <div
                        className = { cn('item-top') }
                        //style = {{ height: '100%' }}
                        style = {{
                            display: 'flex'
                            , justifyContent: 'flex-end'
                            , alignItems: 'center'
                            , position: 'relative'
                            , borderBottom: '0' // overwrite the border-bottom when there is no item-top section showing
                            , padding: '0px' // overwrite the padding when there is no item-top section showing
                        }}
                        >

                        <div
                            className = { cn('item-top') }
                            style = {{
                                position: 'absolute'
                                //, top: '-28px'
                                , bottom: '-48px'
                                , right: '0'
                                , margin: '10px'
                                , whiteSpace: 'nowrap'
                            }}>

                            <div
                                style = {{
                                    display: 'flex'
                                    , margin: 'auto'
                                    //, border: 'red 1px solid'
                                    , textAlign: 'center'
                                    , flexDirection: 'column'
                                }}>

                                {
                                    //productDetails?.status == 'past-deal'
                                    asset.status == 'past-deal'
                                    ?
                                        <div
                                            style = {{
                                                  padding: '3px 8px'
                                                , fontSize: '13px'
                                                , fontWeight: '500'
                                                , verticalAlign: 'top'
                                                , fontFamily: 'DM Sans'
                                                , textAlign: 'center'
                                                , textTransform: 'none'
                                                , border: 'red 2px solid'
                                                , borderWidth: '2px 2px 0px 2px'
                                                , backgroundColor: 'white'
                                                , borderTopLeftRadius: '3px'
                                                , borderTopRightRadius: '3px'
                                                , margin: 'auto'
                                                , color: 'red'
                                                , boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'
                                            }}
                                            >
                                            Past Deal
                                        </div>
                                    :
                                        <div
                                            style = {{
                                                  padding: '3px 8px'
                                                , fontSize: '13px'
                                                , fontWeight: '500'
                                                , verticalAlign: 'top'
                                                , fontFamily: 'DM Sans'
                                                , textAlign: 'center'
                                                , textTransform: 'none'
                                                , border: 'green 1px solid'
                                                //, borderWidth: '2px 2px 0px 2px'
                                                , borderWidth: '2px'
                                                , borderBottomWidth: '0px'
                                                //, backgroundColor: 'white'
                                                , backgroundColor: 'green'
                                                , borderTopLeftRadius: '3px'
                                                , borderTopRightRadius: '3px'
                                                , margin: 'auto'
                                                //, color: 'green'
                                                , color: 'white'
                                                , boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'
                                            }}>

                                            {
                                                asset?.isResell
                                                ? <span>
                                                    {
                                                        asset?.availableFractions
                                                    } Split{
                                                        asset?.availableFractions > 1 ? "s" : ""
                                                    }
                                                </span>
                                                : <span>Available</span>
                                            }

                                        </div>
                                }

                                <div>
                                    <button
                                        className = { cn(categoryName, 'text-shadow', 'flag') }
                                        style = {{
                                            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'
                                            //, fontSize: '14px'
                                        }}
                                        >
                                        { categoryName }
                                    </button>
                                </div>

                            </div>

                        </div>

                        {/*
                        <div
                            style = {{
                                position: 'absolute'
                                , top: '5px'
                                , right: '10px'
                            }}
                            >
                            <button
                                //onClick = { (event) => clickCategory(getCategoryName(x?.asset.categoryId), event) }
                                //onClick = { (event) => clickCategory(getCategoryName(x?.asset.categoryId), event) }
                                >
                                { getCategoryName(x?.asset.categoryId) }
                                { categoryName }
                                { categoryName }
                            </button>
                        </div>
                        */}

                        {/*
                        <div
                            className = 'closing-date'
                            style = {{ display: 'inline-block' }}
                            >
                            Closing Date: <span style = {{ whiteSpace: 'nowrap' }}>{ asset.closingDate }</span>
                        </div>
                        */}
                        
                    </div>

                    <img
                        src = {
                               asset.thumbnailUrl
                            || asset.mediaPreviewUrl
                        }
                        //className = { styles.thumbnailImage }
                        className = 'thumbnail'
                        alt = 'thumbnail.png'
                        //style = {{ borderRadius: 'unset' }}
                        />

                    <div
                        style = {{
                             display: 'flex'
                            , flexWrap: 'wrap'
                            //, padding: '10px 10px 5px 10px'
                            , padding: '15px'
                            , margin: '0px'
                            , justifyContent: 'space-between'
                        }}>

                        <div className = 'watch-info-box'>
                            <div>
                                <div
                                    className = 'watch-title'
                                    style = {{
                                        //maxWidth: '220px'
                                        maxWidth: '315px'
                                    }}
                                    >
                                    { asset?.name }
                                </div>
                            </div>
                            <div>
                                <div
                                    className = 'watch-subtitle'
                                    style = {{ maxWidth: '220px' }}>
                                    { asset?.location }
                                </div>
                            </div>
                        </div>

                        {/*
                        <div
                            //className = { cn("item", categoryName, styles.item)}
                            className = { cn("item", categoryName) }
                            //onClick = { (event) => clickCategory(getCategoryName(x?.asset.categoryId), event) }
                            >
                            <div className = { cn('item-top') }>
                                <button>
                                    { categoryName }
                                </button>
                            </div>
                        </div>
                        */}

                        {/*
                        <div
                            className = { "item-description" }
                            style = {{
                                paddingTop: "20px"
                                , paddingBottom: "16px"
                                //, flexGrow: '1'
                                //, flexShrink: '0'
                                //, flexBasis: '100%'
                                //, maxHeight: '120px'
                                //, overflow: 'hidden'
                                //, textOverflow: 'ellipsis'
                            }}
                            >
                            { asset.intro }
                        </div>
                        */}

                        {/*
                        <div className={'price-box'}
                            style = {{
                                  display: "flex"
                                , flexWrap: "wrap"
                                , justifyContent: "space-between"
                                , maxWidth: "220px"
                                //, display: "none"
                            }}
                            >

                            <div>
                                <div className = { 'table' }>
                                    <div className = { 'row' }>
                                        <div style = {{ whiteSpace: 'nowrap' }}>Price USD</div>
                                    </div>
                                    <div className = { 'row' }>
                                        <div className={`price price-dollars ${styles.price}}`}>
                                            US${ displayPrice(asset, exchangeRate) }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className = { 'table' }>
                                    <div className = { 'row' }>
                                        <div style = {{ whiteSpace: 'nowrap' }}>Price Crypto</div>
                                    </div>
                                    <div className = { 'row' }>
                                        <div
                                            className={cn('price', 'price-crypto')}
                                            style = {{ whiteSpace: 'nowrap' }}
                                            >
                                            {(
                                              asset?.regularPrice
                                            ).toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)}{' '}
                                            MATIC
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}

                        <div
                            //className = 'table'
                            className = { cn(
                                'table'
                                , 'item-details-table'
                                , styles.tableBody
                            )}
                            style = {{
                                //display: 'none'
                                //padding: "16px 0px"
                                //padding: "16px 0px 0px 0px"
                                paddingTop: "8px"
                            }}
                            >
                            
                            {
                                asset.isFractional
                                && (
                                    <div className = 'row'>

                                        <div
                                            className = 'cell icon'
                                            style = {{ paddingLeft: "20px" }}
                                            >
                                            <img
                                                src = '/images/icons/tags-icon-black.png'
                                                className = 'filter-body-text-color'
                                                alt = 'filter-body-text-color'
                                                />
                                        </div>

                                        <div
                                            //className = 'cell label'
                                            className = { cn(
                                                'cell'
                                                //, 'label'
                                                //, styles.fractions
                                            ) }
                                            >
                                            Date Purchased
                                        </div>

                                        <div className = 'cell'>
                                            {/* moment(productDetails?.createdAt).format('DD/MM/YYYY') */}
                                            { moment(productDetails?.createdAt).format('MMM DD, YYYY') }
                                            {/*
                                                new Date(
                                                    Date.parse(productDetails.createdAt)
                                                )
                                                .toLocaleDateString(
                                                    "en-US"
                                                    //, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
                                                    //, { year: 'numeric', month: 'long', day: 'numeric' }
                                                    , { year: 'numeric', month: 'short', day: 'numeric' }
                                                )
                                            */}
                                        </div>

                                        {/*
                                        <p>
                                            {' '}
                                            ${' '}
                                            {
                                                ownedAssetPage
                                                ? (productDetails?.listedAsset?.latestPrice * productDetails?.fractionCount)?.toFixed(4)
                                                : (item.regularPrice * productDetails?.fractionCount)?.toFixed(4)
                                            }
                                        </p>
                                        */}
                                    </div>
                                )
                            }

                            {
                                asset.isFractional
                                && (
                                    <div className = 'row'>

                                        <div
                                            className = 'cell icon'
                                            style = {{ paddingLeft: "20px" }}
                                            >
                                            <img
                                                src = '/images/icons/tags-icon-black.png'
                                                className = 'filter-body-text-color'
                                                />
                                        </div>

                                        <div
                                            //className = 'cell label'
                                            className = { cn(
                                                'cell'
                                                //, 'label'
                                                //, styles.fractions
                                            ) }
                                            >
                                            Splits owned by you
                                        </div>

                                        <div className = 'cell'>
                                            { productDetails?.fractionCount }
                                        </div>

                                        {/*
                                        <p>
                                            {' '}
                                            ${' '}
                                            {
                                                ownedAssetPage
                                                ? (productDetails?.listedAsset?.latestPrice * productDetails?.fractionCount)?.toFixed(4)
                                                : (item.regularPrice * productDetails?.fractionCount)?.toFixed(4)
                                            }
                                        </p>
                                        */}
                                    </div>
                                )
                            }

                            {
                                asset.isFractional
                                && (
                                    <div className = 'row'>
                                        <div className = 'cell icon' style = {{ paddingLeft: "20px" }}>
                                            <img src = '/images/icons/tags-icon-black.png' className = 'filter-body-text-color' />
                                        </div>
                                        <div className = 'cell'>
                                            Investment Amount
                                        </div>
                                        <div className = 'cell'>
                                            ${
                                                numberWithCommas(productDetails?.fractionCount * asset?.regularPrice)//.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES))
                                            }
                                        </div>
                                        {/*
                                        <p>
                                            {
                                                productDetails?.isResell === true
                                                ? '$' + productDetails?.resalePrice?.toFixed(4)
                                                : '$' + asset.regularPrice?.toFixed(4)
                                            }
                                        </p>
                                        */}
                                    </div>
                                )
                            }

                            {
                                asset.isFractional
                                && (
                                    <div className = 'row'>
                                        <div className = 'cell icon' style = {{ paddingLeft: "20px" }}>
                                            <img src = '/images/icons/tags-icon-black.png' className = 'filter-body-text-color' />
                                        </div>
                                        <div className = 'cell'>
                                            Percent Ownership
                                        </div>
                                        <div className = 'cell'>
                                            { ((parseInt(productDetails?.fractionCount) / parseInt(listedAsset?.totalFractions)) * 100).toFixed(3) }%
                                        </div>
                                    </div>
                                )
                            }

                            {/* For Debugging
                                asset.isFractional
                                && (
                                    <div className = 'row'>
                                        <div className = 'cell icon' style = {{ paddingLeft: "20px" }}>
                                            <img src = '/images/icons/tags-icon-black.png' className = 'filter-body-text-color' />
                                        </div>
                                        <div className = 'cell'>
                                            listedAsset?.totalFractions
                                        </div>
                                        <div className = 'cell'>
                                            { (listedAsset?.totalFractions) }
                                        </div>
                                    </div>
                                )
                            */}

                            {
                                //asset.isFractional
                                (asset.isFractional && listedAsset?.totalFractions)
                                && (
                                    <div className = 'row'>
                                        <div className = 'cell icon' style = {{ paddingLeft: "20px" }}>
                                            <img src = '/images/icons/tags-icon-black.png' className = 'filter-body-text-color' />
                                        </div>
                                        <div className = 'cell'>
                                            Total Splits
                                        </div>
                                        <div className = 'cell'>
                                            { numberWithCommas(listedAsset?.totalFractions) }
                                        </div>
                                        {/*
                                        <p>
                                            {' '}
                                            ${' '}
                                            {
                                                formatPricingNumber(
                                                    productDetails.resalePrice * listedAsset?.totalFractions
                                                )
                                            }
                                        </p>
                                        */}
                                    </div>
                                )
                            }

                            {
                                asset.isFractional
                                && (
                                    <div className = 'row'>
                                        <div className = 'cell icon' style = {{ paddingLeft: "20px" }}>
                                            <img src = '/images/icons/tags-icon-black.png' className = 'filter-body-text-color' />
                                        </div>
                                        <div className = 'cell'>
                                            Remaining Splits
                                        </div>
                                        <div className = 'cell'>
                                            { numberWithCommas(listedAsset?.availableFractions) }
                                        </div>
                                        {/*
                                        <p>
                                            {
                                                productDetails?.isResell === true
                                                ? '$' + productDetails?.resalePrice?.toFixed(4)
                                                : '$' + asset.regularPrice?.toFixed(4)
                                            }
                                        </p>
                                        */}
                                    </div>
                                )
                            }

                            {
                                asset.isFractional
                                && (
                                    <div className = 'row'>
                                        <div className = 'cell icon' style = {{ paddingLeft: "20px" }}>
                                            <img src = '/images/icons/tags-icon-black.png' className = 'filter-body-text-color' />
                                        </div>
                                        <div className = 'cell'>
                                            Total Deal Size
                                        </div>
                                        <div className = 'cell'>
                                            { asset.investmentSize }
                                        </div>
                                    </div>
                                )
                            }

                            {
                                asset.isFractional
                                && (
                                    <div className = 'row'>
                                        <div className = 'cell icon' style = {{ paddingLeft: "20px" }}>
                                            <img src = '/images/icons/tags-icon-black.png' className = 'filter-body-text-color' />
                                        </div>
                                        <div className = 'cell'>
                                            Remaining Amount
                                        </div>
                                        <div className = 'cell'>
                                            ${
                                                numberWithCommas(listedAsset?.availableFractions * asset?.regularPrice)//.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES))
                                            }
                                        </div>
                                        {/*
                                        <p>
                                            {
                                                productDetails?.isResell === true
                                                ? '$' + productDetails?.resalePrice?.toFixed(4)
                                                : '$' + asset.regularPrice?.toFixed(4)
                                            }
                                        </p>
                                        */}
                                    </div>
                                )
                            }
                            
                            <div className = 'row'>
                                <div className = 'cell icon' style = {{ paddingLeft: "20px" }}>
                                    <img src = '/images/icons/tags-icon-black.png' className = 'filter-body-text-color' />
                                </div>
                                <div className = 'cell'>
                                    Return on Investment
                                </div>
                                <div className = 'cell'>
                                    { asset?.returnOnInvestment ? asset?.returnOnInvestment : 'N/A' }
                                </div>
                                {/*
                                <p>
                                    {
                                        productDetails?.isResell === true
                                        ? '$' + productDetails?.resalePrice?.toFixed(4)
                                        : '$' + asset.regularPrice?.toFixed(4)
                                    }
                                </p>
                                */}
                            </div>

                        </div>

                    </div>

                    {/*
                    <div
                        className = 'closing-date'
                        //style = {{
                        //    paddingBottom: '10px'
                        //}}
                        >
                        Closing Date: <span>{ asset?.closingDate }</span>
                    </div>
                    */}

                    <div
                        className = { cn(
                            'table'
                            , 'item-details-table'
                            , styles.tableBody
                        )}
                        style = {{
                            display: 'none'
                        }}
                        >

                        <div className={'row'}>

                            <div
                                className = 'cell icon'
                                style = {{ paddingLeft: "20px" }}
                                >
                                <img
                                    //src = '/images/icons/building.svg'
                                    //src = '/images/icons/svgrepo/building-business-management-svgrepo-com.svg'
                                    src = '/images/icons/tags-icon-black.png'
                                    //className = { "filter-body-text-color" }
                                    className = 'filter-body-text-color'
                                    //style = {{ fill: "red" }}
                                    />
                            </div>

                            <div
                                className = 'cell label'
                                //dataShortName = 'Asset Category'
                                //dataLongName = 'Asset Category'
                                >
                                {/*
                                Asset Category
                                Category
                                  */}
                                <span className = 'long-name'>
                                    Asset Category
                                </span>
                                <span className = 'short-name'>
                                    Category
                                </span>
                            </div>

                            <div
                                className = {
                                    cn(
                                        'cell'
                                        //, categoryName
                                    )
                                }
                                style = {{
                                      color: 'var(--color-gray-800, #343A40)'
                                    , fontFamily: 'Source Sans 3'
                                    , fontSize: '16px'
                                    , fontStyle: 'normal'
                                    , fontWeight: '700' 
                                }}>
                                <div
                                    className = {
                                        cn(
                                            "item"
                                            //, categoryName
                                            , styles.item
                                        )
                                    }
                                    //className = { cn("item", "industrial", styles.item)}
                                    //onClick = { () => history.push("/search/" + categoryName) }
                                    //onClick = { () => clickCategory(getCategoryName(x?.asset.categoryId)) }
                                    >

                                    {/*
                                        //productDetails?.status == 'past-deal'
                                        //asset?.status == 'past-deal'
                                        productDetails?.saleType == 'past-deal'
                                        &&
                                            <div
                                                style = {{
                                                    //color: 'white'
                                                    display: 'flex'
                                                    //, marginLeft: '8px'
                                                    , margin: 'auto'
                                                    //, position: 'absolute'
                                                    //, right: '0'
                                                    //, top: '-40px'
                                                    //, border: 'red 1px solid'
                                                    , textAlign: 'center'
                                                }}>
                                                <div
                                                    style = {{
                                                          padding: '5px 10px'
                                                        , fontSize: '16px'
                                                        , fontWeight: '500'
                                                        , verticalAlign: 'top'
                                                        , fontFamily: 'DM Sans'
                                                        , textAlign: 'center'
                                                        , textTransform: 'none'
                                                        , border: 'red 2px solid'
                                                        , borderWidth: '2px 2px 0px 2px'
                                                        , backgroundColor: 'white'
                                                        , borderTopLeftRadius: '5px'
                                                        , borderTopRightRadius: '5px'
                                                        , margin: 'auto'
                                                        , color: 'red'
                                                        , boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'
                                                    }}>
                                                    Past Deal
                                                </div>
                                            </div>
                                    */}

                                    <button
                                        className = { categoryName }
                                        //className = { 'Mixed-Use' }
                                        >
                                        {/*}
                                        Mixed-Use
                                        */}
                                        { categoryName }

                                    </button>
                                </div>

                            </div>
                        </div>

                        <div className = 'row'>
                            <div className = 'cell icon'>
                                <img
                                    //src='/images/icons/graph-up.svg'
                                    //src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                    //src = '/images/icons/1819867-200.png'
                                    src = '/images/icons/dollar-sign-transparent.png'
                                    />
                            </div>
                            <div className = 'cell label'>
                                <span className = 'long-name'>
                                    Investment Size
                                </span>
                                <span className = 'short-name'>
                                    Size
                                </span>
                            </div>
                            <div className={'cell'}>
                                { productDetails.investmentSize }
                            </div>
                        </div>

                        <div className = 'row'>
                            <div className = 'cell icon'>
                                <img
                                    //src='/images/icons/graph-up.svg'
                                    //src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                    //src = '/images/icons/98913.png'
                                    //src = '/images/icons/percentage-sign.png'
                                    //src = '/images/icons/percentage-owned.png'
                                    src = '/images/icons/fractions-available.png'
                                    />
                            </div>
                            <div className = 'cell label'>
                                <div>
                                    <span className = 'long-name'>
                                        % Already Pre-Owned
                                    </span>
                                    <span className = 'short-name'>
                                        % Pre-Owned
                                    </span>
                                </div>
                                <div className = 'long-name'
                                    //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                    >
                                    % of the property that is already owned by other investors
                                </div>
                            </div>
                            <div className={'cell'}>
                                { productDetails.percentageOwned }
                            </div>
                        </div>

                        <div className = 'row'>
                            <div className = 'cell icon'>
                                <img
                                    //src='/images/icons/graph-up.svg'
                                    //src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                    //src = '/images/icons/percentage-sign.png'
                                    //src = '/images/icons/fractions-available.png'
                                    src = '/images/icons/percentage-owned.png'
                                    />
                            </div>
                            <div className = 'cell label'>
                                <div>
                                    <span className = 'long-name'>
                                        % Available for Fractionalization
                                    </span>
                                    <span className = 'short-name'>
                                        % Available
                                    </span>
                                </div>
                                <div
                                    className = 'long-name'
                                    //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                    >
                                    % of the property that is available for fractionalized ownership by RealSplit™ investors
                                </div>
                            </div>
                            <div className={'cell'}>
                                { productDetails?.percentageAvailable }
                            </div>
                        </div>

                        <div className = 'row'>
                            <div className = 'cell icon'>
                                <img
                                    //src='/images/icons/graph-up.svg'
                                    //src = '/images/icons/black-pie-chart-75-percent-15676.png'
                                    //src = '/images/icons/fractions-available.png'
                                    src = '/images/icons/six-fractions.png'
                                    //src = '/images/icons/black-pie-chart-75-percent-15676.png'
                                    //style = {{ transform: 'rotate(90deg)' }}
                                    />
                            </div>
                            <div className = 'cell label'>
                                <div>
                                    <span className = 'long-name'>
                                        Total Fractions Available
                                    </span>
                                    <span className = 'short-name'>
                                        Total Fractions
                                    </span>
                                </div>
                                <div
                                    className = 'long-name'
                                    //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                    >
                                    The total amount of fractions available to RealSplit investors (calculated by dividing the size of the deal by the minimum investment amount)
                                </div>
                            </div>
                            <div className={'cell'}>
                                { asset?.totalFractions }
                            </div>
                        </div>

                        <div className = 'row'>
                            <div className = 'cell icon'>
                                <img
                                    //src='/images/icons/graph-up.svg'
                                    //src = '/images/icons/black-pie-chart-75-percent-15676.png'
                                    //src = '/images/icons/six-fractions-one-taken.png'
                                    src = '/images/icons/six-fractions-two-taken.png'
                                    //src = '/images/icons/fractions-available.png'
                                    //style = {{ transform: 'rotate(90deg)' }}
                                    />
                            </div>
                            <div className = 'cell label'>
                                <div>
                                    <span className = 'long-name'>
                                        Fractions Remaining
                                    </span>
                                    <span className = 'short-name'>
                                        Available
                                    </span>
                                </div>
                                <div
                                    className = 'long-name'
                                    //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                    >
                                    Fractions remaining to be sold and available to be bought by investors on RealSplit™
                                </div>
                            </div>
                            <div className = { 'cell' }>
                                { asset?.availableFractions }
                            </div>
                        </div>

                        <div className = 'row'>
                            <div className = 'cell icon'>
                                <img
                                    //src='/images/icons/graph-up.svg'
                                    //src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                    src = '/images/icons/dollar-sign-transparent.png'
                                    />
                            </div>
                            <div className = 'cell label'>
                                <div>
                                    <span className = 'long-name'>
                                        Minimum Investment Amount
                                    </span>
                                    <span className = 'short-name'>
                                        Minimum
                                    </span>
                                </div>
                                <div
                                    className = 'long-name'
                                    //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                    >
                                    1 Fraction
                                </div>
                            </div>
                            <div className = { 'cell' }>
                                { productDetails?.minimumInvestmentAmount }
                            </div>
                        </div>

                        <div
                            className = { 'row' }
                            //style = {{ display: 'none' }}
                            >

                            <div
                                className={'cell icon'}
                                //style = {{ padding: "0px 20px" }}
                                >
                                <img
                                    //src='/images/icons/clock.svg'
                                    //src = '/images/icons/svgrepo/clock-v-svgrepo-com.svg'
                                    //src = '/images/icons/time-schedule-clock-svgrepo-com.png'
                                    src = '/images/icons/calendar-icon.png'
                                    />
                            </div>

                            <div className = {
                                cn(
                                      'cell'
                                    , 'label'
                                    //, styles.label
                                )
                                }>
                                <span className = 'long-name'>
                                    Closing Date
                                </span>
                                <span className = 'short-name'>
                                    Closing
                                </span>
                            </div>

                            <div
                                className = { cn('cell', 'bold') }
                                //style = {{
                                //    textTransform: "capitalize"
                                //}}
                                >

                                <div style = {{
                                    display: 'flex'
                                    //, flexWrap: 'wrap'
                                    , flexWrap: 'nowrap'
                                    , justifyContent: 'flex-end'
                                    //, flexDirection: 'row'
                                    , flexDirection: 'column'
                                    }}>

                                    <div
                                        style = {{
                                            whiteSpace: 'nowrap'
                                            , fontSize: '66%'
                                            , fontWeight: 'normal'
                                            , color: 'var(--color-gray-600)'
                                            , textTransform: "initial"
                                            , lineHeight: '20px'
                                        }}>
                                        
                                        ({
                                            // Check if the closing date is in the past or future
                                            new Date()
                                            > new Date(
                                                Date.parse(
                                                    productDetails.closingDate
                                                )
                                            )

                                            // "Past"
                                            ? [
                                                Math.round(
                                                      Number(
                                                            new Date().getTime()
                                                          - new Date(Date.parse(productDetails.closingDate)).getTime()
                                                      ) / (1000 * 60 * 60 * 24)
                                                )
                                                , "Days ago"
                                            ].join(' ')

                                            // "Future"
                                            : [
                                                Math.round(
                                                      Number(
                                                            new Date(Date.parse(productDetails.closingDate)).getTime()
                                                          - new Date().getTime()
                                                      ) / (1000 * 60 * 60 * 24)
                                                )
                                                , "Days"
                                            ].join(' ')
                                        })
                                    </div>

                                    <div
                                        style = {{
                                            whiteSpace: 'nowrap'
                                            , lineHeight: '30px'
                                        }}
                                        >
                                        &nbsp;
                                        { 
                                            new Date(
                                                Date.parse(productDetails.closingDate)
                                            )
                                            .toLocaleDateString(
                                                "en-US"
                                                //, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
                                                //, { year: 'numeric', month: 'long', day: 'numeric' }
                                                , { year: 'numeric', month: 'short', day: 'numeric' }
                                            )
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                        {
                            productDetails?.holdingPeriod != null
                            &&
                                <div className = 'row'>
                                    <div className = 'cell icon'>
                                        <img
                                            //src = '/images/icons/calendar2.svg'
                                            //src = '/images/icons/svgrepo/business-calendar-management-svgrepo-com.svg'
                                            src = '/images/icons/png-clipart-padlock-computer-icons-security-padlock-technic-logo-thumbnail.png'
                                            />
                                    </div>
                                    <div className = 'cell label'>
                                        <span className = 'long-name'>
                                            Holding Period
                                        </span>
                                        <span className = 'short-name'>
                                            Holding
                                        </span>
                                    </div>
                                    <div className={'cell'}>
                                        { productDetails.holdingPeriod }
                                    </div>
                                </div>
                        }

                        {/*
                        <div className = 'row'>
                            <div className = 'cell icon'>
                                <img
                                    //src='/images/icons/people.svg'
                                    //src='/images/icons/svgrepo/business-management-media-7-svgrepo-com.svg'
                                    //src='/images/icons/connected-people-icon.svg'
                                    src='/images/icons/referred-affiliates-1.png'
                                    />
                            </div>
                            <div
                                //className = { cn('cell', styles.label) }
                                className = 'cell label'
                                >
                                Investors
                                Current Investors in deal
                            </div>
                            <div className={'cell'}>
                                0
                            </div>
                        </div>
                        */}

                        <div className = 'row'>
                            <div className = 'cell icon'>
                                <img
                                    //src='/images/icons/graph-up.svg'
                                    //src = '/images/icons/svgrepo/business-grafik-management-svgrepo-com.svg'
                                    //src = '/images/icons/return-on-investment-icon.png'
                                    //src = '/images/icons/bar-chart-up-3.svg'
                                    src = '/images/icons/noun-increase-212057-cropped.png'
                                    />
                            </div>
                            <div className = 'cell label'>
                                {/*
                                ROI
                                */}
                                <span className = 'long-name'>
                                    Return on Investment
                                </span>
                                <span className = 'short-name'>
                                    Return
                                </span>
                            </div>
                            <div className={'cell'}>
                                { productDetails.returnOnInvestment }
                            </div>
                        </div>

                    </div>

                    {
                        //asset
                        //&& asset?.isOnSell
                        asset?.status == 'past-deal'
                        ? (
                            <div
                                style = {{
                                    textAlign: 'center'
                                    //, marginBottom: '10px'
                                    , margin: '10px'
                                }}>
                                <button
                                    type='button'
                                    //className = { styles.resaleBtn }
                                    className = { "medium rounded theme-primary" }
                                    /*
                                    style = {{
                                          marginTop: '6px'
                                        , marginBottom: '6px'
                                        , padding: '8px 12px'
                                        , border: 'green 1px solid'
                                        , color: 'green'
                                        , backgroundColor: 'white'
                                    }}
                                    */
                                    onClick = { e => resaleHandleClick(e, asset, productDetails) }
                                    >
                                    Resell Asset
                                </button>
                            </div>
                        )
                        :
                            <div
                                style = {{
                                    textAlign: 'center'
                                    //, marginBottom: '10px'
                                    //, marginTop: '20px'
                                    , margin: '10px 10px'
                                }}>
                                <button
                                    type='button'
                                    //className = { styles.resaleBtn }
                                    className = { "medium rounded theme-positive positive theme-success" }
                                    /*
                                    style = {{
                                          marginTop: '6px'
                                        , marginBottom: '16px'
                                        , padding: '8px 12px'
                                        , border: 'green 1px solid'
                                        , color: 'green'
                                        , backgroundColor: 'white'
                                    }}
                                    */
                                    //onClick = { e => resaleHandleClick(e, asset, productDetails) }
                                    //onClick = { () => props.handleAddToCart(productDetails) }
                                    //onClick = { () => props.handleAddToCart(asset, productDetails) }
                                    onClick = { () => props.handleAddToCart(asset.id, productDetails.listedAssetId) }
                                    >
                                    Buy More
                                </button>
                            </div>
                    }

                </div>

                
            ) : (
                <></>
            )
        }
        </>
    );
};

export default CardContainer;
